import Dropdown from 'react-bootstrap/Dropdown';
import React from 'react';
import "./selectBox.css"
function GenerateFilterMenu({ ParentData, Component, }: any) {
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" className='primary btn  btn-sm me-2 btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3 filterBtn' >
        Generate
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Component ParentData={ParentData} ></Component>
        <Dropdown.Item onClick={() => ParentData.handleActions()}><button className="btn btn-sm btn-primary w-100 px-2 " >Generate</button></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}


export default GenerateFilterMenu;

// rgb(100,180,207)
// rgb(100,180,207)