import React from 'react';
import { withSwal } from 'react-sweetalert2';
import { postRequest } from '../../../consts/Server/Requests';
import { adminToastRunner } from '../adminUtils';

const AdminMaxDeviceChange = withSwal((props: any) => {
    const {
        swal,
        confirm,
        cancel,
        successTitle,
        FailedTitle,
        setShow = null,
        show = false,
        setUpdatePrevState = null,
        refreshNow = null,
        setPending = null,
        row = {},
        urlEndPoint,
    } = props;

    async function sendRequestToServer(updatedMaxCount:any) {
        setPending && setPending(true);

        const updatedPayload = { id: row?.id, max_device_count: updatedMaxCount };

        const response = await postRequest(
            urlEndPoint,
            updatedPayload,
            setPending,
        );

        adminToastRunner(response);

        if (response?.data?.status === 200) {
            setUpdatePrevState && setUpdatePrevState(updatedPayload, row);
            refreshNow && refreshNow();
            setShow && setShow(null);
        } else {
            swal.fire(FailedTitle);
        }
    }

    React.useEffect(() => {
        if (show) {
            swal.fire({
                title: successTitle,
                html: `<input type="number" id="newMaxCount" class="swal2-input" placeholder="Enter new count" value="${row.max_device_count || ''}"/>`,
                showCancelButton: true,
                confirmButtonText: confirm,
                cancelButtonText: cancel,
                preConfirm: () => {
                    const inputElement = document.getElementById('newMaxCount') as HTMLInputElement;
                    const inputValue = inputElement?.value;
                    return inputValue;
                }
            }).then((result: any) => {
                if (result.isConfirmed) {
                    sendRequestToServer(result.value);
                } else if (result.isDismissed) {
                    setShow(null);
                    swal.fire(FailedTitle);
                }
            });
        }
    }, [show]);

    return null;
});

export default AdminMaxDeviceChange;
