import React, { FC, useEffect, useState } from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import AdminMultiSelectFilter from '../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
const Columns = ({ ParentData }: any) => {
  let from = "startDate"
  let to = "endDate"
  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>

      <div className='row mb-2 mb-md-0'>
        <div className='col-md-6 mb-md-2'>
          <span>Start Date</span>
          <input
            type='date'
            style={{ height: '38px' }}
            value={ParentData?.payloads[from]}
            name={from}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, from, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
        <div className='col-md-6'>
          <span>End Date</span>

          <input
            type='date'
            style={{ height: '38px' }}
            name={to}
            value={ParentData?.payloads[to]}
            onChange={(event: any) =>
              ParentData?.onChangeHandler(event.target, to, true)
            }
            className='w-100 form-control me-2 align-start'
          />
        </div>
      </div>

      <span className='fs-semibold fs-7'>Interval</span>
      <SingleSelectSearchDetailsDefaultDyanmic
        handleChangeInputData={ParentData?.onChangeHandler}
        HeaderTitle='Select Interval'
        SelectData={ParentData?.intervalType || []}
        DynamicKeys={["name"]}
        id={"id"}
        name={"interval"}
        targetValue={"value"}
        defaultData={ParentData?.defaultInterval}
      ></SingleSelectSearchDetailsDefaultDyanmic>

      {/* {
        ParentData?.removeFilter ? <></> : <>
          <span className='fs-semibold fs-7'>Select Area Manager</span>
          <AdminMultiSelectFilter
            onChangeHandler={ParentData?.onChangeHandler}
            HeaderTitle="Select Area Manager"
            SelectData={ParentData?.areaManagerList || []}
            displayFields={["first_name", "last_name", "phone"]}
            id="id"
            name="area_manager_ids"
            defaultData={null}
            searchable={false}
            setSeacrch={false}
            isLoading={false}
            filters={null}
          />

        </>
      } */}


      <span className='fs-semibold fs-7'>Select Area</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Area"
        SelectData={ParentData?.masterAreaList || []}
        displayFields={["areaname"]}
        id="id"
        name="area_ids"
        defaultData={null}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      />

      {/* <span className='fs-semibold fs-7'>Select Status</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Status"
        SelectData={[{ id: 1, name: "Approved" }, { id: 0, name: "Not Approved" }]}
        displayFields={["name"]}
        id="id"
        name="status"
        defaultData={{
          item: { value: 1, label: "Approved" },
        }}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      /> */}

      {/* <span className='fs-semibold fs-7'>Select Rows</span>
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Rows"
        SelectData={ParentData?.rowsFilterData || []}
        displayFields={["name"]}
        id="id"
        name="selectedDisplayValues"
        defaultData={{
          item: ParentData?.defaultRows
        }}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      /> */}




    </div>
  )
}

export default Columns

