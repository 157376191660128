import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import AdminSelectSearchable from '../../modules/admins/adminForms/adminFormComponents/AdminSelectSearchable'
import { useAdminManagementPermission } from '../../modules/admins/adminUtils'
const ClickUpCallChamp = ({ show, setShow, CallNowButton, listData = [], defValue = null, setCallDropdownNumber }: any) => {
    const { permissionWrapperContextUtil } = useAdminManagementPermission()

    const onChangeHandler: any = (event: any, name: any, isDate?: any) => {
        setCallDropdownNumber(event ? event?.value : event)
    }

    return (
        <>
            {
                show ? <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-small"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select number to make call </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3' style={{ position: "relative" }}>
                            {
                                permissionWrapperContextUtil("champ-management-dashboard-click-up-call") ? <AdminSelectSearchable
                                    HeaderTitle='Search Number'
                                    onChangeHandler={onChangeHandler}
                                    selectData={listData}
                                    displayFields={["phone", "status"]}
                                    id="phone"
                                    name="callingNumberId"
                                    defaultData={defValue ? defValue : null}
                                    filters={{
                                        reqApi: "searchableSubAreasList",
                                        storeKeyName: "subAreaList",
                                    }}
                                    isMulti={false}
                                /> : <AdminSelectSearchable
                                    HeaderTitle='Search Number'
                                    onChangeHandler={onChangeHandler}
                                    selectData={[]}
                                    displayFields={["phone", "status"]}
                                    id="phone"
                                    name="callingNumberId"
                                    defaultData={defValue ? defValue : null}
                                    filters={{
                                        reqApi: "searchableSubAreasList",
                                        storeKeyName: "subAreaList",
                                    }}
                                    isDisabled={true}
                                    isMulti={false}
                                />
                            }


                        </div>
                        <div className='d-flex align-items-center justify-content-end '>
                            <button dir="rtl" className="btn btn-sm  btn-success mt-4 text-end ms-4" onClick={() => CallNowButton()}>
                                Call Now
                            </button>
                        </div>
                    </Modal.Body>
                </Modal> : ''
            }
        </>
    )
}
export default ClickUpCallChamp
