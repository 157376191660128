import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { HiringPointTrackingList } from './HiringPointTrackingList'
import { KTCardBody } from '../../../_metronic/helpers'
import { HiringPointByArea } from './HiringPointByArea'

export const MainComponent = () => {
    const [activeTab, setActiveTab] = useState('byHiringPoints')

    return (
        <KTCardBody>
            <div className='mb-4 fs-4 fw-semibold'>Hiring Point Counts</div>
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k || 'byHiringPoints')}
                className='mb-4'
            >
                <Tab eventKey='byHiringPoints' title='Count by Hiring Points'>
                    <HiringPointTrackingList />
                </Tab>
                <Tab eventKey='byArea' title='Count by Area'>
                    <HiringPointByArea />
                </Tab>

            </Tabs>
        </KTCardBody>
    )
}