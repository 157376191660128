import React from 'react'
import { useSelector } from 'react-redux'
const PermissionContextProvider = ({ children, keyName }: any) => {
    const userAllowedPermissionList = useSelector((store: any) => store.adminReducer.userAllowedPermissionList)
    return userAllowedPermissionList?.includes(keyName) ? children : <></>
}

export default PermissionContextProvider


// const { permissionWrapperContextUtil } = useAdminManagementPermission()
// const { permissionWrapperHideColsUtil } = useAdminManagementPermission()
// permissionWrapperHideColsUtil(columns())