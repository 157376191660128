// import React, { useState, useEffect } from 'react';
// import DataTable from 'react-data-table-component'
// import { LocalBaseURL } from '../../../../BaseURLmanagement';
// import { columns } from './Columns';
// import { getRequest, postRequest } from '../../../consts/Server/Requests';
// import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
// import { AllFielSearch } from '../../../consts/FieldSearch/AllFieldSearch';
// import AddNewFormData from './AddNewAdminForm/AddNewAdminUser';
// import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../../consts/components/Utility';
// import { ReactTableComponentStylesSheet } from '../../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
// import Refresh from '../../../consts/Refresh';
// import DropDownFilterMenu from '../../../consts/BootrapDropdown/DropDownFilterMenu';
// import { AdminUserFilter } from './AdminUserFilter';
// const AdminUsersMainList = ({ setFilteredIdForChat }: any) => {
//   LocalBaseURL()
//   const [Search, setSearch] = useState<any>("")
//   const [isLoading, setLoading] = useState<boolean>(false)
//   const [filterData, setfilterData] = useState<any>([])
//   const [data, setData] = useState<any>([])
//   const [rolesData, setRolesData] = useState<any>([])
//   const [addAdminUserModalOpen, setaddAdminUserModalOpen] = useState(false)
//   const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
//   const handleSelectedRowsChange = (selectedRows: any) => {
//     setFilteredIdForChat && setFilteredIdForChat(selectedRows?.selectedRows, "admin_users")
//     setFilteredSelectedRows(selectedRows?.selectedRows)
//   };

//   const [payload, setPayload] = useState<any>({
//     role_id: []
//   })

//   React.useEffect(() => {
//     const updatedData = AllFielSearch(data, Search)
//     setfilterData(updatedData)
//   }, [Search])

//   const PopUpMasterBTN = () => {
//     setaddAdminUserModalOpen(true)
//   }

//   const handleClose = () => {
//     setaddAdminUserModalOpen(false)
//   }


//   async function getAdminUserData() {
//     setLoading(true)
//     const { data } = await postRequest(URL_EndPoints(null)?.getMasterAdminUserList, payload, setLoading)
//     setfilterData(data.data)
//     setData(data.data)
//   }

//   const getRoles = async () => {
//     const rolesData = await getRequest(URL_EndPoints(null)?.getAllRoles, null)
//     setRolesData(rolesData?.data.data)
//   }

//   useEffect(() => {
//     getAdminUserData()
//     getRoles()
//   }, [])

//   const handleRefresh = async () => {
//     getAdminUserData()
//   }

//   const handleChangeInputData = (event: any, name: any) => {
//     setPayload({ ...payload, [name]: event ? event?.value : event })
//   }
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const handleChangePage = (page: number) => {
//     setCurrentPage(page);
//   };

//   const handleChangeRowsPerPage = (newPerPage: number, page: number) => {
//     setRowsPerPage(newPerPage);
//     setCurrentPage(page);
//   };
//   const paginatedData = filterData.slice(
//   (currentPage - 1) * rowsPerPage,
//   currentPage * rowsPerPage
// );

//   return (
//     <div>
//       {
//         <DataTable
//           title="Admin Users List"
//           selectableRows
//           progressPending={isLoading}
//           customStyles={ReactTableComponentStylesSheet}
//           columns={reorderColumns(columns(), "adminUserReorderedItem")}
//           data={paginatedData}
//           onColumnOrderChange={cols => storeReorderIndexes(cols, "adminUserReorderedItem")}
//           pagination
//           fixedHeader
//           paginationServer
//           paginationComponentOptions={paginationRowsPerPageOptionsAll}
//           paginationRowsPerPageOptions={paginationRowsPerPageOptions}
//           paginationPerPage={10}
//           fixedHeaderScrollHeight='auto'
//           selectableRowsHighlight
//           highlightOnHover
//           onChangePage={handleChangePage}
//           onChangeRowsPerPage={handleChangeRowsPerPage}
//           paginationTotalRows={filterData?.length}
//           onSelectedRowsChange={handleSelectedRowsChange}

//           actions={
//             <>
//               <button className="btn btn-sm btn-primary" onClick={(row) => PopUpMasterBTN()}><i className="fa fa-plus-circle mr-2"></i>Add New Admin</button>
//             </>
//           }
//           subHeader
//           subHeaderComponent={<>
//             <div className='w-100 d-flex justify-content-between mb-3 '>
//               <div className='d-flex align-items-start justify-content-start '>
//                 <input
//                   type="text"
//                   placeholder='Search Here'
//                   value={Search}
//                   onChange={(e: any) => setSearch(e.target.value)}
//                   className='w-100 form-control me-2 align-start' />
//               </div>
//               <div className='d-flex align-items-center justify-content-end '>
//                 <Refresh refreshNow={handleRefresh} />
//                 <DropDownFilterMenu
//                   ParentData={{
//                     onChangeHandler: handleChangeInputData,
//                     handleActions: handleRefresh,
//                     payloads: payload,
//                     roleData: rolesData,
//                   }}
//                   Component={AdminUserFilter}
//                 ></DropDownFilterMenu>
//               </div>

//             </div>
//           </>}
//         />
//       }





//       {addAdminUserModalOpen ?
//         <AddNewFormData tableRefresh={handleRefresh} addAdminUserModalOpen={addAdminUserModalOpen} handleClose={handleClose} initialValue={{}} rolesData={rolesData} />
//         : ""}
//     </div>
//   )
// }
// export default AdminUsersMainList





import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { LocalBaseURL } from '../../../../BaseURLmanagement';
import { columns } from './Columns';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { AllFielSearch } from '../../../consts/FieldSearch/AllFieldSearch';
import AddNewFormData from './AddNewAdminForm/AddNewAdminUser';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../../consts/components/Utility';
import Refresh from '../../../consts/Refresh';
import DropDownFilterMenu from '../../../consts/BootrapDropdown/DropDownFilterMenu';
import { AdminUserFilter } from './AdminUserFilter';
const AdminUsersMainList = ({ setFilteredIdForChat }: any) => {
  LocalBaseURL()
  const [Search, setSearch] = useState<any>("")
  const [isLoading, setLoading] = useState<boolean>(false)
  const [filterData, setfilterData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [rolesData, setRolesData] = useState<any>([])
  const [addAdminUserModalOpen, setaddAdminUserModalOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    role_id: []
  })
   
 useEffect(() => {
    const updatedData = AllFielSearch(data, Search)
    console.log('updatedData', updatedData);
    setfilterData(updatedData)
  }, [Search])

  const PopUpMasterBTN = () => {
    setaddAdminUserModalOpen(true)
  }

  const handleClose = () => {
    setaddAdminUserModalOpen(false)
  }


  async function getAdminUserData() {
    setLoading(true)
    const { data } = await postRequest(URL_EndPoints(null)?.getMasterAdminUserList, payload, setLoading)
    setData(data.data)
    setfilterData(data.data)
  }

  const getRoles = async () => {
    const rolesData = await getRequest(URL_EndPoints(null)?.getAllRoles, null)
    setRolesData(rolesData?.data.data)
  }

  useEffect(() => {
    getAdminUserData()
    getRoles()
  }, [])

  const handleRefresh = async () => {
    getAdminUserData()
  }

  const handleChangeInputData = (event: any, name: any) => {
    setPayload({ ...payload, [name]: event ? event?.value : event })
  }
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredIdForChat && setFilteredIdForChat(selectedRows?.selectedRows, "admin_users")
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };


  return (
    <div>
      {
        <DataTable
          title="Admin Users List"
          selectableRows
          progressPending={isLoading}
          paginationTotalRows={filterData?.length}
          // customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(columns(), "adminUserReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "adminUserReorderedItem")}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          onSelectedRowsChange={handleSelectedRowsChange}
          actions={
            <>
              <button className="btn btn-sm btn-primary" onClick={(row) => PopUpMasterBTN()}><i className="fa fa-plus-circle mr-2"></i>Add New Admin</button>
            </>
          }
          subHeader
          subHeaderComponent={<>
            <div className='w-100 d-flex justify-content-between mb-3 '>
              <div className='d-flex align-items-start justify-content-start '>
                <input
                  type="text"
                  placeholder='Search Here'
                  value={Search}
                  onChange={(e: any) => setSearch(e.target.value)}
                  className='w-100 form-control me-2 align-start' />
              </div>
              <div className='d-flex align-items-center justify-content-end '>
                <Refresh refreshNow={handleRefresh} />
                <DropDownFilterMenu
                  ParentData={{
                    onChangeHandler: handleChangeInputData,
                    handleActions: handleRefresh,
                    payloads: payload,
                    roleData: rolesData,
                  }}
                  Component={AdminUserFilter}
                ></DropDownFilterMenu>
              </div>

            </div>
          </>}
        />
      }

      {addAdminUserModalOpen ?
        <AddNewFormData tableRefresh={handleRefresh} addAdminUserModalOpen={addAdminUserModalOpen} handleClose={handleClose} initialValue={{}} />
        : ""}
    </div>
  )
}
export default AdminUsersMainList
