import React, { useEffect, useRef, useState } from 'react'
import { faBackspace, faBackward, faBackwardStep, faClose, faForward, faForwardStep } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { getRequest, postRequest, postRequestWithFile } from '../Server/Requests'
import moment from 'moment'
import { toast } from 'react-toastify'
import WhatsappChats from './components/WhatsappChats'
import ChampChats from './components/ChampChats'
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CHAMPS_CHAT_MESSAGES, STORE_ROW_DATA_FOR_CHAMP_DASHBOARD } from '../../../Redux/champChatDashboardReducer/champsTypes'
import { openChatByMessage, openChatByWhatsapp } from '../../../Redux/champChatDashboardReducer/champsActions'
import UpdateSourceForm from './actions/UpdateSourceForm'
import UpdateTicketCategoryForm from './actions/UpdateTicketCategoryForm'
import UpdateTicketStatusForm from './actions/UpdateTicketStatusForm'
import AssigningFormToUserForm from './actions/AssigningFormToUserForm'
import SweetAlertConfirm from '../SweetAlert/SweetAlertConfirm'
import { MAIN_ADMIN_BASE_API_URL, TEST_ADMIN_BASE_API_URL } from '../../../apiGlobally'
import SweetAlertCloseTimer from '../SweetAlert/SweetAlertCloseTimer'
import InternalTicketTicketOpen from './InternalTicketTicketOpen'
import { LocalBaseURL } from '../../../BaseURLmanagement'
import ChampActionLayout from './components/ChampActionLayout'
import TicketStatus from './../reuseModules/TicketStatus';
import { BulkUtilButtonWrapper } from '../reuseModules/BulkUtilButton'
import AdminChampActionForm from '../../modules/admins/adminChampDashboardForms/AdminChampActionForm'
import { champDashboardSubAreaActionFormData } from './utils/DefaultPrivatePublic'
import { catchCurrentOpenedUrl } from '../components/Utility'
import { adminQueryGenerator, enableFeatures4ChampDashboard, setIsChatOpenedOrNot } from '../../modules/admins/adminUtils'
import AdminChampLeadStatusActionForm from '../../modules/admins/adminChampDashboardForms/AdminChampLeadStatusActionForm'
import UpdateStatusTaskList from '../../modules/taskLists/UpdateStatusTaskList'
import AdminTaskListStatusForm from '../../modules/admins/adminChampDashboardForms/AdminTaskListStatusForm'
import { JOB_STATUSES_FORMATTED } from './constants/JobStatusLists'
import AdminTaskListTaskDateForm from '../../modules/admins/adminChampDashboardForms/AdminTaskListTaskDateForm'
import AdminDueDateFormChampLead from '../../modules/admins/adminChampDashboardForms/AdminDueDateFormChampLead'
import { DateFormate } from '../toggleColumns/DateFormate'
import AdminUpdatePrivateTagsListChampLead from '../../modules/admins/adminChampDashboardForms/AdminUpdatePrivateTagsListChampLead'
import ClickUpCallChamp from './ClickUpCallChamp'

const ChatSection = ({ toggleDrawer, setShowImagesToggle, setShowImagesLink, cleanerid, isNextOrPrev, previuosHandler, nextHandler }: any) => {
    LocalBaseURL()
    const dispatch = useDispatch()
    // ⚠️ data dispatch from champ lead so before removing dispatch from champ also handle this 
    const visitorStatusesFromChampLead = useSelector((store: any) => store.VisitorDataReducer.visitorStatuses)
    // ⚠️ data dispatch from champ lead so before removing dispatch from champ also handle this 

    const CallAlertRender = useSelector((store: any) => store.RefreshPage.CallAlertRender)
    const champsHistoryData = useSelector((store: any) => store.champsReducer.champsHistoryData[cleanerid])
    const champChatMessages = useSelector((store: any) => store.champsReducer.champChatMessages[cleanerid])
    const rowDataForChampDashboard = useSelector((store: any) => store.champsReducer.rowDataForChampDashboard[cleanerid])
    const [storRowDataForChampDashboard, setStorRowDataForChampDashboard] = useState<any>(rowDataForChampDashboard)
    useEffect(() => { setStorRowDataForChampDashboard(rowDataForChampDashboard) }, [rowDataForChampDashboard?.id])

    let champTicket = champChatMessages?.ticket
    let champsDetails = champsHistoryData?.ChampDetail
    const [storeChampRowData, setStoreChampRowData] = useState<any>(champsDetails)
    useEffect(() => { setStoreChampRowData(champsDetails) }, [champsDetails?.id])

    const champsWhatsappMessages = useSelector((store: any) => store.champsReducer.champsWhatsappMessages[cleanerid])
    const MessageSource = useSelector((store: any) => store.champsReducer.MessageSource)

    // dymmy state these all will get changes into redux state  based on api  
    const userid: any = JSON.parse(localStorage.getItem("user") || "")
    const localKeyCheck = JSON.parse(localStorage.getItem("API") || "0")
    const [whatsappReplies, setWhatsappReplies] = useState<any>([])
    const [WhatsappTemplateMessage, replyWhatsappTemplateMessage] = useState<any>(null)
    const [isTemplate, setIsTemplate] = useState<any>(false)
    const [messages, setMessage] = useState<string>("")
    const [SwitchStatus, setSwitchStatus] = useState<any>("tickets");
    const [customerWhatsappIsActive, setcustomerWhatsappIsActive] = useState<any>(null);
    const [isloading2, setloading2] = useState<boolean>(false);
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [key, setKey] = useState(0);
    // dymmy state these all will get changes into redux state  based on api  
    const refss: any = useRef(null)

    // status update modal states 
    const [sourceUpdateModal, setSourceUpdateModal] = React.useState<boolean>(false)
    const [updateCategoryPopup, setUpdateCategoryPopup] = React.useState<boolean>(false)
    const [ticketStatusUpdateModal, setTicketStatusUpdateModal] = React.useState<boolean>(false)
    const [assignToUserUpdateModal, setAssignToUserUpdateModal] = React.useState<boolean>(false)
    const [showChampLeadSubArea, setShowChampLeadSubArea] = React.useState<boolean>(false)
    const [showChampLeadStatus, setShowChampLeadStatus] = React.useState<boolean>(false)
    const [showTaskListStatus, setShowTaskListStatus] = React.useState<boolean>(false)
    const [showTaskListTaskDate, setShowTaskListTaskDate] = React.useState<boolean>(false)
    const [showChampLeadDueDate, setShowChampLeadDueDate] = React.useState<boolean>(false)
    const [showChampLeadPrivateTag, setShowChampLeadPrivateTag] = React.useState<boolean>(false)
    const setCloseChamLeadStatus = () => setShowChampLeadStatus(false)
    const setCloseChamLeadDueDate = () => setShowChampLeadDueDate(false)
    const setCloseTaskListTaskDate = () => setShowTaskListTaskDate(false)
    const setCloseTaskListPrivateTag = () => setShowChampLeadPrivateTag(false)
    async function TicketRepliesData() {
        setloading2(true)
        dispatch(openChatByMessage({ ticketid: champTicket?.id }, cleanerid) as any)
        setloading2(false)
    }

    React.useEffect(() => {
        isTemplate && setMessage("")
    }, [isTemplate, messages])

    const sendMessage = async (e: React.MouseEvent) => {
        let ChampTicketId = document.querySelector("[data-champTicketId]")?.getAttribute("data-champTicketId") as any
        let newMessage = messages
        newMessage = newMessage.replace(/\r?\n/g, '<br />')
        setButtonLoading(true)

        const isPrivateMessage = e.currentTarget.id === 'private-message';
        isPrivateMessage ?
            await postRequestWithFile(URL_EndPoints()?.notesOnChampTicketByAdmin, { "ticketid": champTicket?.id, "userid": userid, "answer": newMessage, "image": file }, setloading2)
            :
            await postRequestWithFile(URL_EndPoints()?.replyOnCleanerTicketByAdmin, { "ticketid": champTicket?.id, "userid": userid, "answer": newMessage, "image": file }, setloading2)

        setButtonLoading(false)
        setMessage('')
        handleResetClick()
        TicketRepliesData()

    }

    // whats app reply message  handler
    const ReplyToWhatappMessageHandler = async () => {
        if (!isTemplate) {
            setButtonLoading(true)
            if (messages) {
                const whatsappMessage = await postRequest(URL_EndPoints()?.sendOpenSessionWhatsAppToChamp, {
                    ticketId: champTicket?.id,
                    champId: champsDetails?.id,
                    phoneNumber: champsDetails?.phone,
                    message: messages,
                    userId: userid,
                }, setloading2)
                if (whatsappMessage?.data?.status == 200) {
                    setButtonLoading(false)
                    dispatch({ type: CHAMPS_CHAT_MESSAGES, payload: { data: [...champChatMessages?.data, whatsappMessage?.data?.data] } })
                }
                setMessage("")
            }
            else {
                setButtonLoading(false)
                toast.error("Text message missing", { position: "top-center" })
            }
            setButtonLoading(false)
            // TicketReplies()
        }

        else {
            if (WhatsappTemplateMessage != null) {
                setButtonLoading(true)
                const whatsappMessageTemplate = await postRequest(URL_EndPoints()?.sendTemplateWhatsAppToChamp, {
                    ticketId: champTicket?.id,
                    champId: champsDetails?.id,
                    phoneNumber: champsDetails?.phone,
                    templateId: +WhatsappTemplateMessage?.value,
                    userId: userid,
                }, setloading2)
                if (whatsappMessageTemplate?.data?.status == 200) {
                    setButtonLoading(false)
                    toast.success("Message Has been sent successfully", { position: "top-center" })
                    TicketRepliesData()
                    replyWhatsappTemplateMessage(null)
                }
            }
            else {
                setButtonLoading(false)
                toast.error("Templateid missing", { position: "top-center" })
            }
            setButtonLoading(false)
        }
    }

    const SwitchToWhatsappMessage = async (from: any) => {
        const CustomerNumberIsActive = champsWhatsappMessages?.filter((ele: any) => ele.statusString == "SENT")

        const MessageSorted: any = champsWhatsappMessages?.sort((a: any, b: any) => {
            let a1: any = new Date(b.created)
            let a2: any = new Date(a.created)
            return a2 - a1
        })

        const latestMessage = MessageSorted?.length > 0 ? MessageSorted[MessageSorted?.length - 1] : {}
        const times: any = moment(new Date(latestMessage?.created)).fromNow() || ""

        if (from == "Whatsapp Message") {
            const createdAtTimestamp = latestMessage?.created
            const createdAtMoment = moment(createdAtTimestamp);
            const currentMoment = moment();
            const duration = moment.duration(currentMoment.diff(createdAtMoment));
            const isLessThan24Hours = duration.asHours() <= 24;
            setMessage(isLessThan24Hours ? "Customer is  active" : "Customer is not active")
            setcustomerWhatsappIsActive(isLessThan24Hours ? "isExpired" : null)
        }
        else {
            setMessage("")
            setcustomerWhatsappIsActive(null)
        }
        setSwitchStatus(from)
    }

    const SeeImageFullWidth = (images: any) => {
        setShowImagesLink(images)
        setShowImagesToggle((prev: any) => !prev)
    }


    const scrollBottomAuto = () => {
        setTimeout(() => {
            refss.current?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
    }

    React.useEffect(() => {
        scrollBottomAuto()
    }, [messages, champChatMessages, MessageSource])

    const textRef: any = React.useRef();
    React.useEffect(() => {
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
    }, [messages]);


    //image uploading
    const onChangeFile = (event: any) => {
        setFileSelected(true)
        setFile(event?.target?.files[0])
    }

    const isDrawer = false
    const handleResetClick = () => {
        setFile(null);
        setFileSelected(false);
        setKey(key + 1);
    };

    // update Status for tickeet 
    const handleClose = () => {
        setSourceUpdateModal(false)
        setUpdateCategoryPopup(false)
        setTicketStatusUpdateModal(false)
        setAssignToUserUpdateModal(false)
    }

    const [Re_Render, setRe_Render] = React.useState<any>(null)
    const [showCallDropdown, setShowCallDropdown] = useState(false)
    const [callDropdownNumber, setCallDropdownNumber] = useState<any>(null)
    const [defaultNumberValue, setDefaultNumberValue] = useState<any>(null)
    const [numberListData, setNumberListData] = useState([])
        const getNumbers = async () => {
            const response = await getRequest(URL_EndPoints(adminQueryGenerator("getCallingNumbers", { userType: 1 }))?.getCallingNumbers, null)
            setNumberListData(response?.data?.data)
            const defValue = response?.data?.data?.find((ele: any) => ele?.is_default == 1)
            setCallDropdownNumber(defValue ? defValue?.phone : defValue)
            setDefaultNumberValue(defValue ? { item: { label: defValue?.phone, value: defValue?.id } } : null)
        }
    
        useEffect(() => {
            getNumbers()
        }, [])
    
    // const callToCleaner = async () => {
    //     setRe_Render(Math.random())
    // }

    const callToCleaner = async () => {
        setShowCallDropdown(true)
    }

    
        const CallNowButton = () => {
            if (!callDropdownNumber) {
                toast.error("Please select number", { position: "top-center" })
                return
            }
            setRe_Render(Math.random())
        }

    const WhatSappMessageHandler = async () => {
        dispatch(openChatByWhatsapp({ champPhone: champsDetails?.phone, pageSize: 50, pageNumber: 1 }, cleanerid) as any)
        dispatch({
            type: "CHAT_SOURCE", payload: {
                CurrentMessageSourceRender: Math.random(),
                from: "whatsappMessages"
            }
        })
    }

    // ✅ below logic is related to champ leads table  
    const subAreaPrevData4subAreaForm = champsDetails?.cleaner_sub_area_details ? { label: champsDetails?.cleaner_sub_area_details?.name, value: champsDetails?.cleaner_sub_area_details?.id } : null

    const setUpdatedSubAreaName = (newSubArea: any) => {
        if (newSubArea?.isSet) {
            storRowDataForChampDashboard.cleaner_sub_area_details = storRowDataForChampDashboard.cleaner_sub_area_details?.name ? { ...storRowDataForChampDashboard.cleaner_sub_area_details } : {}
            storRowDataForChampDashboard.cleaner_sub_area_details.name = newSubArea?.payload?.sub_area_id_item?.item?.name
            setStorRowDataForChampDashboard(storRowDataForChampDashboard)
        }
        // 👉 keyName+"_"+"item" == for subArea update ==> sub_area_id_item?.item?.name
    }

    const setUpdatedRowDataForChampDashboard = (newRow: any) => {
        setStorRowDataForChampDashboard(newRow)
    }

    const [champLeadStatus, setChampLeadStatus] = useState<any>(null)
    useEffect(() => {
        const champLeadStatusDefaultName = visitorStatusesFromChampLead?.find((ele: any) => {
            return ele?.id === storRowDataForChampDashboard?.status_id

        })
        champLeadStatusDefaultName && setChampLeadStatus(champLeadStatusDefaultName);
    }, [visitorStatusesFromChampLead, storRowDataForChampDashboard?.status_id])

    // task list started ------

    // ✅ below logic is related to champ leads table  
    const closeChat = () => {
        setIsChatOpenedOrNot(null, true, "champ_chat_opened")
        dispatch({
            type: "NOTIFICATION_DATA", payload: {
                chatName: null,
                id: null,
                data: {}
            }
        })
        toggleDrawer()
    }

    return (
        <div className='border'>
            <div className='p-2  overflow-hidden ' style={{ height: "120px", width: "100%" }} >
                <div className='p-1 d-flex justify-content-between '>
                    <span className='fw-bold fs-3 cursor-pointer'>{champsHistoryData?.ChampDetail?.first_name || champsHistoryData?.ChampDetail?.last_name ? champsHistoryData?.ChampDetail?.first_name + " " + champsHistoryData?.ChampDetail?.last_name : "No Name"}</span>
                    <span className='cursor-pointer text-end' onClick={closeChat}><FontAwesomeIcon icon={faClose} color='#009ef7' size='xl' /></span>
                </div>
                <BulkUtilButtonWrapper>

                    {/*  👇 ✅ These action feature is are common for all list from wherever the champ dashboard will get opened  */}

                    {/* remove comment at last  */}
                    <span id={"ticket-status-champ"}>
                        <TicketStatus ticketStatus={champTicket?.ticketstatus?.name} ticketStatusId={champTicket?.ticketstatus?.id} marginTops={true} setShow={setTicketStatusUpdateModal} />
                        <ReactTooltip
                            anchorId={"ticket-status-champ"}
                            className="bg-dark"
                            content={"Ticket Status"}
                        />
                    </span>


                    <ChampActionLayout
                        toolTipAnchorId="update-source-champ"
                        toolTipTitle="Update Source"
                        title={
                            champTicket?.ticketsource?.name ? champTicket?.ticketsource?.name : "No Source"
                        }
                        setShow={setSourceUpdateModal}
                    />

                    {/* <ChampActionLayout
                        toolTipAnchorId="ticket-category-champ"
                        toolTipTitle="Ticket Category"
                        title={
                            champTicket?.ticketcategory
                                ? champTicket?.ticketcategory?.category_name
                                : 'No Category'
                        }
                        setShow={setUpdateCategoryPopup}
                    /> */}

                    <ChampActionLayout
                        toolTipAnchorId="update-sub-category-champ"
                        toolTipTitle="Sub Category Update"
                        title={
                            champTicket?.ticketsubcat?.masterticketsubcategory
                                ? champTicket?.ticketsubcat?.masterticketsubcategory?.subcategory_name
                                : 'No Sub Category'
                        }
                        setShow={setUpdateCategoryPopup}
                    />

                    {/* remove comment at last  */}

                    {/* 👇 ✅ These action feature is specific to list   */}
                    {/* 👇 feature from champ lead lsit  */}
                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.champLeadSubArea ? <ChampActionLayout
                            toolTipAnchorId="update-champ-lead-sub-area-champ-dashboard"
                            toolTipTitle="Update Cham Lead Sub Area"
                            title={
                                storRowDataForChampDashboard?.cleaner_sub_area_details
                                    ? storRowDataForChampDashboard?.cleaner_sub_area_details?.name
                                    : 'No Sub Area Champ Lead '
                            }
                            setShow={setShowChampLeadSubArea}
                        /> : <></>
                    }

                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.champLeadStatus ? <ChampActionLayout
                            toolTipAnchorId="update-champ-lead-status-champ-dashboard"
                            toolTipTitle="Update Champ Lead Status"
                            title={
                                champLeadStatus
                                    ? champLeadStatus?.name
                                    : 'No Status Champ Lead '
                            }
                            setShow={setShowChampLeadStatus}
                        /> : <></>
                    }

                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.champLeadDueDate ? <ChampActionLayout
                            toolTipAnchorId="update-champ-lead-dueDate-champ-dashboard"
                            toolTipTitle="Update Champ Lead Due Date"
                            title={
                                storRowDataForChampDashboard?.cleanerTicketsSingle ?
                                    <DateFormate date={storRowDataForChampDashboard?.cleanerTicketsSingle?.last_date_resolution} from="Visitor_Due_Date" />
                                    : 'Not Set'
                            }
                            setShow={setShowChampLeadDueDate}
                        /> : <></>
                    }

                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.champLeadPrivateTag ? <>
                            {
                                storRowDataForChampDashboard?.private_tag?.split(",").map((ele: any, index: any) => {
                                    return <ChampActionLayout
                                        toolTipAnchorId={`update-champ-lead-private-tag-champ-dashboard-${index}`}
                                        toolTipTitle="Update Champ Lead Private Tag"
                                        title={ele}
                                        setShow={setShowChampLeadPrivateTag}
                                    />
                                })

                            }

                        </> : <></>
                    }
                    {/*👇 feature from champ lead lsit  */}

                    {/*👇 feature from task list table   */}
                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.taskListStatus ? <ChampActionLayout
                            toolTipAnchorId="update-task-list-status-champ-dashboard"
                            toolTipTitle="Update Task List Status"
                            title={
                                JOB_STATUSES_FORMATTED[+storRowDataForChampDashboard?.job_status] ? JOB_STATUSES_FORMATTED[+storRowDataForChampDashboard?.job_status] : "No Task List Status"
                            }
                            setShow={setShowTaskListStatus}
                        /> : <></>
                    }

                    {
                        enableFeatures4ChampDashboard(catchCurrentOpenedUrl())?.taskListTaskDate ? <ChampActionLayout
                            toolTipAnchorId="update-task-list-taskdate-champ-dashboard"
                            toolTipTitle="Update Task List Task Date"
                            title={
                                moment(storRowDataForChampDashboard?.attendencedate).format("DD-MM-YYYY") === "Invalid date" ? "Not Set" : moment(storRowDataForChampDashboard?.attendencedate).format("DD-MM-YYYY")
                            }
                            setShow={setShowTaskListTaskDate}
                        /> : <></>
                    }

                    {/*  👇 feature from task list table   */}

                    {/* 👆 ✅ These action feature is specific to list   */}

                    {
                        champTicket?.ticket_assigns?.length > 0 && champTicket?.ticket_assigns != undefined ?
                            champTicket?.ticket_assigns?.map((assign: any, index: any) => {
                                let uniqueToolId = index + "_" + assign?.name ? assign?.name : "NoName"
                                return <ChampActionLayout
                                    toolTipAnchorId={uniqueToolId}
                                    toolTipTitle="Assign To User"
                                    title={
                                        assign?.name ? assign?.name : "Not Assigned"
                                    }
                                    setShow={setAssignToUserUpdateModal}
                                />
                            })
                            : <ChampActionLayout
                                toolTipAnchorId="assign-to-user-form"
                                toolTipTitle="Assign To User"
                                title="Not Assgined"
                                setShow={setAssignToUserUpdateModal}
                            />
                    }



                </BulkUtilButtonWrapper>
            </div>

            <InternalTicketTicketOpen WhatSappMessageHandler={WhatSappMessageHandler} callToCleaner={callToCleaner} champsDetails={champsDetails} previuosHandler={previuosHandler} nextHandler={nextHandler} isNextOrPrev={isNextOrPrev} />



            {
                showCallDropdown ? <ClickUpCallChamp
                    show={showCallDropdown}
                    setShow={setShowCallDropdown}
                    CallNowButton={CallNowButton}
                    listData={numberListData}
                    setCallDropdownNumber={setCallDropdownNumber}
                    defValue={defaultNumberValue}
                /> : <></>
            }

            <div className='p-2'>
                {
                    MessageSource?.from == "whatsappMessages" ?
                        <WhatsappChats props={{ isDrawer, SeeImageFullWidth, whatsappReplies, messages, cleanerid }} />
                        :
                        <ChampChats props={{ isloading2, isDrawer, SeeImageFullWidth, fileSelected, file, handleResetClick, onChangeFile, key, textRef, refss, messages, setMessage, sendMessage, buttonLoading, from: "ticketschat", SwitchStatus, SwitchToWhatsappMessage, customerWhatsappIsActive, ReplyToWhatappMessageHandler, replyWhatsappTemplateMessage, isTemplate, setIsTemplate, champsDetails, cleanerid }} />
                }
            </div>

            {assignToUserUpdateModal &&
                <AssigningFormToUserForm
                    handleClose={handleClose}
                    show={assignToUserUpdateModal}
                    ticketId={champTicket?.id}
                    invokedTicket={TicketRepliesData}
                    champTicket={champTicket}
                ></AssigningFormToUserForm>
            }

            {ticketStatusUpdateModal &&
                <UpdateTicketStatusForm
                    handleClose={handleClose}
                    show={ticketStatusUpdateModal}
                    ticketId={champTicket?.id}
                    invokedTicket={TicketRepliesData}
                ></UpdateTicketStatusForm>
            }

            {updateCategoryPopup &&
                <UpdateTicketCategoryForm
                    handleClose={handleClose}
                    show={updateCategoryPopup}
                    ticketId={champTicket?.id}
                    invokedTicket={TicketRepliesData}
                ></UpdateTicketCategoryForm>
            }

            {sourceUpdateModal &&
                <UpdateSourceForm
                    handleClose={handleClose}
                    show={sourceUpdateModal}
                    ticketId={champTicket?.id}
                    invokedTicket={TicketRepliesData}
                ></UpdateSourceForm>
            }

            {/* 👇✅ list wise specific action feature forms   */}
            {/* champ lead features*/}
            {
                showChampLeadSubArea ? <AdminChampActionForm
                    show={showChampLeadSubArea}
                    setShow={setShowChampLeadSubArea}
                    setStoreRow={setUpdatedSubAreaName}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={champDashboardSubAreaActionFormData}
                    staticFormData={[]}
                    initialData={{
                        champId: [champsDetails?.id],
                        sub_area_id: subAreaPrevData4subAreaForm?.value ? subAreaPrevData4subAreaForm?.value : null,
                    }}
                    prevInitialData={{ item: subAreaPrevData4subAreaForm }}
                    keyName="subAreaAddOrUpdateForChamp1"
                    formTitle={`Update Sub Area`}
                    formSubmitApiUrl="addCleanerAddressInBulk"
                /> : <></>
            }

            {
                showChampLeadStatus ? <AdminChampLeadStatusActionForm
                    FilteredData={{
                        show: showChampLeadStatus,
                        handleCloseForm: setCloseChamLeadStatus,
                        selectedId: {
                            status_id: storRowDataForChampDashboard?.status_id,
                            champId: storRowDataForChampDashboard?.id,
                            tickets: storRowDataForChampDashboard?.cleanerTickets,
                        },
                    }}
                    setStoreRow={setUpdatedRowDataForChampDashboard}
                    row={storRowDataForChampDashboard}
                    prevInitialData={champLeadStatus ? { label: champLeadStatus?.name, value: champLeadStatus?.id } : null}

                /> : <></>
            }

            {
                <AdminDueDateFormChampLead FilteredData={{
                    show: showChampLeadDueDate,
                    ticket_history_id: 0,
                    ticket_id: storRowDataForChampDashboard?.cleanerTicketsSingle ? storRowDataForChampDashboard?.cleanerTicketsSingle?.id : 0,
                    customerid: storRowDataForChampDashboard?.id,
                    user_id: userid,
                    prev_due_date: storRowDataForChampDashboard?.cleanerTicketsSingle ? storRowDataForChampDashboard?.cleanerTicketsSingle?.last_date_resolution : '',
                }} handleCloseForm={setCloseChamLeadDueDate} setStoreRow={setUpdatedRowDataForChampDashboard} row={storRowDataForChampDashboard} />
            }


            {/* champ lead features*/}

            {
                showTaskListStatus && <AdminTaskListStatusForm
                    show={showTaskListStatus}
                    setShow={setShowTaskListStatus}
                    setRowData={setUpdatedRowDataForChampDashboard}
                    row={storRowDataForChampDashboard}
                    prevInitialData={JOB_STATUSES_FORMATTED[+storRowDataForChampDashboard?.job_status] ? { label: JOB_STATUSES_FORMATTED[+storRowDataForChampDashboard?.job_status], value: storRowDataForChampDashboard?.job_status } : null}
                />
            }

            {
                showTaskListTaskDate && <AdminTaskListTaskDateForm FilteredData={{
                    show: showTaskListTaskDate,
                    attendencedate: storRowDataForChampDashboard?.attendencedate,
                    taskId: storRowDataForChampDashboard?.id,
                }} handleCloseForm={setCloseTaskListTaskDate} setStoreRow={setUpdatedRowDataForChampDashboard} row={storRowDataForChampDashboard} />
            }

            {showChampLeadPrivateTag ?
                <AdminUpdatePrivateTagsListChampLead
                    showModal={showChampLeadPrivateTag}
                    handleClose={setCloseTaskListPrivateTag}
                    row={storRowDataForChampDashboard}
                    setRowData={setUpdatedRowDataForChampDashboard}
                /> : <></>}


            {/* 👇✅ list wise specific action feature forms   */}


            <SweetAlertConfirm
                Render={Re_Render}
                payloads={{
                    userid: userid,
                    cleanerid: cleanerid,
                    callingNumberId: callDropdownNumber,
                }}
                localKey={localKeyCheck}
                Apis={MAIN_ADMIN_BASE_API_URL}
                TestApis={TEST_ADMIN_BASE_API_URL}
                ApiEndPoints={"/clickToCallFromTicket"} />
            <SweetAlertCloseTimer Re_Render={CallAlertRender} />
        </div>
    )
}

export default ChatSection
