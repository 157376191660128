import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { EASEBUZZ_PAYMENT_STATUS } from '../../../consts/DynamicFilteration/components/CommonData';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { postRequest } from '../../../consts/Server/Requests';
import { adminToastRunner } from '../../admins/adminUtils';

const InititateTransferHiringPoints = ({ row }: any) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    async function initiateTransfer() {
        try {
            setIsDisabled(true)
            setIsLoading(true)
            const response = await postRequest(URL_EndPoints()?.initiateTransferHiringPoint, {
                payoutIds: [row.id],
            }, setIsLoading)
            adminToastRunner(response)
        } catch (error) {
            toast.error("Something went wrong")
        } finally {
            setIsDisabled(false)
            setIsLoading(false)
        }
    }

    const isEaseBuzzStatus = (payout_status: string) => EASEBUZZ_PAYMENT_STATUS.some((eps) => eps.id === payout_status) && !["failure", "rejected"].includes(payout_status)

    return isEaseBuzzStatus(row?.payout_status) ? (
        <Button disabled={true} className='btn-sm'>
            Initiated
        </Button>
    ) : (
        <Button
            onClick={() => {
                if (window.confirm('Are you sure you want to initiate payment?')) {
                    initiateTransfer()
                }
            }}
            disabled={isDisabled}
            className='btn-sm'
        >
            Initiate Payment {isLoading ? '...' : ''}
        </Button>
    )
}

export default InititateTransferHiringPoints
