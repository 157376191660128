


import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { ColModal, GroupModal, RowModal, SelectModal } from '../../../consts/widgets/forms/FormsModals';
import GiveRewardSelectBox from '../../../consts/Select/GiveRewardSelectBox';

const GiveRewardPoints = ({ row, show, setShow }: any) => {
    const [loader, setLoader] = useState(false)
    const [fileSelected, setFileSelected] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [key, setKey] = useState(0);

    const [reasonLists, setReasonLists] = useState<any>([])
  
    const [payload, setPayload] = useState<any>({
        beneficiaryId: row?.cleanerArea?.cleaner_id,
        beneficiaryType: row?.userType,
        amount: 0,
        referenceId: null,
        comment: "",
        media: "",
        typeId: 0,
        reasonId: null,
        serviceDate: ""
    })

    const onChangeFile = (event: any) => {
        setFileSelected(true)
        setFile(event?.target?.files[0])
        setPayload({ ...payload, ["media"]: event?.target?.files[0] })
    }

    const handleResetClick = () => {
        setKey(key + 1)
        setFile(null);
        setPayload({ ...payload, ["media"]: null })
        setFileSelected(false);
    }
    useEffect(() => {

        async function loadInitialData() {
            const reasonLists = await getRequest(URL_EndPoints()?.getReasons, null)
            setReasonLists(reasonLists?.data?.data)
        }
        loadInitialData()

    }, [])


    const giveRewardHandler = async () => {

        if (payload?.reasonId) {
            setLoader(true)
            const { data } = await postRequest(URL_EndPoints()?.addRequestForReward, payload, setLoader)
            if (data?.status == 200) {
                toast.success(data?.msg, { position: "top-center" })
                setShow(false)
            }
            else {
                toast.error(data?.msg, { position: "top-center" })
                setShow(false)
            }
        }
        else {
            toast.error("Something is missing", { position: "top-center" })
        }

    }

    const onchangeHandler = (event: any, name: any) => {
        if (!event) {
            setPayload({ ...payload, ["reasonId"]: null, ["amount"]: 0 })
        }
        else {
            setPayload({ ...payload, ["reasonId"]: event?.value, ["amount"]: event?.item?.default_amount })
        }
    }

    return (
        <>




            {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-small"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='fs-5 text-muted'>Give Reward Points</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-12 ">
                        <RowModal classes="d-flex flex-wrap justify-content-between ">

                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <SelectModal
                                        classes="mb-2"
                                        title="Select Type"
                                        name="type"
                                        keyName="name"
                                        id="id"
                                        isData={[{ name: "points", id: "points" }, { name: "cash", id: "cash" }]}
                                        payload={payload}
                                        setPayload={setPayload}
                                        defValue={[]}
                                    />
                                </GroupModal>
                            </ColModal>


                         
                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <GiveRewardSelectBox
                                        handleChangeInputData={onchangeHandler}
                                        HeaderTitle="Select Reason"
                                        SelectData={reasonLists}
                                        DynamicKey={"reason"}
                                        id={"id"}
                                        name="reasonId"
                                    ></GiveRewardSelectBox>
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <input
                                        placeholder='Points'
                                        name='amount'
                                        type='text'
                                        value={payload?.amount}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <input
                                        placeholder='Service Date'
                                        name='serviceDate'
                                        type='date'
                                        value={payload.serviceDate}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>
                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <div className='d-flex  align-items-center mb-2'>
                                        <div className='col-1'>
                                            <input type="file" key={key} name='image' id='image' onChange={onChangeFile} style={{ "display": "none" }} />
                                            <label htmlFor="image"><i className="fa-solid fa-upload fs-3 text-primary mt-4 cursor-pointer"></i></label>
                                        </div>

                                        <span className="mt-3 fw-bold">Image/ Pdf Upload
                                        </span>

                                    </div>
                                    {
                                        fileSelected ?
                                            <div className="d-flex justify-content-between col-12 me-2 px-2" style={{ "backgroundColor": "#e2e2e2", "padding": "2px" }}>
                                                <div className="p-1">
                                                    {file?.name}({(file?.size / (1024 * 1024)).toFixed(2)}MB)
                                                </div>
                                                <div className="p-1" onClick={handleResetClick}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </GroupModal>
                            </ColModal>

                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="" >
                                    <input
                                        placeholder='comment here...'
                                        name='comment'
                                        type='textarea'
                                        value={payload.comment}
                                        className='form-control  mb-3 mb-lg-0'
                                        autoComplete='off'
                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                    />
                                </GroupModal>
                            </ColModal>
                            <ColModal classes="mb-4 col-12 col-md-6 col-sm-6 col-lg-12">
                                <GroupModal classes="col-12" >
                                    <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6 col-12`} onClick={giveRewardHandler}>

                                        {loader ?
                                            <>

                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                <span>Assigning...</span>

                                            </>

                                            : " Assgin / Change"}

                                    </button>
                                </GroupModal>
                            </ColModal>
                        </RowModal>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>

    )
}
export default GiveRewardPoints
