// ChatApp.js
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import ChatWindow from "./ChatWindow";
import { getRequest, postRequest } from "../../consts/Server/Requests";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";
import { useSelector } from "react-redux";

const ChatApp = () => {
  const liveChatMessage = useSelector((store: any) => store.adminReducer.liveChatMessage)

  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [selectedChatMap, setSelectedChatMap] = useState<any>({});
  const [chats, setChats] = useState<any>([])
  const [otherMembers, seOtherMembers] = useState<any>([])
  const [pending, setPending] = useState(false)
  const [paginationLoader, setPaginationLoader] = useState(false)
  const [page, setPage] = useState(1);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const searchRef = useRef<any>(null)
  const scrollContainerRef = useRef<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const [areaManagerList, setAreaManagerList] = useState([])

  useEffect(() => {
    if (liveChatMessage?.render && liveChatMessage?.message?.group_id) {
      if(selectedChat?.id != liveChatMessage?.message?.group_id) {
        const updatedCount = chats?.map((ele: any) => ele?.id == liveChatMessage?.message?.group_id && liveChatMessage?.message?.count ? ({ ...ele, groupMember: { ...ele?.groupMember, unread_message_count: ele?.groupMember?.unread_message_count + 1 } }) : ele)
        setChats(updatedCount)
      }else if(selectedChat) {
        // Chat is open
        postRequest(URL_EndPoints()?.markGroupMessagesSeen, {
          "groupId": selectedChat?.id
        }, null)  
      }
      setSelectedChatMap((prevMessage: any) => {
        const existingMessages = prevMessage[liveChatMessage?.message?.group_id] || [];
        return {
          ...prevMessage,
          [liveChatMessage?.message?.group_id]: [...existingMessages, liveChatMessage?.message],
        };
      });
    }
    return
  }, [liveChatMessage?.render])


  const getChats = async (pageNumber = 1, search?: string, isNew?: any, isPagination?: any, filterData?: any) => {
    if (isPagination) {
      setPaginationLoader(true)
    }
    else {
      setPending(true);
    }
    const response = await postRequest(URL_EndPoints()?.getGroupChat,
      {
        start: pageNumber,
        length: 10,
        search: search || "",
        orders: "desc",
        columns: "id",
        sortby: "",
        direction: "desc",
        searchType: "any",
        filterData: filterData || {}
      }, isPagination ? setPaginationLoader : setPending);
    setRecordsTotal(response?.data?.recordsTotal)
    const newChats = response?.data?.data || [];
    const otherMembers = response?.data?.otherMembers || [];

    seOtherMembers(otherMembers);

    if (newChats.length < 10) setHasMore(false);
    else setHasMore(true);

    if (searchRef?.current) {
      setPage(pageNumber);
      setChats(newChats);
      searchRef.current = false;
      if (isPagination) {
        setPaginationLoader(false)
      }
      else {
        setPending(false);
      }
      return;
    }

    setPage(pageNumber);
    setChats((prev: any) => isNew ? newChats : [...prev, ...newChats]);
    // setPending(false);
  };


  // const handleScrollling = (scroll: any) => {
  //   const container = scrollContainerRef.current;
  //   const bottom = container.scrollHeight - container.clientHeight - container.scrollTop;

  //   if (bottom <= 0 && !pending) {
  //     const previousScrollHeight = container.scrollHeight;
  //     const nextPage = page + 1;

  //     getChats(nextPage).then(() => {
  //       setTimeout(() => {
  //         const newScrollHeight = container.scrollHeight;
  //         container.scrollTop = newScrollHeight - previousScrollHeight ;
  //       }, 50);
  //     });

  //     setPage(nextPage);
  //   }
  // };

  const handleScrollling = (scroll: any) => {
    // const container = scrollContainerRef.current;
    // const bottom = container.scrollHeight - container.clientHeight - container.scrollTop;

    // if (bottom <= 0 && !pending && hasMore) {
    //   const previousScrollHeight = container.scrollHeight;
    //   const nextPage = page + 1;

    //   getChats(nextPage).then(() => {
    //     setTimeout(() => {
    //       const newScrollHeight = container.scrollHeight;
    //       container.scrollTop = newScrollHeight - previousScrollHeight;
    //     }, 50);
    //   });

    //   setPage(nextPage);
    // }
  };

  const [search, setSearch] = useState("")

  useEffect(() => {
    let timer = setTimeout(async function () {
      if (search) {
        searchRef.current = true
        setPending(true)
        getChats(1, search)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [search])

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container && container.scrollHeight <= container.clientHeight) {
      container.scrollTop = container.scrollHeight;
    }
  }, [chats.length]); // or [chats] if array might change entirely


  useEffect(() => {
    !search && getChats(1, "");
    !search && setPage(1);
  }, [search])

  useEffect(() => { 
    async function getSupervisorList() {
      const response = await getRequest(URL_EndPoints()?.getSupervisorList, null)
      console.log(response)
      setAreaManagerList(response?.data?.data)
    }
    getSupervisorList()
  }, [])

  const onSelectChat = (chat: any) => {
    const updatedCount = chats?.map((ele: any) => ele?.id == chat?.id ? ({ ...ele, groupMember: { ...ele?.groupMember, unread_message_count: 0} }) : ele)
    setChats(updatedCount)
    // Chat is open
    postRequest(URL_EndPoints()?.markGroupMessagesSeen, {
      "groupId": chat?.id
    }, null)
    setSelectedChat(chat)
  }

  return (
    <div className="d-flex bg-white">
      <div className={`sidebar-container ${selectedChat ? 'd-none d-md-block' : 'd-block w-100'}`} style={{ width: "30%", minHeight: "90vh" }}>
        {
          <Sidebar onSelectChat={onSelectChat} selectedChat={selectedChat} chats={chats} pending={pending} handleScrollling={handleScrollling} setSearch={setSearch} search={search} getChats={getChats} otherMembers={otherMembers} refss={scrollContainerRef} page={page} recordsTotal={recordsTotal} paginationLoader={paginationLoader} areaManagerList={areaManagerList} />
        }
      </div>
      <div className={`chat-container flex-grow-1 d-flex flex-column ${selectedChat ? 'd-block' : 'd-none d-md-block'}`} style={{ minHeight: "100vh", width: "70%" }}>
        <ChatWindow selectedChat={selectedChat} onBack={() => setSelectedChat(null)} setSelectedChatMap={setSelectedChatMap} selectedChatMap={selectedChatMap} getChats={getChats} setChats={setChats} />
      </div>
    </div>
  );
};

export default ChatApp;