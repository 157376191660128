import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"
import { AllFielSearch } from "../../../../consts/FieldSearch/AllFieldSearch"
import { getRequest, postRequest } from "../../../../consts/Server/Requests"
import { URL_EndPoints } from "../../../../consts/Server/URL_EndPoints"
import { CheckboxModal, ColModal, GroupModal, RowModal, SelectModal } from "../../../../consts/widgets/forms/FormsModals"
import { QueryParams } from "../../../../consts/Server/QueryParams"
import { AllowanceColumn } from "./AllowanceColumn"
import IsLoading from "../../../../consts/IsLoading/IsLoading"

export const AssignAllowance = ({ row, show, setShow }: any) => {

    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<boolean>(false)
    const [filterData, setfilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [editLoader, setEditLoader] = useState<boolean>(false)
    const [allowanceRecord, setAllowanceRecords] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        cl_allowance_id: 0,
        allowance_id: row?.id,
        cleanerid: row?.id,
        type: null,
        amount: "",
        status: 0,
    })

    useEffect(() => {
        setPending(true)
        async function InvokedRendered() {
            const getAllAllowance = await getRequest(URL_EndPoints()?.getAllAllowance, setPending)
            setAllowanceRecords(getAllAllowance?.data?.data)

            let newQueryParams = { ...QueryParams("getCleanerAllowance", "/") }
            newQueryParams.What += row?.id
            const { data } = await getRequest(URL_EndPoints(newQueryParams)?.getCleanerAllowance, setPending)
            setData(data.data)
            setfilterData(data.data)
        }
        InvokedRendered()
    }, [row?.id])

    useEffect(() => {
        async function InvokedRendered() {
            setPending(true)
            let newQueryParams = { ...QueryParams("getCleanerAllowance", "/") }
            newQueryParams.What += row?.id
            const { data } = await getRequest(URL_EndPoints(newQueryParams)?.getCleanerAllowance, setPending)
            setData(data.data)
            setfilterData(data.data)
        }
        loader == false && InvokedRendered()
    }, [loader])

    useEffect(() => {
        const updatedData = AllFielSearch(data, search)
        setfilterData(updatedData)
    }, [search])

    const handleSubmitAssign = async () => {
        setLoader(true)

        const { data } = await postRequest(URL_EndPoints()?.assignAllowanceToCleaner, payload, setLoader)
        if (data?.status == 200) {
            toast.success(data?.msg, { position: "top-center" })
        }
        else {
            toast.error(data?.msg, { position: "top-center" })
        }
    }



    return <>

        <Modal show={show} onHide={() => setShow(false)} size="xl" >
            <Modal.Header closeButton>
                <div className='w-100 d-flex justify-content-between '>
                    <div className='d-flex align-items-start justify-content-start '>
                        <input
                            type="text"
                            placeholder='Search Here'
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            className='w-100 form-control  align-start' />
                    </div>
                    <div className='d-flex align-items-center justify-content-end me-10 '>
                        <span className="fs-4 fw-bold">Assign / Change Slab</span>
                    </div>
                </div>

            </Modal.Header>
            <Modal.Body>

                {

                    <DataTable
                        title=""
                        columns={AllowanceColumn(setPayload, payload, setEditLoader, row)} 
                        data={filterData}
                        pagination  
                        progressPending={pending}
                        fixedHeader 
                        fixedHeaderScrollHeight='auto'   
                        selectableRowsHighlight 
                        highlightOnHover 
                        subHeader 
                        subHeaderComponent={<>
                            {
                                <div className="col-12 ">

                                    {editLoader ? <IsLoading /> :
                                        <RowModal classes="d-flex flex-wrap justify-content-between ">

                                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                                <GroupModal classes="" >
                                                    <SelectModal
                                                        classes="mb-2"
                                                        title="Select Allowance"
                                                        name="allowance_id"
                                                        keyName="name"
                                                        id="id"
                                                        isData={allowanceRecord}
                                                        payload={payload}
                                                        setPayload={setPayload}
                                                        defValue={payload?.allowance_id}
                                                    />
                                                </GroupModal>
                                            </ColModal>


                                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                                <GroupModal classes="" >
                                                    <SelectModal
                                                        classes="mb-2"
                                                        title="Select Type"
                                                        name="type"
                                                        keyName="name"
                                                        id="id"
                                                        isData={[{ id: "cash", name: "cash" }, { id: "points", name: "points" }]}
                                                        payload={payload}
                                                        setPayload={setPayload}
                                                        defValue={payload?.type}
                                                    />
                                                </GroupModal>
                                            </ColModal>


                                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                                <GroupModal classes="" >
                                                    <input
                                                        placeholder='Amount'
                                                        name='amount'
                                                        type='text'
                                                        value={payload?.amount}
                                                        className='form-control  mb-3 mb-lg-0'
                                                        autoComplete='off'
                                                        onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                                    />
                                                </GroupModal>
                                            </ColModal>

                                            <ColModal classes="mb-4 col-12 col-md-2 col-sm-2 col-lg-1">
                                                <GroupModal classes=" mt-5" >
                                                    <CheckboxModal
                                                        classes=""
                                                        name="status"
                                                        payload={payload}
                                                        setPayload={setPayload}
                                                        defValue={payload?.status}
                                                    />
                                                </GroupModal>
                                            </ColModal>


                                            <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-2">
                                                <GroupModal classes="" >
                                                    <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6`} onClick={handleSubmitAssign}>

                                                        {loader ?
                                                            <>

                                                                <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                                <span>Assigning...</span>

                                                            </>

                                                            : " Assgin / Change"}

                                                    </button>
                                                </GroupModal>
                                            </ColModal>


                                        </RowModal>}
                                </div>
                            }
                        </>}
                    />
                }
            </Modal.Body>
        </Modal>
    </>

}








