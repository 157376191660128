import React, { useEffect, useReducer, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import NewChampAssignmentList from '../newChampAssignmentList/NewChampAssignmentList'
import NewChampAvailabilityList from '../newChampAssignmentList/NewChampAvailabilityList'


const NewChampAssignmentsModel = ({ row, show, setShow, champId }: any) => {

    return (
        <>
            <Modal
                size='xl'
                show={show}
                onHide={() => setShow(false)}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>New Champ Assignment List</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-2 py-0 border'>
                        {/* <NewChampAssignmentList /> */}
                        <NewChampAvailabilityList champId={row?.id} />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default NewChampAssignmentsModel