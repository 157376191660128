import { useState } from "react"
import { Modal } from "react-bootstrap"
import { postRequest } from "../../../consts/Server/Requests"
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints"
import { adminToastRunner } from "../../admins/adminUtils"

export const FacebookInsightForm = ({show, setShow}: {show: boolean, setShow: (show: boolean) => void}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState<any>({
        file: null
    })

    const handleAdd = async (e: any) => {
        e.preventDefault()
        try {
            console.log(payload)
            setIsLoading(true)
            const formData = new FormData()
            formData.append('file', payload.file)
            const response = await postRequest(URL_EndPoints(null)?.createFacebookAdInsight, formData, setIsLoading, null)
            adminToastRunner(response)
            if(response.data?.status === 200) {
                setPayload((payload: any) => ({
                    ...payload,
                    file: null
                }))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Facebook Insight</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleAdd}>
                    <div className="mb-3">
                        <label className="form-label">Facebook Insight File</label>
                        <input
                            type="file"
                            className="form-control"
                            accept=".xlsx"
                            onChange={(e) => setPayload({file: e.target.files?.[0]})}
                            required
                            multiple={false}
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" type="submit" disabled={isLoading}>{isLoading ? 'Adding...' : 'Add'}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
