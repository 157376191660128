import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { postRequest } from '../Server/Requests'
import { URL_EndPoints } from '../Server/URL_EndPoints'
import { toast } from 'react-toastify'
import IsLoading from '../IsLoading/IsLoading'

const ChampBulkWhatsappNotificationSender = ({ showNotifyPopUp, handleNotifyPopUpClose, filteredSelectedRows, templateType, key, setFilteredSelectedRows }: any) => {
    const [isBulkNotifyLoading, setisBulkNotifyLoading] = useState(false)
    const [whatsappTemplates, setWhatsappTemplates] = useState<any>([])
    const [loader, setloader] = useState<any>(false)
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
    const [payload, setPayload] = useState<any>({
        templateName: ""
    })

    async function loadData() {
        setloader(true)
        const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
            wa_number_id: templateType
        }, setloader);
        setWhatsappTemplates(whatsappTemplates?.data?.data);
    }
    React.useEffect(() => {
        loadData()
    }, [])


    const handleBulkWhatsappNotifySubmit = async (e: any) => {
        e.preventDefault();
        let ids = filteredSelectedRows?.map((row: any) => row?.id);
        payload["champIds"] = ids || [];
        setisBulkNotifyLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.sendBulkWhatsAppTemplateToChamp, payload, setisBulkNotifyLoading)
        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            setFilteredSelectedRows && setFilteredSelectedRows([])
            handleNotifyPopUpClose()
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            handleNotifyPopUpClose(false)
        }
        setisBulkNotifyLoading(false)

    }


    const handleTemplateChange = (event: any) => {
        if(event?.target?.name !== "templateName"){
            setPayload({
                ...payload,
                [event?.target?.name]: event?.target?.value
            })
            return
        }
        const selectedTemplate: any = whatsappTemplates?.find((template: any) => template?.name == event?.target?.value)
        const params: any = (selectedTemplate?.params ? JSON.parse(selectedTemplate?.params) : [])?.map((param: any) => param)
        const object = {
            ...payload, 
            "templateName": event?.target?.value,
        }
        if(selectedTemplate?.is_params_required > 0){
            params?.forEach((param: any) => {
                object[`params_$${param}`] = ""
            })
        }
        setSelectedTemplate(selectedTemplate)
        setPayload(object)
    }

    return (
        <div>
            {/* size='sm'  */}
            <Modal dialogClassName="modal-small" show={showNotifyPopUp} onHide={handleNotifyPopUpClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Send WhatsApp Notification to {filteredSelectedRows?.length} Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleBulkWhatsappNotifySubmit}>
                        <Form.Group className="mb-3" >
                            {
                                loader ? <IsLoading /> :
                                    <Form.Select aria-label="Default select example"  value={payload?.templateName} required name='templateName' onChange={handleTemplateChange} className='w-100 form-control me-2 align-start mb-2'>
                                        <option value="">Select Template</option>
                                        {whatsappTemplates?.map((template: any) => <option value={template.name}>{template.name}</option>)}
                                    </Form.Select>
                            }

                            {selectedTemplate?.is_params_required > 0 && <div>
                                {(selectedTemplate?.params ? JSON.parse(selectedTemplate?.params) : []) ?.map((param: any) => 
                                  <input
                                  type='text'
                                  style={{ height: '38px' }}
                                  value={payload[`params_$${param}`]}
                                  name={`params_$${param}`}
                                  placeholder={`Enter ${param}`}
                                  onChange={handleTemplateChange}
                                  className='w-100 form-control me-2 align-start mb-2'
                                />
                                )}
                            </div>}
                        </Form.Group>
                        <Form.Group className="mb-3 p-2" >
                            {
                                isBulkNotifyLoading ?
                                    <Spinner animation={'border'} />
                                    : <Button type='submit' variant="primary">
                                        Send Now
                                    </Button>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ChampBulkWhatsappNotificationSender




// {show ? <BulkWhatsappNotificationSender
//     showNotifyPopUp={show}
//     handleNotifyPopUpClose={handleNotifyPopUpClose}
//     row={row}
// /> : ''}