import GenerateHiringPointsPayout from "./GenerateHiringPointsPayout";
import InititateTransferHiringPoints from "./InititateTransferHiringPoints";

export const HiringPayoutListsColumn = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id ?? "NA",
        sortField: 'id',
        sortable: true,
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "NAME",
        cell: (row: any) => row?.hiringPoint?.name ?? "NA",
        // cell: (row: any) => <OpenChat title={row?.name} champId={row?.recruiter?.id} trackData={champTrackData} />,
        sortField: 'hiringPoint.name',
        sortable: true,
        width: "200px"
    },
    {
        id: 3,
        reorder: true,
        name: "START DATE",
        cell: (row: any) => row?.start_date ?? "",
        sortField: 'start_date',
        sortable: true,
        width: "150px"
    },

    {
        id: 4,
        reorder: true,
        name: "END DATE",
        cell: (row: any) => row?.end_date ?? "NA",
        sortField: 'end_date',
        sortable: true,
        width: "150px"
    },

    {
        id: 5,
        reorder: true,
        name: "TOTAL LEADS",
        cell: (row: any) => row?.total_leads ?? "NA",
        sortField: 'total_leads',
        sortable: true,
        width: "100px"
    },

    {
        id: 6,
        reorder: true,
        name: "CONVERTED LEADS",
        cell: (row: any) => row?.converted_leads,
        sortField: 'converted_leads',
        sortable: true,
        width: "100px"
    },

    {
        id: 7,
        reorder: true,
        name: "AMOUNT",
        cell: (row: any) => row?.earnable_amount,
        sortField: 'earnable_amount',
        sortable: true,
        width: "120px"
    },
    {
        id: 8,
        reorder: true,
        name: "P.L.AMOUNT",
        cell: (row: any) => row?.per_lead_amount,
        sortField: 'per_lead_amount',
        sortable: true,
        width: "120px"
    },
    {
        id: 9,
        reorder: true,
        name: "P.C.AMOUNT",
        cell: (row: any) => row?.per_conversion_amount,
        sortField: 'per_conversion_amount',
        sortable: true,
        width: "120px"
    },
    {
        id: 10,
        reorder: true,
        name: "PREV STATUS",
        cell: (row: any) => row?.payout_status,
        sortField: 'payout_status',
        sortable: true,
        width: "150px"
    },
    {
        id: 11,
        reorder: true,
        name: "PHONE",
        cell: (row: any) => row?.hiringPoint?.recruiter?.phone ?? "NA",
        sortField: 'hiringPoint.recruiter.phone',
        sortable: true,
        width: "200px"
    },
    {
        id: 18,
        reorder: true,
        name: 'Action',
        selector: (row: any) => <InititateTransferHiringPoints row={row} />,
        width: '200px',
    },
    // {
    //     id: 18,
    //     reorder: true,
    //     name: 'Action',
    //     selector:  (row: any) => <GenerateHiringPointsPayout row={row} />,
    //     width: '200px',
    // }



]

