
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LocalBaseURL } from '../../../../../BaseURLmanagement'
import { getRequest, postRequest, postRequestWithFile } from '../../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UpdateAdminUser = ({ row }: any) => {
    LocalBaseURL()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [show, setShow] = useState(false)

    const [payload, setPayload] = useState<any>({
        id: row?.id,
        first_name: row?.first_name,
        last_name: row?.last_name,
        // password: row?.password,
        call_active_status: row?.status,
        customer_cap: row?.customer_cap,
        champ_cap: row?.champ_cap,
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        const response = await postRequestWithFile(URL_EndPoints()?.updateAdminData, payload, setLoading)
        if (response?.data?.status == 200) {
            toast.success(response?.data?.msg, { position: "top-center" })
            setShow(false)
        }
        else {
            toast.error(response?.data?.msg, { position: "top-center" })
            setShow(false)
        }
    }

    return (
        <>

            <span>
                <FontAwesomeIcon icon={faEdit} className='fs-3 ms-2 text-muted me-4 ' onClick={() => setShow(true)} />
            </span>

            <Modal size="lg" show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Update Admin User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>

                            <Form autoComplete={'off'} onSubmit={handleSubmit}>


                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>First Name </Form.Label>
                                    <Form.Control type="text" placeholder="Enter First Name" name="first_name" value={payload?.fname || payload?.first_name} onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Last Name </Form.Label>
                                    <Form.Control type="text" placeholder="Enter Last Name" name="last_name" value={payload?.lname || payload?.last_name} onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} required />
                                </Form.Group>


                                {/* <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Password </Form.Label>
                                    <Form.Control type="password" placeholder="Enter Password" name="password" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.password} required />
                                </Form.Group> */}



                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Customer Cap </Form.Label>
                                    <Form.Control type="number" placeholder="Enter Customer Cap Count" name="customer_cap" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.customer_cap} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Champ Cap </Form.Label>
                                    <Form.Control type="number" placeholder="Enter Champ Cap Count" name="champ_cap" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.champ_cap} required />
                                </Form.Group>




                                <Form.Group className="mb-3" controlId="call_active_status">
                                    <Form.Check type="checkbox" label="call_active_status" name="call_active_status" onChange={(event) => setPayload((prev: any) => ({ ...prev, [event?.target.name]: prev?.call_active_status == 1 ? 0 : 1 }))} checked={payload?.call_active_status == 1 ? true : false} />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Update User Detail
                                </Button>
                            </Form>
                        </> : <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                </Modal.Body>
            </Modal>



        </>

    )
}

export default UpdateAdminUser



