import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import ToolTips from '../../consts/Tooltip/ToolTips';
import SentenceShorter from '../../consts/SentenceShorter';
import { postRequest, postRequestWithFile } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { faBan, faEdit, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChipsInfos from '../../consts/Chips/ChipsInfos';
import TicketOwnerForm from './TicketOwnerUpdate/TicketOwnerForm';
import Calling from '../../consts/Call/Calling';
import Layout from '../../consts/Call/Layout';
import { DateFormate } from '../../consts/toggleColumns/DateFormate';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import WhatsppMessageSenderIndividual from '../../consts/components/WhatsppMessageSenderIndividual';
import { TempsDateFormate } from '../../consts/toggleColumns/TempsDateFormate';
import ChampCallDetailsLogs from '../../consts/champChatDashboard/components/champCallDetails/ChampCallDetailsLogs';
import { TempsDateFormateTasklist } from '../../consts/toggleColumns/TempsDateFormateTasklist';
import TagsShow from '../../consts/TagsShow/TagsShow';
import UpdateTicketTag from './TagsUpdate/UpdateTicketTag';
import TicketStatus from '../../consts/reuseModules/TicketStatus';
import AdminChampLeadStatusActionForm from '../admins/adminChampDashboardForms/AdminChampLeadStatusActionForm';
import AdminUpdatePrivateTagsListChampLead from '../admins/adminChampDashboardForms/AdminUpdatePrivateTagsListChampLead';
import AdminChampActionForm from '../admins/adminChampDashboardForms/AdminChampActionForm';
import { champDashboardSubAreaActionFormData } from '../../consts/champChatDashboard/utils/DefaultPrivatePublic';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';
import AdminDueDateForm from '../admins/adminComponents/AdminDueDateForm';
import moment from 'moment';
import MaskedPhoneNumber from '../../consts/MaskedPhoneNumber';
const userid = JSON.parse(localStorage.getItem("user") || "0")

export const columns = (handleFetchAllCallLogs?: any, data?: any, totalRowDataForChampDashboard?: any) => [

    {
        id: 1,
        reorder: true,
        name: "Name",
        cell: (row: any) => <div className='d-flex g-4 flex-wrap'>
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-add-note"]}>
                <NotesHandler row={row} />
            </PermissionContextProvider>
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-whatsapp-notification-send-single"]}>
                <WhatsappMessageSender row={row} />
            </PermissionContextProvider>
            <Calling title={row?.first_name || row?.last_name ? row?.first_name + " " + row?.last_name : "NA"} row_id={row?.id} row={row} type="cleanerid" From={Layout} isOpen={true} source="champ_leads" trackData={data} totalRowDataForChampDashboard={totalRowDataForChampDashboard} />
        </div>,

        sortField: 'first_name',
        sortable: true,
        width: "250px"
    },
    {
        id: 30,
        reorder: true,
        name: "Phone",
        cell: (row: any) => <MaskedPhoneNumber phoneNumber={row?.phone} />,
        sortField: 'phone',
        sortable: true,
        width: "250px"
    },
    {
        name: <CallDetailColumn handleFetchAllCallLogs={handleFetchAllCallLogs} />,
        cell: (row: any) => <CallDtails row={row} />,
        minWidth: "250px",
        id: 27,
        reorder: true,
        hideCols: "champ-leads-fetch-call-detail"
    },
    {
        id: 2,
        reorder: true,
        name: "Created",
        cell: (row: any) => TempsDateFormate(row?.createdAt, true),
        sortField: 'createdAt',
        sortable: true,
    },
    {
        id: 2,
        reorder: true,
        name: "L.S Updated",
        cell: (row: any) => moment(row?.last_status_updated_ts).format("DD-MM-YYYY HH:mm"),
        sortField: 'last_status_updated_ts',
        sortable: true,
    },
    {
        id: 3,
        reorder: true,
        name: "Due Date",
        selector: (row: any) => <div className=''>
            <VisitorDueDateUpdate row={row}></VisitorDueDateUpdate>
        </div>,
        sortField: 'cleanerTicketsSingle.last_date_resolution',
        sortable: true,
        width: "200px"
    },
    {
        id: 4,
        reorder: true,
        name: "Source",
        selector: (row: any) => {
            const sourceUpdate: any = <div className='d-flex flex-row'>
                <VisitorSourceUpdate row={row}></VisitorSourceUpdate>
            </div>
            return sourceUpdate;
        },
        sortField: 'cleanerSource.name',
        sortable: true,
        minWidth: '150px'
    },
    {
        id: 5,
        reorder: true,
        name: "Status",
        selector: (row: any) => {
            const statusUpdate: any = <div className='d-flex flex-row'>
                <VisitorStatusUpdate row={row}></VisitorStatusUpdate>
            </div>
            return statusUpdate;
        },
        sortField: 'status_id',
        sortable: true
    },

    {
        id: 6,
        reorder: true,//    
        name: "S.L Status",
        selector: (row: any) => row?.champSubStatus?.name || "Not Set",
        sortField: 'champSubStatus.name',
        sortable: true
    },

    {
        id: 24,
        reorder: true,
        name: "Private Tag",
        selector: (row: any) => <UpdatePrivateTags row={row} />,
        sortField: 'private_tag',
        sortable: true
    },

    {
        id: 26,
        reorder: true,
        name: "Ticket Tag",
        selector: (row: any) => <UpdateTicketListTag row={row} />,
        sortField: 'cleanerTicketsSingle.tags',
        sortable: true
    },

    {
        id: 7,
        reorder: true,
        name: "Vehicle",
        cell: (row: any) => row?.vehicle_type,
        sortField: 'vehicle_type',
        width: "200px"
    },
    {
        id: 8,
        reorder: true,
        name: "Completed Calls",
        cell: (row: any) => row?.call_complete_count,
        width: "200px",
        sortField: 'call_complete_count',

    },
    {
        id: 9,
        reorder: true,
        name: "Total Calls",
        cell: (row: any) => row?.total_call_count,
        width: "200px",
        sortField: 'total_call_count',

    },
    {
        id: 10,
        reorder: true,
        name: "Trainer",
        cell: (row: any) => row?.trainer?.first_name || "No Trainer",
        sortField: 'trainer.first_name',

    },
    {
        id: 11,
        reorder: true,
        name: "Training Location",
        cell: (row: any) => row?.cleanerTrainingLocation?.locationname || "Not Set",
        sortField: 'cleanerTrainingLocation.locationname',
        sortable: true
    },
    {
        id: 12,
        reorder: true,
        name: "Area Name",
        cell: (row: any) => {
            return row?.cleanerAreas?.map((ele: any) => ele?.areaDetail?.areaname + ", ")
        },
        width: "200px",
        sortField: 'address',
        sortable: true
    },
    {
        id: 13,
        reorder: true,
        name: "Free Form Location",
        cell: (row: any) => row?.address || "Not Set",
        width: "200px",
        sortField: 'address',
        sortable: true
    },
    {
        id: 14,
        reorder: true,
        name: "Sub Area Id",
        cell: (row: any) => <SubAreaAssign row={row} />,
        width: "100px",
        sortField: 'cleaner_sub_area_details.name',
        sortable: true
    },
    {
        id: 30,
        reorder: true,
        name: "Latest Activity",
        cell: (row: any) => row?.user_latest_event_name,
        sortField: 'user_latest_event_name',
        sortable: true
    },
    {
        id: 15,
        reorder: true,
        name: "Ticket Status",
        selector: (row: any) => {
            return <TicketStatus ticketStatus={row?.cleanerTicketsSingle?.ticketstatus?.name} ticketStatusId={row?.cleanerTicketsSingle?.ticketstatus?.id} />
        },
        sortField: 'cleanerTicketsSingle.ticketstatus.name',
        sortable: true
    },
    {
        id: 16,
        reorder: true,
        name: "Activation Date",
        cell: (row: any) => TempsDateFormate(row?.champHistory?.date, true) === "Invalid date" ? "" : TempsDateFormateTasklist(row?.champHistory?.date, true),
        sortField: "champHistory.date",
        sortable: true,
    },
    {
        id: 17,
        reorder: true,
        name: "App Version",
        selector: (row: any) => row?.appversion || "Not downloaded",
        sortField: "appversion",
        sortable: true,
        minWidth: "150px"
    },

    {
        id: 18,
        reorder: true,
        name: "Ticket Owner",
        cell: (row: any) => <TicketOwnerUpdate row={row} />,
        sortField: "cleanerTicketsSingle.owner_user_id",
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 19,
        reorder: true,
        name: "Ticket Assignee",
        cell: (row: any) => {
            if (!row?.cleanerTicketsSingle?.ticketAssignsSingle) {
                return "Not Assigned";
            }
            const data = <ChipsInfos SelectedString={row?.cleanerTicketsSingle?.ticketAssignsSingle?.userDetail?.first_name || 'Unkown name'} classes="primary" />
            return data;
        },
        sortField: "cleanerTicketsSingle.ticketAssignsSingle.userDetail.first_name",
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 20,
        reorder: true,
        name: "Ticket Updated At",
        cell: (row: any) => TempsDateFormate(row?.cleanerTicketsSingle?.ticket_updated_at, true),
        sortField: "cleanerTicketsSingle.ticket_updated_at",
        sortable: true,
        minWidth: "200px",
    },
    {
        id: 21,
        reorder: true,
        name: "Batch",
        cell: (row: any) => row?.champBatch?.name ?? 'Not Set',
        sortField: "champBatch.name",
        minWidth: "200px"
    },
    {
        id: 22,
        reorder: true,
        name: "WhatsApp Template Name",
        cell: (row: any) => row?.cleanerTicketsSingle?.last_sent_template_name || 'Not Set',
        sortField: "cleanerTicketsSingle.last_sent_template_name",
        minWidth: "200px"
    },
    {
        id: 23,
        reorder: true,
        name: "WhatsApp Template Time",
        cell: (row: any) => <DateFormate date={row?.cleanerTicketsSingle?.last_sent_template_ts} from="Visitor_WhatsApp_Template_Time" />,
        sortField: "cleanerTicketsSingle.last_sent_template_ts",
        minWidth: "200px"
    },
    {
        id: 32,
        reorder: true,
        name: 'Campaign Name',
        cell: (row: any) => row?.champSourceDetail?.campaignName ?? "Not Set",
        sortField: 'champSourceDetail.campaignName',
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 30,
        reorder: true,
        name: "Common Lead",
        cell: (row: any) => row?.isCommon ? "Yes" : "No",
        sortField: "isCommon",
    },
    {
        id: 31,
        reorder: true,
        name: "Common Lead Flag",
        cell: (row: any) => row?.isCommonCheck ? "Present" : "Removed",
        sortField: "isCommonCheck",
    },
];


const UpdateTicketListTag = ({ row }: any) => {

    const [updateTagModal, setUpdateTagModal] = useState<any>(false)
    const [rowData, setRowData] = useState<any>(row)

    useEffect(() => {
        setRowData(row)
    }, [row?.cleanerTicketsSingle?.tags])

    const handleClose = () => {
        setUpdateTagModal(false)
    }

    return (
        <>
            <span className='d-flex'>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-ticket-tag-update"]}>
                    <FontAwesomeIcon onClick={() => setUpdateTagModal(true)} icon={faEdit} />
                </PermissionContextProvider>

                &nbsp; {rowData?.cleanerTicketsSingle?.tags ? <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1 cursor-pointer'>
                    <TagsShow Tag={rowData?.cleanerTicketsSingle?.tags}></TagsShow>
                </div> : 'Not Set'}
            </span>

            {updateTagModal ? <UpdateTicketTag showModal={updateTagModal} handleClose={handleClose} row={row} setRowData={setRowData} /> : <></>}

        </>
    )
}

const UpdatePrivateTags = ({ row }: any) => {

    const [updateTagModal, setUpdateTagModal] = useState<any>(false)
    const [rowData, setRowData] = useState<any>(row)

    useEffect(() => {
        setRowData(row)
    }, [row?.private_tag])

    const handleClose = () => {
        setUpdateTagModal(false)
    }

    return (
        <>
            <span className='d-flex'>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-private-tag-update"]}>
                    <FontAwesomeIcon onClick={() => setUpdateTagModal(true)} icon={faEdit} />
                </PermissionContextProvider> &nbsp; {rowData?.private_tag ? <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1 cursor-pointer'>
                    <TagsShow Tag={rowData?.private_tag}></TagsShow>
                </div> : 'Not Set'}
            </span>
            {updateTagModal ? <AdminUpdatePrivateTagsListChampLead showModal={updateTagModal} handleClose={handleClose} row={row} setRowData={setRowData} /> : <></>}

        </>
    )
}


const TicketOwnerUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const adminList = useSelector((store: any) => store.VisitorDataReducer.adminList)
    const supervisorList = useSelector((store: any) => store.VisitorDataReducer.supervisorList)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        let updatedData: any;
        if (storeRowP2P?.cleanerTicketsSingle?.owner_user_type === 5) {
            updatedData = adminList?.filter((ele: any) => {
                return ele?.id === storeRowP2P?.cleanerTicketsSingle?.owner_user_id
            });
        } else {
            updatedData = supervisorList?.filter((ele: any) => {
                return ele?.id === storeRowP2P?.cleanerTicketsSingle?.owner_user_id
            });
        }
        updatedData && setshowRes(updatedData?.length > 0 ? updatedData[0] : {
            name: "No Set"
        });
    }, [adminList, supervisorList, storeRowP2P?.cleanerTicketsSingle?.owner_user_id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setupdateStatusPop(false)
    }

    return <>
        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-owner-update"]}>
                <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} />
            </PermissionContextProvider>   &nbsp;{showRes?.first_name || showRes?.name || 'Not Available'}
        </span>
        <TicketOwnerForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    owner_user_id: storeRowP2P?.cleanerTicketsSingle?.owner_user_id,
                    owner_user_type: storeRowP2P?.cleanerTicketsSingle?.owner_user_type,
                    ticket_id: row?.cleanerTicketsSingle?.id,
                    champId: row?.id,
                    tickets: row?.cleanerTicketsSingle,
                },
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></TicketOwnerForm>
    </>
}



const NotesHandler = ({ row }: any) => {
    const [show, setShow] = useState(false)
    const [loader, setloader] = useState(false)
    const [notes, setNotes] = useState<any>("")

    const notesAddHandler = async () => {
        setloader(true)
        const result = await postRequestWithFile(URL_EndPoints()?.notesOnChampTicketByAdmin, { "ticketid": row?.cleanerTicketsSingle ? row?.cleanerTicketsSingle?.id : 0, "userid": userid, "answer": notes }, setloader)
        setloader(false)
        toast?.[result?.data?.status == 200 ? "success" : "error"](result?.data?.msg, { position: "top-center" })
        setShow(false)
        setNotes("")
    }

    return <>

        <span className='cursor-pointer me-4 fs-5 text-primary'><FontAwesomeIcon onClick={() => setShow(true)} icon={faStickyNote} className='text-primary' /> </span>

        {show ? <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal"
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`col-12  mb-1`}>

                    <textarea
                        placeholder='Add notes...'
                        name='notes'
                        value={notes}
                        data-kt-element='input'
                        wrap='hard'
                        className='form-control   mb-3 mb-lg-0'
                        autoComplete='off'
                        onChange={(e) => setNotes(e.target.value)}
                    ></textarea>

                </div>

                <div className='d-flex  align-items-center justify-content-end '>
                    <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={loader ? true : false} onClick={() => notesAddHandler()}>
                        {
                            loader ?
                                <>
                                    <div className='spinner-border mr-15' role='status'></div>
                                    <h4 className=' text-white fs-6 ms-2 mt-2'>Adding.....</h4>
                                </>
                                : <h4 className=' btn-sm text-white fs-8 ms-2 mt-2'>Save Now</h4>
                        }
                    </button>
                </div>
            </Modal.Body>
        </Modal> : ''}

    </>


}

const WhatsappMessageSender = ({ row }: any) => {
    const [show, setShow] = useState(false)
    const handleNotifyPopUpClose = () => {
        setShow(false)
    }

    return <>

        <span className='cursor-pointer me-4 fs-5 text-primary'>
            {
                row?.isWhatsApp == 2 ?
                    <span className='cursor-pointer me-2 fs-7 text-danger' style={{ cursor: "not-allowed" }}><FontAwesomeIcon icon={faBan} className='text-danger' /> </span>
                    :
                    <i className={`cursor-pointer fab fa-whatsapp text-${row?.isWhatsApp == 3 ? "danger" : "success"}`} onClick={() => setShow(true)}></i>
            }

        </span>

        {show ? <WhatsppMessageSenderIndividual
            showNotifyPopUp={show}
            handleNotifyPopUpClose={handleNotifyPopUpClose}
            row={row}
        /> : ''}

    </>


}

const VisitorStatusUpdate = ({ row }: any) => {
    const dispatch = useDispatch()
    const visitorStatuses = useSelector((store: any) => store.VisitorDataReducer.visitorStatuses)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [showRes, setshowRes] = useState<any>({})

    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        const showResName = visitorStatuses?.find((ele: any) => {
            return ele?.id === storeRowP2P?.status_id
        });
        showResName && setshowRes(showResName);
    }, [visitorStatuses, storeRowP2P?.status_id])

    const handleClose = () => {
        dispatch({
            type: "MODAL_OPEN", payload: {
                toggle: false,
                id: null,
            }
        })
        setupdateStatusPop(false)
    }

    return <>


        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-status-update"]}>
                <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;
            </PermissionContextProvider>
            {showRes?.name || 'Not Set'}
        </span>
        {storeRowP2P && <AdminChampLeadStatusActionForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    status_id: row?.status_id,
                    champId: row?.id,
                    tickets: row?.cleanerTickets,
                },
            }}
            setStoreRow={setStoreRowP2p}
            row={row}
            prevInitialData={showRes?.name ? { label: showRes?.name, value: showRes?.id } : null}
        ></AdminChampLeadStatusActionForm>}

        {/* {storeRowP2P && <VisitorStatusForm
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    status_id: row?.status_id,
                    champId: row?.id,
                    tickets: row?.cleanerTickets,
                },
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
        ></VisitorStatusForm>} */}
    </>
}

const VisitorSourceUpdate = ({ row }: any) => {
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    return <>
        <span >
            {storeRowP2P?.cleanerSource?.name || 'Not Set'}
        </span>

    </>
}


const VisitorDueDateUpdate = ({ row }: any) => {
    const [showDueDatePopup, setShowDueDatePopup] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const setUpdatedData = (payload: any) => {
        row['cleanerTicketsSingle'] = {}
        row.cleanerTicketsSingle.last_date_resolution = payload?.["due_date"]
        setStoreRow(row)
    }

    return <>

        <span >
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-due-date-update"]}>
                <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} />
            </PermissionContextProvider>
            &nbsp;{storeRow?.cleanerTicketsSingle ?
                <DateFormate date={storeRow?.cleanerTicketsSingle?.last_date_resolution} from="Visitor_Due_Date" />
                : 'Not Set'}
        </span>

        {
            showDueDatePopup ? <AdminDueDateForm
                show={showDueDatePopup}
                setShow={setShowDueDatePopup}
                setUpdatedData={setUpdatedData}
                prevInitialPayload={{
                    show: showDueDatePopup,
                    ticket_history_id: 0,
                    ticket_id: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.id : 0,
                    customerid: storeRow?.id,
                    user_id: userid,
                    due_date: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : '',
                    prev_due_date: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : '',
                }}
                row={row}
                title="Due Date Update"
                apiUrl="updateDueDateVisitor"
                dueDateKeyName="due_date"
                isDateTime={false}
                isServerDueDate={false}
                serverApiUrl="xxxxx"
            /> : <></>

        }


        {/* {
            <AdminDueDateFormChampLead FilteredData={{
                show: showDueDatePopup,
                ticket_history_id: 0,
                ticket_id: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.id : 0,
                customerid: storeRow?.id,
                user_id: userid,
                prev_due_date: storeRow?.cleanerTicketsSingle ? storeRow?.cleanerTicketsSingle?.last_date_resolution : '',
            }} handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} />
        } */}
    </>
}

const CallDetailColumn = ({ handleFetchAllCallLogs }: any) => {
    return <>
        Call Details
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-fetch-call-detail"]}>
            <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchAllCallLogs} style={{
                fontSize: '10px',
                padding: '5px 7px',
                marginLeft: '7px'
            }}>Fetch All</button>
        </PermissionContextProvider>



    </>
}

const CallDtails = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row]);

    return <>
        <div className='card-title'>
            <div className='symbol-group symbol-hover'>
                {storedRow?.callLogs?.map((ele: any, indexs: any) => {
                    let id = uuid()
                    let StartTime = ele?.FormattedStartTime?.split(" ")
                    let sentence = ele?.FromDetails?.name + ' - ' + ((StartTime[0] + " " + StartTime[1]))
                    if (indexs < 5) {
                        return <div className='symbol symbol-40px symbol-circle' id={id} key={id}  >
                            <span className={`symbol-label bg-light-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : ele?.Status === "no-answer" ? "secondary" : ele?.Status === "canceled" ? "danger" : "primary"} text-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : "primary"} 40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                            <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                        </div>
                    }
                })}

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-fetch-call-detail"]}>
                    <CallLogsDetails customerid={storedRow?.id} customer_name={storedRow?.first_name + ' ' + storedRow?.last_name} lengths={storedRow?.callLogs?.length} row={storedRow} setStoredRow={setStoredRow} ></CallLogsDetails>
                </PermissionContextProvider>



            </div>
        </div>
    </>
}

const CallLogsDetails = ({ customerid, customer_name, lengths, row, setStoredRow }: any) => {
    const [show, setshow] = useState(false)
    const [Cusomerid, setCusomerid] = useState(0)
    const handleFetchCallDetails = async (customerid: number) => {
        if (lengths === undefined) {
            const response: any = await postRequest(URL_EndPoints(null)?.getCallHistoryByCustomerIds, {
                customerIds: [row?.id],
                userType: 'cleaner'
            }, null);
            const callLogs: any = response?.data?.data[0];
            setStoredRow({ ...row, callLogs });
            setCusomerid(customerid)
        } else {
            setshow(true)
            setCusomerid(customerid)
        }
    }
    const handleClose = () => {
        setshow(false)
    }
    return (
        <>
            <a
                href='#'
                className='symbol symbol-40px symbol-circle'
                id={customerid}
                onClick={(e: any) => {
                    e.preventDefault();
                    handleFetchCallDetails(customerid);
                }}
            >
                <span
                    className='symbol-label fs-8 fw-bolder bg-primary text-white'
                >
                    {lengths === undefined ? 'Fetch' : lengths}
                </span>
                <ToolTips toolTipId={customerid} ToolTipShowData={lengths === undefined ? "View call logs" : "View All Call Details"} place={""} />
            </a>

            <ChampCallDetailsLogs FilteredData={{
                champ_call_log_id: Cusomerid,
                show: show,
                handleClose,
                champ_name: customer_name
            }} >
            </ChampCallDetailsLogs>


        </>
    )
}



const SubAreaAssign = ({ row }: any) => {

    const [show, setShow] = useState(false)
    const [rowData, setRowData] = useState<any>(row)
    useEffect(() => { setRowData(row) }, [row])


    const setUpdatedSubAreaName = (newSubArea: any) => {
        if (newSubArea?.isSet) {
            row.cleaner_sub_area_details = row.cleaner_sub_area_details?.name ? { ...row.cleaner_sub_area_details } : {}
            row.cleaner_sub_area_details.name = newSubArea?.payload?.sub_area_id_item?.item?.name
            setRowData(row)
        }
        // 👉 keyName+"_"+"item" == for subArea update ==> sub_area_id_item?.item?.name
    }
    const subAreaPrevData4subAreaForm = row?.cleaner_sub_area_details ? { label: row?.cleaner_sub_area_details?.name, value: row?.cleaner_sub_area_details?.id } : null
    return (
        <>
            <span onClick={() => setShow(true)}>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["champ-leads-sub-area-name-update"]}>
                    <FontAwesomeIcon icon={faEdit} className='fs-4 me-2 cursor-pointer' />
                </PermissionContextProvider>
                {rowData?.cleaner_sub_area_details?.name}
            </span>

            {
                show ? <AdminChampActionForm
                    show={show}
                    setShow={setShow}
                    setStoreRow={setUpdatedSubAreaName}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={champDashboardSubAreaActionFormData}
                    staticFormData={[]}
                    initialData={{
                        champId: [row?.id],
                        sub_area_id: subAreaPrevData4subAreaForm?.value ? subAreaPrevData4subAreaForm?.value : null,
                    }}
                    prevInitialData={{ item: subAreaPrevData4subAreaForm }}
                    keyName="subAreaAddOrUpdateForChamp1"
                    formTitle={`Update Sub Area`}
                    formSubmitApiUrl="addCleanerAddressInBulk"
                /> : <></>
            }
        </>
    )
}