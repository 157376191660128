import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withSwal as SweetAlertConfirm } from 'react-sweetalert2';
import { toast } from 'react-toastify';
export default SweetAlertConfirm((props: any) => {
    const { swal, Render, payloads, localKey, Apis, TestApis, ApiEndPoints } = props;
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (Render) {
            swal.fire({
                html: `<h3 >Do you want call this ${"cleanerid" in payloads ? "Cleaner" : "Customer"} ?</h3>`,
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No",
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.post(`${localKey ? Apis : TestApis}/admin${ApiEndPoints}`, payloads).then((res) => {
                        dispatch({ type: "CallingAlert", payload: Math.random() })
                        setTimeout(() => {
                            if (res?.data?.msg === "Call Not Initiated.") {
                                toast.error(res?.data?.msg)
                                dispatch({ type: "CallingAlert", payload: null })
                            }
                            else {
                                toast.success("Call Successfully Connected")
                                dispatch({ type: "CallingAlert", payload: null })
                            }
                        }, 5000);
                    }).catch((error) => {
                        dispatch({ type: "CallingAlert", payload: null })

                        swal.fire('', 'Something went wrong', '');

                    })
                } else if (result.isDenied) {
                    dispatch({ type: "CallingAlert", payload: null })

                    swal.fire('', 'You Cancelled the Call', '');
                }
            })
        }
    }, [Render])
});
