import { Form, Modal, Tabs, Tab, Col } from "react-bootstrap"
import { useState } from "react"
import VisitorListChampV2 from "../VisitorListChamp/VisitorListChampV2"
import ActiveCleanerRoute from "../ActiveCleaner/ActiveCleanerRoute"
import { postRequest } from "../../consts/Server/Requests"
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints"
import { adminToastRunner } from "../admins/adminUtils"
import AdmInMainRoutes from "../MASTERS/AdminUsers/AdmInMainRoutes"
const listList = [
    {
        name: "Champ Leads",
        value: "champ_leads"
    },
    {
        name: "Active Champs",
        value: "active_champs"
    },
    {
        name: "Admin Users",
        value: "admin_users"
    },

]

const AddMembers = ({ show, setShow, groupId, getChatMessages }: any) => {
    const listObject: any = {
        "champ_leads": VisitorListChampV2,
        "active_champs": ActiveCleanerRoute,
        "admin_users": AdmInMainRoutes,
    }

    const [selectedFilterId, setSelectedFilterId] = useState<any>({
        "champ_leads": [],
        "active_champs": [],
        "admin_users": [],
    })

    const [selectedListType, setSelectedListType] = useState<any>(null)
    const [from, setFrom] = useState<any>(null)

    const handleInputChange = (event: any) => {
        setFrom(null)
        setSelectedFilterId({
            "champ_leads": [],
            "active_champs": [],
            "admin_users": [],
        })
        setSelectedListType(event?.target?.value)
    }

    const selectedFilteredFromList = (filteredSelectedRows: any, from: any) => {
        setFrom(from)
        setSelectedFilterId({ [from]: filteredSelectedRows?.map((row: any) => ({ userId: row?.id, userType: row?.userType })) })
    }

    const Component = listObject[selectedListType]
    const [apiPending, setApiPending] = useState(false)
    const onSubmit = async (ids: any) => {
        setApiPending(true)
        const response = await postRequest(URL_EndPoints()?.addGroupMembers, {
            groupId: groupId,
            members: ids,
        }, setApiPending)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            getChatMessages(1, true)
            setShow(false)
            setSelectedFilterId({
                "champ_leads": [],
                "active_champs": [],
                "admin_users": [],
            })
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="small-modal"
            centered
            key={"AddMembers"}
            fullscreen
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Members</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} className="mb-3 col-12 d-flex gap-3 align-items-center" controlId="list_id">
                    <div>
                        <Form.Label className="d-flex justify-content-between align-items-center col-12"><strong>Select List Type</strong> </Form.Label>
                        <Form.Control
                            as="select"
                            name="list_id"
                            onChange={handleInputChange}
                            style={{ width: "300px" }}
                        >
                            <option value="">Select List</option>
                            {listList?.map((item: any) => (
                                <option key={item.value} value={item.value}>
                                    {item?.name}
                                </option>
                            ))}
                        </Form.Control>
                    </div>
                    {from && <div className="ms-5 col-2 d-flex flex-column mt-5">
                        <span>{from && from?.split("_")?.join(" ")?.toUpperCase()} ({selectedFilterId?.[from]?.length})</span>
                        <button className="btn btn-sm btn-primary" onClick={() => onSubmit(selectedFilterId[from])} disabled={apiPending}>{apiPending ? "Adding..." : "Add New Members"}</button>
                    </div>}
                </Form.Group>

                {
                    Component && < Component setFilteredIdForChat={selectedFilteredFromList} apiPending={apiPending} />
                }
            </Modal.Body>
        </Modal >
    )
}

export default AddMembers