import React from 'react';
import moment from 'moment';
import UpdateAdminUser from './AddNewAdminForm/UpdateAdminUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import AdminYesNoAlertAction from '../../admins/adminComponents/AdminYesNoAlertAction';
import AdminMaxDeviceChange from '../../admins/adminComponents/AdminMaxDeviceChange';
import MaskedPhoneNumber from '../../../consts/MaskedPhoneNumber';
import { isAllowedCopyNumber } from '../../../consts/components/Utility';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

function copyToClipboards(value: any) {
    if (isAllowedCopyNumber()) {
        copy(value);
        toast.success(`Copied to clipboard - ( ${value} ) `, { position: "top-center" })
      }
}
export const columns: any = () => [
   
    {
        id: 1,
        reorder: true,
        name: 'Actions',
        cell: (row: any) => (
            <Actions row={row} />
        ),
        minWidth: "200px"
    },
    {
        id: 0,
        reorder: true,
        name: 'ID',
        selector: (row: any) => (row?.id),
        sortable: true,
        minWidth: "70px"
        
    },

    {
        id: 2,
        reorder: true,
        name: 'NAME',
        cell: (row: any) => {
            const fullName = row.first_name + ' ' + row.last_name;
            return (
                <span
                    style={{ cursor: "pointer"}}
                    onClick={() => copyToClipboards(`${fullName}, ${row?.id}`)}
                >
                    {fullName}
                </span>
            );
        },
        sortable: true,
        minWidth: "150px",
    },

    {
        id: 9,
        reorder: true,
        name: 'STATUS',
        cell: (row: any) => <Status row={row} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.status
            const b = rowB?.status
            if (a > b) {
                return 1
            }
            if (b > a) {
                return -1
            }
            return 0
        },
    },
    {
        id: 12,
        reorder: true,
        name: 'AVAILABILITY',
        cell: (row: any) => <IsAvailable row={row} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.is_unavailable
            const b = rowB?.is_unavailable
            if (a > b) {
                return 1
            }
            if (b > a) {
                return -1
            }
            return 0
        },
        minWidth: "130px"
    },

    {
        id: 15,
        reorder: true,
        name: 'MAX DEVICE COUNT',
        cell: (row: any) => <MaxDeviceCountChange row={row} />,
        sortable: true,
        minWidth: "150px",
    },

    {
        id: 10,
        reorder: true,
        name: 'CALL STATUS',
        selector: (row: any) => row?.call_active_status == 1 ? <span className="badge badge-success">ON</span> : <span className="badge badge-danger">OFF</span>,
        sortable: true,
        minWidth: "150px",
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.call_active_status
            const b = rowB?.call_active_status
            if (a > b) {
                return 1
            }
            if (b > a) {
                return -1
            }
            return 0
        },

    },
    {
        id: 11,
        reorder: true,
        name: 'LAST CALL STATUS MODIFIED',
        selector: (row: any) => row?.last_updated_call_status_ts == 0 ? "Not set" : moment(row?.last_updated_call_status_ts).format("DD-MM-YYYY hh:mm:ss A"),
        sortable: true,
        minWidth: "200px",
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.last_updated_call_status_ts
            const b = rowB?.last_updated_call_status_ts
            if (a > b) {
                return 1
            }
            if (b > a) {
                return -1
            }
            return 0
        },
    },
    {
        id: 6,
        reorder: true,
        name: 'CUS. CAP',
        selector: (row: any) => row?.customer_cap ?? "NA",
        sortable: true,
    },
    {
        id: 7,
        reorder: true,
        name: 'CHAM. CAP',
        selector: (row: any) => row?.champ_cap ?? "NA",
        sortable: true,
    },
    {
        id: 8,
        reorder: true,
        name: 'POCs',
        selector: (row: any) => row?.customerCount ?? "NA",
        sortable: true,
    },
    {
        id: 14,
        reorder: true,
        name: 'ACTIVE CUSTOMERS',
        selector: (row: any) => row?.active_customers ?? "NA",
        sortable: true,
        width: "150px"
    },
    {
        id: 13,
        reorder: true,
        name: 'ROLE',
        selector: (row: any) => row?.role ?? "NA",
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => {
            const a = rowA?.role?.toLowerCase()
            const b = rowB?.role?.toLowerCase()
            if (a > b) {
                return 1
            }
            if (b > a) {
                return -1
            }
            return 0
        },
    },
    {
        id: 3,
        reorder: true,
        name: 'PHONE',
        selector: (row: { phone: any }) => <MaskedPhoneNumber phoneNumber={row?.phone}/>,
        sortable: true,
    },
    {
        id: 4,
        reorder: true,
        name: 'EMAIL',
        selector: (row: { email: any }) => row?.email,
        sortable: true,
        grow: 4,
        minWidth: "250px"
    },

];


const Actions = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState(row)

    useEffect(() => { setStoreRow(row) }, [row])
    const [teamLeader, setTeamLeader] = useState<any>(null)

    const setUpdatedTeamLeader = (payload: any) => {

    }

    return <>
        <UpdateAdminUser row={storeRow} />
        <span> Make T.L <FontAwesomeIcon className='me-2 ms-4 text-muted fs-4' icon={faEdit} onClick={() => setTeamLeader(Math.random())} /></span>

        {
            teamLeader ? <div key={"makeTeamLeader"}>
                <AdminYesNoAlertAction
                    confirm='Yes'
                    cancel='No'
                    successTitle={`Are you sure want to make team leader ?`}
                    FailedTitle='Changes Cancelled '
                    payload={{
                        adminID: storeRow?.id,
                    }}
                    show={teamLeader}
                    setShow={setTeamLeader}
                    setUpdatePrevState={setUpdatedTeamLeader}
                    refreshNow={null}
                    setPending={null}
                    urlEndPoint="makeTeamLeader"
                    row={storeRow}
                />
            </div> : <></>
        }



    </>
}

const MaxDeviceCountChange = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState(row)

    useEffect(() => { setStoreRow(row) }, [row])
    const [maxCount, setMaxCount] = useState<any>(null)

    const setUpdatedMaxCount = (payload: any) => {
        row.max_device_count = payload?.max_device_count
        setStoreRow(row)
    }

    return <>
        <span> {storeRow?.max_device_count} <FontAwesomeIcon className='me-2 ms-4 text-muted fs-4' icon={faEdit} onClick={() => setMaxCount(Math.random())} />
        </span>

        {
            maxCount ? <div key={"maxCount"}>
                <AdminMaxDeviceChange
                    confirm="Save"
                    cancel="Cancel"
                    successTitle="Change Max Device Count"
                    FailedTitle="Changes Cancelled"
                    payload={{
                        adminID: storeRow?.id,
                    }}
                    show={maxCount}
                    setShow={setMaxCount}
                    setUpdatePrevState={setUpdatedMaxCount}
                    refreshNow={null}
                    setPending={null}
                    urlEndPoint="updateAdminData"
                    row={storeRow}
                    />

            </div> : <></>
        }
    </>
}

const Status = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState(row)

    useEffect(() => { 
        setStoreRow(row)
     }, [row])

    const [status, setStatus] = useState<any>(null)

    const setUpdatedStatus = (payload: any) => {
        row.status = payload?.status
        setStoreRow(row)
    }

    let title = storeRow?.status === 1 ? "Active" : "Inactive"
    let color = storeRow?.status === 1 ? "success" : "danger"

    return <>
        <span className={`me-3 badge badge-${color}`}> {title}</span>  <FontAwesomeIcon className='text-muted fs-4' icon={faEdit} onClick={()=>setStatus(Math.random())} />

        {
            status ? <div key={"status"}>
                <AdminYesNoAlertAction
                    confirm="yes"
                    cancel="no"
                    successTitle={`Are you sure want to make ${storeRow?.status === 1 ? "Inactive" : "Active"} ?`}
                    FailedTitle="Changes Cancelled"
                    payload={{
                        adminID: storeRow?.id,
                        status: storeRow?.status === 1 ? 0 : 1
                    }}
                    show={status}
                    setShow={setStatus}
                    setUpdatePrevState={setUpdatedStatus}
                    refreshNow={null}
                    setPending={null}
                    urlEndPoint="updateAgentStatus"
                    row={storeRow}
                    />

            </div> : <></>
        }
    </>
}

const IsAvailable = ({ row }: any) => {
    const [storeRow, setStoreRow] = useState(row)

    useEffect(() => { setStoreRow(row) }, [row])

    const [isAvailable, setIsAvailable] = useState<any>(null)

    const setUpdatedIsAvailable = (payload: any) => {
        row.is_unavailable = row?.is_unavailable == 1 ? 0 : 1
        setStoreRow(row)
    }

    let title = storeRow?.is_unavailable == 1 ? "Unavailable" : "Available"
    let color = storeRow?.is_unavailable == 1 ? "danger" : "success"

    return <>
        <span className={`me-3 badge badge-${color}`}> {title}</span>  <FontAwesomeIcon className='text-muted fs-4' icon={faEdit} onClick={() => setIsAvailable(Math.random())} />

        {
            isAvailable ? <div key={"isAvailable"}>
                <AdminYesNoAlertAction
                    confirm='Yes'
                    cancel='No'
                    successTitle={`Are you sure want to make ${storeRow?.is_unavailable == 1 ? "Available" : "Unavailable"} ?`}
                    FailedTitle='Changes Cancelled '
                    payload={{
                        adminID: storeRow?.id,
                        isUnavailable: storeRow?.is_unavailable == 1 ? 0 : 1,
                    }}
                    show={isAvailable}
                    setShow={setIsAvailable}
                    setUpdatePrevState={setUpdatedIsAvailable}
                    refreshNow={null}
                    setPending={null}
                    urlEndPoint="markAgentAvailability"
                    row={storeRow}
                />
            </div> : <></>
        }

    </>
}