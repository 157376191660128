import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { ChampTrackingList } from './ChampTrackingList'
import { KTCardBody } from '../../../_metronic/helpers'
import { SubscriptionTrackingLIst } from './SubscriptionTrackingLIst'

export const ChampSubsMainComponent = () => {
    const [activeTab, setActiveTab] = useState('byChamp')

    return (
        <KTCardBody>
            <div className='mb-4 fs-4 fw-semibold'>Champ Counts</div>
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k || 'byChamp')}
                className='mb-4'
            >
                <Tab eventKey='byChamp' title='Count by Champ'>
                    <ChampTrackingList />
                </Tab>
                <Tab eventKey='bySubscription' title='Count by Subscription'>
                    <SubscriptionTrackingLIst />
                </Tab>

            </Tabs>
        </KTCardBody>
    )
}