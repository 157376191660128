
import React, { useEffect, useState } from 'react'
import "./Cleaner.css"
import { image_Cloudfront } from '../../consts/components/Utility'
import IsLoading from '../../consts/IsLoading/IsLoading'
import Not_Found from '../../consts/Not_Found'
import axios from 'axios'

const HiringPointsMap = () => {
  const [hiringPointsData, sethiringPointsData] = useState<any>([])
  console.log('hiringPointsData', hiringPointsData);
  const [Loader, setLoader] = useState<any>(false)
  const [map, setMap] = useState<any>(null);
  const [centerLatLong, setCenterLatLong] = useState({
    lat: 12.9121181,
    lng: 77.6445548,
  })

  async function loadhiringPointsData() {
    setLoader(true)
    const result = await axios.get('https://api.carselonadaily.com/api/admin/getHiringPointList', {
      headers: {
        'Authorization': 'Test'
      }
    })
    setLoader(false)
    sethiringPointsData(result?.data?.data)
  }

  useEffect(() => {
    loadhiringPointsData()
  }, [])

  let brnchUrl = `${image_Cloudfront}351f2fa6-07b0-4668-9469-dac59255d3d0.png`

  useEffect(() => {
    let activeInfoWindow: any = null
    function rendered() {
      const map = new window.google.maps.Map(document.getElementById('map') as any, {
        center: centerLatLong,
        zoom: 11
      });
      setMap(map);

      let allMarkers: any = []
      hiringPointsData.forEach((markerInfo: any) => {
        const smallMarkerIcon = {
          url:
            `http://maps.google.com/mapfiles/ms/icons/${markerInfo?.color_name}-dot.png`,
          scaledSize: new window.google.maps.Size(20, 20),
        };

        const marker = new window.google.maps.Marker({
          position: {
            lat: +markerInfo?.latitude,
            lng: +markerInfo?.longitude,
          },
          map: map,
          title: "",
          icon: smallMarkerIcon,
          zIndex: 1,
        });

        marker.set('hiringPointsData', markerInfo);

        allMarkers.push(marker)
        const infowindow = new window.google.maps.InfoWindow({
          content: `
              <div style="font-family: Arial, sans-serif; font-size: 14px;">
                  <table border="1" cellspacing="0" cellpadding="5" style="border-collapse: collapse; width: 100%;">
                      <tr>
                          <th style="text-align: left; padding: 5px;">Name</th>
                          <td style="padding: 5px;">${markerInfo?.name} </td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Address</th>
                          <td style="padding: 5px;">${markerInfo?.address || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Amount</th>
                          <td style="padding: 5px;">${markerInfo?.min_amount || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Shop Name</th>
                          <td style="padding: 5px;">${markerInfo?.shop_name || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Total Leads</th>
                          <td style="padding: 5px;">${markerInfo?.total_leads || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Converted Leads</th>
                          <td style="padding: 5px;">${markerInfo?.converted_leads || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Category</th>
                          <td style="padding: 5px; color:${markerInfo?.color}">${markerInfo?.category || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">QR Code </th>
                          <td style="padding: 5px;">${markerInfo?.qr_code || 'N/A'}</td>
                      </tr>
                      <tr>
                          <th style="text-align: left; padding: 5px;">Photo</th>
                          <td style="padding: 5px;">
                              ${markerInfo?.photo_url 
                                  ? `<img src="${markerInfo.photo_url}" alt="Photo" style="width: 100px; height: auto; border-radius: 5px;">`
                                  : 'No Image Available'}
                          </td>
                      </tr>
                  </table>
              </div>
          `
      });
      

        marker.addListener('click', function () {
          if (activeInfoWindow) {
            activeInfoWindow.close();
          }
          infowindow.open(map, marker);
          activeInfoWindow = infowindow;

        });
      });

      window.google.maps.event.addListener(map, 'zoom_changed', function () {

        var zoom: any = map.getZoom();

        allMarkers.forEach(function (marker: any) {

          if (zoom > 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url,
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(34, marker.getIcon().url == brnchUrl ? 45 : 34) //changes the scale
              }
            )
          } else if (zoom == 13) {
            marker.setIcon(
              {
                url: marker.getIcon().url,
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(29, marker.getIcon().url == brnchUrl ? 40 : 29) //changes the scale
              }
            )
          } else {
            marker.setIcon(
              {
                url: marker.getIcon().url,
                size: null,
                origin: null,
                anchor: null,
                scaledSize: new google.maps.Size(20, marker.getIcon().url == brnchUrl ? 30 : 20)//changes the scale
              }
            )
          }
        })
      });
    }

    const x = setTimeout(() => {
      hiringPointsData?.length > 0 && rendered()
    }, 1000);

    return () => {
      clearTimeout(x)
    }

  }, [hiringPointsData, centerLatLong])


  return (
    <>
      <h5 className='mb-4'>Hiring Points List Map</h5>
      {/* <div className="d-flex">
          <span className='border p-1 bg-white rounded me-3 mt-2'> 35-59P - <img style={{ width: "20px", height: "21px" }} src="http://maps.google.com/mapfiles/ms/icons/green-dot.png" alt="" /></span>
        </div> */}
      {
        hiringPointsData?.length == 0 && !Loader ? <Not_Found headerTitle={hiringPointsData?.length === 0 ? 'Select City To See Map' : "Select City To See Map"}></Not_Found> :
          Loader ? <IsLoading /> :
            <div id="map" style={{ height: 'calc(100vh - 250px)', width: '100%' }} />
      }


    </>
  )
}
export default HiringPointsMap
// Blue marker: http://maps.google.com/mapfiles/ms/icons/blue-dot.png
// Green marker: http://maps.google.com/mapfiles/ms/icons/green-dot.png
// Yellow marker: http://maps.google.com/mapfiles/ms/icons/yellow-dot.png
// Orange marker: http://maps.google.com/mapfiles/ms/icons/orange-dot.png
// Purple marker: http://maps.google.com/mapfiles/ms/icons/purple-dot.png
// Pink marker: http://maps.google.com/mapfiles/ms/icons/pink-dot.png
// Pink marker: http://maps.google.com/mapfiles/ms/icons/red-dot.png
