import React from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import AdminMultiSelectFilter from '../../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
export const AdminUserFilter = ({ ParentData }: any) => {
  return (
    <div className='p-6 w-325px w-md-375px'>
      <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>
      <span className='fs-semibold fs-7'>Select Role</span>
    
      <AdminMultiSelectFilter
        onChangeHandler={ParentData?.onChangeHandler}
        HeaderTitle="Select Role"
        SelectData={ParentData?.roleData || []}
        displayFields={["Role_name"]}
        id="id"
        name="role_id"
        defaultData={null}
        searchable={false}
        setSeacrch={false}
        isLoading={false}
        filters={null}
      />
    </div>
  )
}


