import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';






const SecurityDeposit = ({ depositModal, handleCloseModel, row }: any) => {

    const [loader, setLoader] = useState(false)

    const [payload, setPayload] = useState<any>({
        cleanerid: row?.id,
        security_deposit_amount: row?.security_deposit_amount,
        security_deposited_amount: row?.security_deposited_amount,
    })




    const depositHandler = async () => {

        setLoader(true)
        const securityDeposite: any = await postRequest(URL_EndPoints().updateChampSecurityDeposit, payload, setLoader)

        if (securityDeposite?.data?.status == 200) {
            toast.success(securityDeposite?.data?.message, { position: "top-center" })
        }

        else {
            toast.error(securityDeposite?.data?.message, { position: "top-center" })

        }

    }

    return (
        <Modal show={depositModal} onHide={handleCloseModel} dialogClassName="modal-small"
            // size="m"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Securit Deposit</Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <Form.Group className="mb-3" >
                    <Form.Label>Security deposit Amount </Form.Label>
                    <Form.Control placeholder="Enter deposit here..." name="security_deposit_amount" value={payload?.security_deposit_amount} onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Security deposited till now Amount </Form.Label>
                    <Form.Control placeholder="Enter deposited amount here..." name="security_deposited_amount" value={payload?.security_deposited_amount} onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })} />
                </Form.Group>

                <div className='d-flex  align-items-center justify-content-between '>
                    <button dir="rtl" className="btn btn-sm  btn-success mt-4 text-end " onClick={depositHandler} disabled={loader ? true : false}>
                        {loader ? "Applying..." : 'Apply'}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SecurityDeposit
