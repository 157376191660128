import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import SkeletonLoader from "../../consts/IsLoading/SkeletonLoader";
import { getRequest, postRequest } from "../../consts/Server/Requests";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";
import { adminParamsGenerator, adminToastRunner } from "../admins/adminUtils";

const GroupInfo = ({ show, setShow, groupId , getChatMessages}: any) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [groupsData, setGroupsData] = useState<any>({})
  const [pending, setPending] = useState(false)

  const getGroupsData = async () => {
    setPending(true)
    const response = await getRequest(URL_EndPoints(adminParamsGenerator("getGroupDetail", groupId))?.getGroupDetail, setPending)
    setGroupsData(response?.data?.data)
  }

  useEffect(() => {
    getGroupsData()
  }, [])
  const [removing, seRemoving] = useState<any>(null)
  const [loader, setLoader] = useState(false)
  const removeFromGroup = async (element: any) => {
    seRemoving(element?.id)
    setLoader(true)
    const response = await postRequest(URL_EndPoints()?.removeMembersFromGroup, {
      groupId,
      members: [
        { "userId": element?.user_id, "userType": element?.user_type }
      ]
    }, setLoader)
    adminToastRunner(response)
    if (response?.data?.status == 200) {
      getGroupsData()
      getChatMessages(1, true)
    }
  }
  const renderRightContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <>
            {
              pending ? <SkeletonLoader /> : <>
                <div className="text-center">
                  <div className="avatar rounded-circle bg-secondary mb-4" style={{ width: "40px", height: "40px", margin: "auto" }}>
                    <img src={groupsData?.createdBy?.profile_image ? groupsData?.createdBy?.profile_image : toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                  </div>
                  <h4 className="fw-bold">{groupsData?.name}</h4>
                </div>
                <div className="mt-4">
                  <p className="text-muted mb-1 fw-semibold">Created</p>
                  <p>{moment(groupsData?.createdAt).format("DD-MM-YYYY hh:mm")} -  {groupsData?.createdBy?.name}</p>
                </div>
                <div className="mt-3">
                  <p className="text-muted mb-1 fw-semibold">Description</p>
                  <p className="text-muted">
                    Welcome to the official Carelona Daily Group.
                  </p>
                </div>

              </>
            }
          </>
        );

      case "members":
        return <>
          {
            pending ? <SkeletonLoader /> : <>
              <>
                {
                  groupsData?.groupMembers?.map((ele: any) => (
                    <div className={`d-flex align-items-center p-2 border-bottom `} style={{ cursor: 'pointer' }}>
                      <div className="avatar rounded-circle bg-secondary" style={{ width: "40px", height: "40px" }}>
                        <img src={ele?.groupMember?.profile_image ? ele?.groupMember?.profile_image : toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                      </div>
                      <div className="ms-2 flex-grow-1">
                        <strong>{ele?.groupMember?.name}</strong>
                        <div className="text-muted small">{ele?.groupMember?.userTypeText}</div>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-muted small ms-1 mb-1">{moment(ele?.createdAt).format("DD-MM-YYYY")}</span>
                        <button disabled={removing == ele?.id && loader} className="text-danger bg-white d-flex justify-content-center align-items-center  p-2 border border-none" style={{ height: "20px" }} onClick={() => removeFromGroup(ele)}>{removing == ele?.id && loader ? "Removing..." : "Remove"}</button>
                      </div>
                    </div>
                  ))
                }
              </>

            </>
          }

        </>;

      default:
        return null;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="small-modal"
      centered
      size="lg"
      key="ListViewModal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Group Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex vh-100 ">
          {/* Sidebar */}
          <div className="border-end p-3" style={{ width: "220px" }}>
            <ul className="nav flex-column gap-2">
              <li className="nav-item">
                <button className={`nav-link btn btn-link text-start p-2 fs-5 w-100 ${activeTab === "overview" ? "border-none text-primary border-b-1  border-l-0 border-r-0 border-t-0" : "text-muted"
                  }`} onClick={() => setActiveTab("overview")}>Overview</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link btn btn-link text-start p-2 fs-5 w-100 ${activeTab === "members" ? "border-none text-primary border-b-1  border-l-0 border-r-0 border-t-0" : "text-muted"
                  }`} onClick={() => setActiveTab("members")}>Members</button>
              </li>

            </ul>
          </div>

          {/* Right Content */}
          <div className="flex-grow-1 p-4">
            {renderRightContent()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GroupInfo;
