// ChatListItem.js
import moment from "moment";
import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const ChatListItem = ({ name, message, createdAt, groupMember, id, onClick, selectedChat, group_image }: any) => {
    return (
        <div className={`d-flex align-items-center p-2 border-bottom bg-${selectedChat?.id == id ? "light" : ""}`} onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className="avatar rounded-circle bg-secondary" style={{ width: "40px", height: "40px" }}>
                <img src={group_image ? group_image : toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
            </div>
            <div className="ms-2 flex-grow-1">
                <strong>{name}</strong>
                <div className="text-muted small">{message}</div>
            </div>
            <div className="d-flex flex-column align-items-center ">
                <span className="text-muted small ms-1 mb-1">{moment(createdAt).format("DD-MM-YYYY")}</span>
                {
                    groupMember?.unread_message_count != 0 && <div>
                        <span className="bg-primary text-white rounded-pill d-flex justify-content-center align-items-center px-2">{groupMember?.unread_message_count}  </span>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChatListItem;