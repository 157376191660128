import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import TagsShow from "../../../consts/TagsShow/TagsShow";
export const TagsUpdate = ({ row, tag }: any) => {
    const RowDataUpdateTags = useSelector((store: any) => store.Schedules.RowDataUpdateTags) // to update from outside 
    const [storeRow, setStoreRow] = useState<any>(row)
    useEffect(() => {
        setStoreRow(row)
    }, [row])
    useEffect(() => {
        setStoreRow(row)
    }, [RowDataUpdateTags])
    return <>
        <>
            {
                tag === "private" && < div
                    
                    className='badge badge- fw-bolder rounded mb-1 d-flex justify-content-center'
                    style={{ whiteSpace: 'pre-wrap', cursor: "pointer" }
                    }
                >


                    <TagsShow Tag={storeRow?.private_tag}></TagsShow>




                </div>
            }
        </>
    </>
}