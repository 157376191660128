import React, { useEffect, useRef, useState } from 'react'
const apiKey = "AIzaSyB1TLe_5fP2B85oU9mvzNnJbN6QuHjdjHE";
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
// load google map api js
function loadAsyncScript(src: any) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}


function AutoCompleteAddressGoogleUtils({ address, setMarkerPositionLatLng, isTitle = false }: any) {
    const search: any = useRef<any>(null);

    // do something on address change
    const onChangeAddress = (autocomplete: any) => {
        const place = autocomplete.getPlace();
        setMarkerPositionLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
        // setAddress(search?.current?.value)
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!search.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(search.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }


    // init gmap script
    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }
    // load map script after mounted
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, []);


    // Set the input value when the address prop changes
    useEffect(() => {
        if (search.current) {
            search.current.value = address;
        }
    }, [address]);
    return (


        <div className="col-12  mb-3">
            <h5>{isTitle ? <></> : "Address"}</h5>
            <input
                placeholder='Address'
                name='address'
                type='text'
                className='form-control  mb-5 mb-lg-0'
                autoComplete='off'
                ref={search}
                style={{height:'38px'}}
            />


        </div>
    )
}
export default AutoCompleteAddressGoogleUtils
