import moment from "moment";
import {  useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const SeenMessage = ({ show, setShow, groupsData }: any) => {
  const [activeTab, setActiveTab] = useState("members");

  console.log('groupsData', groupsData);

  const renderRightContent = () => {
    switch (activeTab) {
      case "members":
        return <>
          {
            <>
              <>
                {
                  groupsData?.filter((ele: any) => ele?.seen)?.map((ele: any) => (
                    <div className={`d-flex align-items-center p-2 border-bottom `} style={{ cursor: 'pointer' }}>
                      <div className="avatar rounded-circle bg-secondary" style={{ width: "40px", height: "40px" }}>
                        <img src={ele?.user?.profile_image ? ele?.user?.profile_image : toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
                      </div>
                      <div className="ms-2 flex-grow-1">
                        <strong>{ele?.user?.name}</strong>
                        <div className="text-muted small">{ele?.user?.userTypeText}</div>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-muted small ms-1 mb-1">Seen at {moment(ele?.seen_at).format("DD-MM-YYYY hh:mm a")}</span>
                      </div>
                    </div>
                  ))
                }
              </>

            </>
          }
        </>;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="small-modal"
      centered
      size="lg"
      key="ListViewModal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Message Seen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex vh-100 ">
          <div className="border-end p-3" style={{ width: "220px" }}>
            <ul className="nav flex-column gap-2">
              {/* <li className="nav-item">
                <button className={`nav-link btn btn-link text-start p-2 fs-5 w-100 ${activeTab === "overview" ? "border-none text-primary border-b-1  border-l-0 border-r-0 border-t-0" : "text-muted"
                  }`} onClick={() => setActiveTab("overview")}>Overview</button>
              </li> */}
              <li className="nav-item">
                <button className={`nav-link btn btn-link text-start p-2 fs-5 w-100 ${activeTab === "members" ? "border-none text-primary border-b-1  border-l-0 border-r-0 border-t-0" : "text-muted"
                  }`} onClick={() => setActiveTab("members")}>Members</button>
              </li>

            </ul>
          </div>

          {/* Right Content */}
          <div className="flex-grow-1 p-4">
            {renderRightContent()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SeenMessage;
