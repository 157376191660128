import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

const ExcelExportButton = ({ tableName }: any) => {

    const exportToExcel = () => {
        const columnHeaders = document.querySelectorAll(`${tableName} [role="columnheader"]`) || [];
        const headers = Array.from(columnHeaders).map((header: any, index: any) => {
            if (index === 0) {
                const checkbox = header.querySelector('input[type="checkbox"]');
                if (checkbox) {
                    return '';
                }
            }
            return header.textContent.trim().replace(/[^\w\s]/gi, '');
        });

        const pattern = /[!@#$%^&*(),.?":{}|<>]/g;

        // Use the replace() method to remove the special characters
        const fileName = tableName.replace(pattern, '');

        const rows = document.querySelectorAll(`${tableName}  [role="row"]`) || [];
        const rowData = Array.from(rows).map((row: any) => {
            const cells = row.querySelectorAll('[role="cell"]');
            const cellData = Array.from(cells).map((cell: any, index: any) => {

                if (index === 0) {
                    const checkbox = cell.querySelector('input[type="checkbox"]');
                    if (checkbox) {
                        return '';
                    }
                }


                const select = cell.querySelector('select');
                const checkbox = cell.querySelector('input[type="checkbox"]');
                const parentDiv = checkbox?.parentElement;
                if (select) {
                    return select.options[select.selectedIndex].text.trim();
                } else if (checkbox && (parentDiv && !parentDiv.classList.contains('EZDrawer'))) {

                    return checkbox.checked ? 'true' : 'false';
                } else {
                    return cell.textContent.trim();
                }
            });
            return cellData;
        });
        const workbook = XLSX.utils.book_new();
        const sheetData = [headers, ...rowData];
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        saveAsExcelFile(excelBuffer, `${fileName}.xlsx`);
    };

    const saveAsExcelFile = (buffer: any, fileName: any) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(data);
        downloadLink.download = fileName;
        downloadLink.click();
    };

    return (

        <button className='btn btn-primary btn-sm  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary btn-active-secondary p-3 px-3 filterBtn me-2' onClick={exportToExcel}>
            <FontAwesomeIcon icon={faFileExport} className='me-1' /> Export
        </button>
    );
};

export default ExcelExportButton;
