import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { columns } from "./columns"
import { toast } from "react-toastify"
import { AllFielSearch } from "../../../../consts/FieldSearch/AllFieldSearch"
import { getRequest, postRequest } from "../../../../consts/Server/Requests"
import { URL_EndPoints } from "../../../../consts/Server/URL_EndPoints"
import { ColModal, GroupModal, RowModal, SelectModal } from "../../../../consts/widgets/forms/FormsModals"
import { QueryParams } from "../../../../consts/Server/QueryParams"

export const AssignChangeSlab = ({ row, show, setShow }: any) => {

    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState<boolean>(false)
    const [filterData, setfilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [loader, setLoader] = useState<boolean>(false)
    const [schemeLists, setSchemeLists] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        slab_id: 0,
        cleanerid: row?.id,
        cleaner_payout_scheme_id: null,
        min_earning_week: null,
        scheme_assigned_date: ""
    })

    useEffect(() => {
        setPending(true)
        async function InvokedRendered() {

            let newQueryParams = { ...QueryParams("getSchemeRecord", "/") }
            newQueryParams.What += row?.id
            const { data } = await getRequest(URL_EndPoints(newQueryParams)?.getSchemeRecord, setPending)
            setData(data.data)
            setfilterData(data.data)
            const getSchemesList = await getRequest(URL_EndPoints()?.getAllActiveSchemes, setPending)
            setSchemeLists(getSchemesList?.data?.data)
        }
        InvokedRendered()


    }, [row?.id])

    useEffect(() => {
        const updatedData = AllFielSearch(data, search)
        setfilterData(updatedData)
    }, [search])

    const handleSubmitAssign = async () => {
        setLoader(true)

        const { data } = await postRequest(URL_EndPoints()?.assignSchemeToCleaner, payload, setLoader)
        if (data?.status == 200) {
            toast.success(data?.msg, { position: "top-center" })
        }
        else {
            toast.error(data?.msg, { position: "top-center" })
        }
    }
    return <>

        <Modal show={show} onHide={() => setShow(false)} size="xl" >
            <Modal.Header closeButton>
                <div className='w-100 d-flex justify-content-between '>
                    <div className='d-flex align-items-start justify-content-start '>
                        <input
                            type="text"
                            placeholder='Search Here'
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            className='w-100 form-control  align-start' />
                    </div>
                    <div className='d-flex align-items-center justify-content-end me-10 '>
                        <span className="fs-4 fw-bold">Assign / Change Slab</span>
                    </div>
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='w-100 d-flex justify-content-between '>
                    <div className='d-flex align-items-start justify-content-start '>
                        <input
                            type="text"
                            placeholder='Search Here'
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            className='w-100 form-control  align-start' />
                    </div>
                    <div className='d-flex align-items-center justify-content-end me-10 '>
                        <span className="fs-4 fw-bold">Assign / Change Slab</span>
                    </div>
                </div>
                {
                    <DataTable
                        title=""
                        columns={columns}
                        data={filterData}
                        pagination
                        progressPending={pending}
                        fixedHeader
                        fixedHeaderScrollHeight='auto'
                        selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<>
                            <div className="col-12 ">
                                <RowModal classes="d-flex flex-wrap justify-content-between ">
                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <SelectModal
                                                classes="mb-2"
                                                title="Select Scheme Name"
                                                name="cleaner_payout_scheme_id"
                                                keyName="scheme_name"
                                                id="id"
                                                isData={schemeLists}
                                                payload={payload}
                                                setPayload={setPayload}
                                                defValue={[]}
                                            />
                                        </GroupModal>
                                    </ColModal>
                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <input
                                                placeholder='Min Earning Point'
                                                name='min_earning_week'
                                                type='text'
                                                value={payload?.min_earning_week}
                                                className='form-control  mb-3 mb-lg-0'
                                                autoComplete='off'
                                                onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                            />
                                        </GroupModal>
                                    </ColModal>
                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <input
                                                placeholder='Parking no. '
                                                name='scheme_assigned_date'
                                                type='date'
                                                value={payload.scheme_assigned_date}
                                                className='form-control  mb-3 mb-lg-0'
                                                autoComplete='off'
                                                onChange={(e) => setPayload({ ...payload, [e.target.name]: e.target.value })}
                                            />
                                        </GroupModal>
                                    </ColModal>
                                    <ColModal classes="mb-4 col-12 col-md-4 col-sm-6 col-lg-3">
                                        <GroupModal classes="" >
                                            <button disabled={loader} className={`btn btn-sm btn-${loader ? "warning" : "primary"} fs-6`} onClick={handleSubmitAssign}>
                                                {loader ?
                                                    <>
                                                        <span className="spinner-grow spinner-grow-sm  text-white me-2" role="status" aria-hidden="true"></span>
                                                        <span>Assigning...</span>
                                                    </>
                                                    : " Assgin / Change"}
                                            </button>
                                        </GroupModal>
                                    </ColModal>


                                </RowModal>

                            </div>
                        </>}
                    />
                }

            </Modal.Body>
        </Modal>
    </>

}








