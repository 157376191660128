import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { columns } from './Columns';
import "./taskListAction/custom.css"
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { DynamicFilter, FilterHandler } from '../../consts/DynamicFilteration/FilterHandler';
import { getCurrentFilterHandler, getFilterDataBySearch, initialPayload, operators, removeFilterHandler } from '../../consts/DynamicFilteration/components/CommonData';
import { KeywiseComponents } from '../../consts/DynamicFilteration/KeywiseComponents';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility';
import IsPaidTaskListAndRenewalTaskYesNo from '../../consts/SweetAlert/IsPaidTaskListAndRenewalTaskYesNo';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton';
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet';
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList';
import AdminBulkForm from '../admins/adminForms/AdminBulkForm';
import { filteredSelectedRowsIds, useAdminListApiUrl, useAdminManagementPermission, useAdminSmartFilterList } from '../admins/adminUtils';
import { adminMenuActionPermissionKeys, adminWatiNotifyTemplatFormData } from '../admins/adminConstant';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import CreateReturnTask from './CreateReturnTask';
import CreateSelfCheckTask from './CreateSelfCheckTask';

const TaskLists = ({ filterDataOutsource }: any) => {
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()

  const [pending, setPending] = React.useState(false)
  const [filterData, setfilterData] = useState<any>([])
  const [filterDataTemp, setfilterDataTemp] = useState<any>([])
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [storeFilterList, setStoreFilterList] = useState<any>({})
  const [selectedFilterData, setSelectedFilterData] = useState<any>([])
  const [totalRows, setTotalRows] = useState<any>(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [sortby, setSortBy] = useState<any>("");
  const [direction, setdirections] = useState<any>("desc");
  const [page, setPages] = useState<any>(1);
  const [search, setSearch] = useState<any>("");
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [filterkey, setFilterKey] = useState<any>({})
  const [filter, setFilter] = useState<any>([])
  const [payload, setPayload] = useState<any>(initialPayload(true, { "task_type": { operator: "!=", value: 1 } }))

  const [sortedColumns, setSortedColumns] = useState<any>(permissionWrapperHideColsUtil(columns(null, null, null, null)));

  useEffect(() => {
    if (filterData) {

      const newColumns = permissionWrapperHideColsUtil(columns(handleFetchAllCallLogs, handleRefresh, filterData?.map((ele: any) => ele?.attendenceCleaner?.id), filterData));
      setSortedColumns(newColumns);
    }
  }, [filterData]);

  const [renders, setRender] = useState<any>(null)
  async function InvokedRendered(payloads: any, setloading: any) {
    setPending(true)
    const { data } = await postRequest(URL_EndPoints(null)?.taskListPagination, payloads, setloading)
    setfilterData(data?.data)
    setTotalRows(data?.recordsTotal)
    setFilter(data?.filter?.taskList)
    setPending(false)
  }



  const handlePagination = (search: any = "", sortBy = "", sortDirection: any = "desc", page: any = 1, perPageSize: any = 10) => {
    payload.search = search
    payload.start = page
    payload.length = perPageSize
    payload.sortby = sortBy
    payload.orders = sortDirection
    payload.direction = sortDirection

    setPayload(payload)
    return payload
  }

  const onChangeHandler = (event: any, key: any, value: any, defVal: any = false, timestamp: any, multiVal: any) => {
    setPayload({ ...payload, filterData: { ...payload.filterData, [key]: { ...payload.filterData[key], [value]: event?.value, [key + "_" + value]: defVal ? event : "", isDate: defVal ? false : true, timestamp: timestamp === "null" ? undefined : timestamp, multiVal: multiVal } } })
  }

  const actionHandler = async () => {
    await InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }

  const removeFilter = (index: any, key: any) => {
    removeFilterHandler(index, key, setPayload, payload, setSelectedFilterData, selectedFilterData)
  }

  const getCurrentFilter = async (key: any, currentItemRemovedOrAdded: any) => {
    getCurrentFilterHandler(key, currentItemRemovedOrAdded, setFilterKey, setSelectedFilterData, selectedFilterData, setStoreFilterList, storeFilterList, payload, setPayload)
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSortBy(column.sortField)
    setdirections(sortDirection)
    InvokedRendered(handlePagination(search, column.sortField, sortDirection, page, perPageSize), setPending)
  };

  const handlePageChange = (page: any) => {
    setPages(page)
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  };
  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    InvokedRendered(handlePagination(search, sortby, direction, page, newPerPageSize), setPending)
    setPerPageSize(newPerPageSize)
  };

  useEffect(() => {

    let timer = setTimeout(async function () {
      search && setPending(true)
      search && InvokedRendered(handlePagination(search && search, sortby, direction, page, perPageSize), setPending)
    }, 1000)
    return () => {
      clearTimeout(timer)
    }

  }, [search])

  useEffect(() => {
    let { isFilter, taskListFilter } = filterDataOutsource || {}
    !search && !isFilter && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
    isFilter && InvokedRendered({ ...payload, ["filterData"]: taskListFilter }, null)
    isFilter && setPayload({ ...payload, ["filterData"]: taskListFilter })
  }, [
    search,
    filterDataOutsource?.taskListFilter,
    filterDataOutsource?.isFilter,
  ])

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    selectedFilterData?.length === 0 && InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending);

  }, [selectedFilterData?.length]);

  // const loadData = async () => {
  //   const whatsappTemplates: any = await postRequest(URL_EndPoints(null)?.get_WhatsAppTemplate, {
  //     wa_number_id: 2
  //   }, null);
  //   setWhatsappTemplates(whatsappTemplates?.data?.data);
  // }

  // useEffect(() => {
  //   loadData();
  // }, []);

  function handleFetchAllCallLogs() {
    setfilterData((prev: any) => {
      setfilterDataTemp(prev);
      return prev;
    });
  }

  useEffect(() => {
    const fetchAllCallHistory = async () => {
      setPending(true);
      const clone = filterDataTemp.slice();
      const cleanerIds = clone.map((e: any) => e.user_id);
      const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
        customerIds: cleanerIds,
        userType: 'cleaner'
      }, setPending);
      const callLogs = response?.data?.data;
      for (let i = 0; i < clone.length; i++) {
        const e: any = clone[i];
        const foundLog = callLogs?.find((ele: any) => ele[0]?.From === e?.attendenceCleaner?.phone || ele[0]?.To === e?.attendenceCleaner?.phone) || [];
        clone[i].callLogs = foundLog;
      }
      setfilterData(clone);
      setPending(false);
    }
    filterDataTemp?.length > 0 && fetchAllCallHistory();
  }, [filterDataTemp])



  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([]);
  const [filteredSelectedRowsBulkIds, setfilteredSelectedRowsBulkIds] = useState<any>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const handleSelectedRowsChange = (selectedRows: any) => {
    let isPaidIds = selectedRows?.selectedRows?.map((row: any) => row?.id) || [];
    setfilteredSelectedRowsBulkIds(isPaidIds)
    setFilteredSelectedRows(selectedRows?.selectedRows);
    const count = [...new Set(selectedRows?.selectedRows?.map((row: any) => row?.user_id)) as any]?.length;
    setSelectedRowsCount(count);
  };

  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [isBulkNotifyLoading, setIsBulkNotifyLoading] = useState(false);
  const [whatsappNotifyPayload, setWhatsappNotifyPayload] = useState<any>({
    templateName: '',
  });

  const handleNotifyPopUpClose = () => {
    setWhatsappNotifyPayload({
      templateName: '',
    });
    setShowNotifyPopUp(false);
  }

  const handleWhatsappInputChange = (e: any) => {
    setWhatsappNotifyPayload({
      ...whatsappNotifyPayload,
      [e.target.name]: e.target.value
    });
  }

  const [showMissedModal, setShowMissedModal] = useState(false)
  const updateStatusToMissed = async () => {
    setShowMissedModal(true)
    const response: any = await postRequest(URL_EndPoints(null)?.updateUnActionedHiringTasksToMissed, {}, setShowMissedModal)
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message, { position: "top-center" })
      setShowMissedModal(false)
    }
    else {
      toast.error(response?.data?.message, { position: "top-center" })
      setShowMissedModal(false)
    }
  }

  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
  const [dynFilter, setDynFilter] = useState<any>("")

  const setSearchFilter = (event: any, filters: any) => {
    setDynFilter(filters)
    setStoreSearchKeywords(event)
  }
  useEffect(() => {
    let x = setTimeout(async () => {
      setIsLoadingFilter(true)
      storeSearchKeywords && await getFilterDataBySearch(dynFilter, setStoreFilterList, storeFilterList, {
        keyword: storeSearchKeywords,
      })
      setIsLoadingFilter(false)
    }, 2000)
    return () => {
      setIsLoadingFilter(false)
      clearTimeout(x)
    }
  }, [storeSearchKeywords, dynFilter])


  const handleRefresh = () => {
    InvokedRendered(handlePagination(search, sortby, direction, page, perPageSize), setPending)
  }


  const [showCampaignContact, setShowCampaignContact] = useState(false);


  let { adminGetSmartFilterList } = useAdminSmartFilterList()
  let { listApiUrl } = useAdminListApiUrl()

  useEffect(() => {
    adminGetSmartFilterList("taskListPagination")
  }, [])

  const [showCreateReturnTask, setShowCreateReturnTask] = useState(false)
  const [showCreateSelfCheckTask, setShowCreateSelfCheckTask] = useState(false)
  return (
    <>
      <div>
        <DataTable
          title={!filterDataOutsource?.isFilter && "Task Lists"}
          selectableRows
          progressPending={pending}
          customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(sortedColumns, "taskListsReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "taskListsReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          // server 
          pagination
          clearSelectedRows={toggledClearRows}
          sortServer
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          // server 
          subHeaderComponent={
            <>



              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={search} setSearch={setSearch} />
                </LeftBox>

                <RightBox>
                  <Refresh refreshNow={handleRefresh} />
                  {
                    !filterDataOutsource?.isFilter &&
                    <BulkUtilButton title="Create Return Task" filteredSelectedRows={"static"} setShow={setShowCreateReturnTask} />
                  }
                  {
                    !filterDataOutsource?.isFilter &&
                    <BulkUtilButton title="Create Self Check Task" filteredSelectedRows={"static"} setShow={setShowCreateSelfCheckTask} />
                  }
                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-update-to-missed-training"]}>
                    {
                      !filterDataOutsource?.isFilter && <Button className='btn-sm mb-2 ms-2 ' onClick={() => updateStatusToMissed()} disabled={showMissedModal}>{showMissedModal ? "Updating..." : "Update Missed Training"}</Button>
                    }
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-filter"]}>
                    <FilterHandler
                      onChangeHandler={onChangeHandler}
                      actionHandler={actionHandler}
                      getCurrentFilter={getCurrentFilter}
                      removeFilter={removeFilter}
                      payload={payload}
                      Component={DynamicFilter}
                      filterData={filter}
                      OperatorsData={operators}
                      selectedFilterData={selectedFilterData}
                      filterComponents={KeywiseComponents()}
                      // filter data  -----
                      storeFilterList={storeFilterList}
                      filterkey={filterkey}
                      setSeacrch={setSearchFilter}
                      isLoadingFilter={isLoadingFilter}
                      listApiUrl={listApiUrl("taskListPagination")}

                    />
                  </PermissionContextProvider>


                </RightBox>
              </MainContainerWrapper>

              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-bulk-whatsapp-notification"]}>
                    <BulkUtilButton title="Bulk WhatsApp Notify Champs" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-bulk-is-paid-status"]}>
                    <BulkUtilButton title="Bulk Update Is Paid Status" filteredSelectedRows={filteredSelectedRows} setShow={setRender} randomValue={true} />
                  </PermissionContextProvider>

                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["task-lists-bulk-add-to-contact-to-list"]}>
                    <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                  </PermissionContextProvider>



                </BulkUtilButtonWrapper> : <></>
              }


            </>
          }
        />

        {
          showCampaignContact ? <AdminBulkAddContactsToCampaignList
            show={showCampaignContact}
            setCloseModal={setShowCampaignContact}
            filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.attendenceCleaner?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
            setToggleClearRows={setToggleClearRows}
            toggledClearRows={toggledClearRows}
            handleRefresh={handleRefresh}
            userType={"cleaner"}
          /> : <></>
        }




        {
          showNotifyPopUp ? <AdminBulkForm
            show={showNotifyPopUp}
            setShow={setShowNotifyPopUp}
            filteredSelectedRows={filteredSelectedRowsIds(filteredSelectedRows, "user_id") || []}
            setFilteredSelectedRows={setFilteredSelectedRows}
            toggledClearRows={toggledClearRows}
            setToggleClearRows={setToggleClearRows}
            refreshNow={handleRefresh}
            toastMessage="msg"
            formData={adminWatiNotifyTemplatFormData({ HeaderTitle: "", valueType: "", name: "", errMessage: "", label: "", wa_number_id: 2 })}
            initialData={{
              champIds: [],
              templateName: "",
            }}
            keyName="taskListWatiNotify"
            formTitle={`Send WhatsApp Notification to ${filteredSelectedRows?.length} Champs`}
            bulkKeyName="champIds"
            formSubmitApiUrl="sendBulkWhatsAppTemplateToChamp"
          /> : <></>
        }




      </div>

      {
        renders ?
          <IsPaidTaskListAndRenewalTaskYesNo
            render={renders}
            confirm={'Yes'}
            cancel={'No'}
            setFilteredSelectedRows={setFilteredSelectedRows}
            payload={{
              "jobIds": filteredSelectedRowsBulkIds,
              "approver_id": localStorage.getItem("user"),
              "requested_by": localStorage.getItem("user"),
              "requested_user_type": localStorage.getItem("role"),
              "updateFields": {
                "ispaid": 1
              }
            }}
            setToggleClearRows={setToggleClearRows} toggledClearRows={toggledClearRows}
            setRender={setRender}
            title={`Are you sure want to Paid ?`}

          /> : ""
      }


      {
        showCreateReturnTask ? <CreateReturnTask
          show={showCreateReturnTask}
          setShow={setShowCreateReturnTask}
          refreshNow={null}
          filteredSelectedRows={filteredSelectedRows?.map((ele: any) => ele?.inventoryOrderedItems?.map((ele: any) => ({ ...ele, quantity_ordered: ele?.quantity })))?.flat()}

        /> : <></>
      }

      {
        showCreateSelfCheckTask ? <CreateSelfCheckTask
          show={showCreateSelfCheckTask}
          setShow={setShowCreateSelfCheckTask}
          refreshNow={null}
        /> : <></>
      }
    </>
  )
}


export default TaskLists
