import React, { FC } from 'react'
import SkeletonLoader from '../../../consts/IsLoading/SkeletonLoader'
import AdminUsersMainList from './AdminUsersMainList'
const AdmInMainRoutes = ({ setFilteredIdForChat, apiPending }: any) => {
    return (
        <>
            {
                // apiPending ? <SkeletonLoader rowCount={10} title="Admin User List" /> :
                <AdminUsersMainList setFilteredIdForChat={setFilteredIdForChat}></AdminUsersMainList>
            }
        </>
    )
}
export default AdmInMainRoutes
