import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault'
import { getPostMultipleApiHandler, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import '../Styles/form.css'
import SingleRequirementTypeSearch from '../../../consts/champChatDashboard/actions/SingleRequirementTypeSearch'
import MomentDate from '../../../consts/MomentDate'
import { AdminChamLeadStatusFormApi } from '../adminConstant'
import { adminToastRunner } from '../adminUtils'


const shouldRenderSubreason = (StatusUpdateDataList: any, reasonId: any) => StatusUpdateDataList?.find((ele: any) => ele?.id === reasonId && ele?.sub_reason_exists)?.name;

const trainingWithFormData = [52, 53, 54, 55, 56, 57];

const AdminChampLeadStatusActionForm = ({ FilteredData, setStoreRow = null, row, prevInitialData }: any) => {

    const [StatusUpdateDataList, setStatusUpdateDataList] = React.useState<any>([]);
    const [trainingLocations, setTrainingLocations] = React.useState<any>([]);
    const [subReasons, setSubReasons] = React.useState<any>([]);
    const [activeChamps, setActiveChamps] = React.useState<any>([]);


    const getInitialList4Actions = async () => {
        const results = await getPostMultipleApiHandler(AdminChamLeadStatusFormApi, null)
        setStatusUpdateDataList(results["getMasterReasonForVisitors"] || [])
        setTrainingLocations(results["getChampRequirementWithChampCount"] || [])
        setSubReasons(results["getMasterSubReason"] || [])
        setActiveChamps(results["getActiveChampList"] || [])
    }

    useEffect(() => {
        FilteredData?.show && getInitialList4Actions()
    }, [FilteredData?.show])

    const [StatusUpdatedReason, setStatusUpdatedReason] = React.useState<any>(null);
    const [defaultValue, setDefaultValue] = React.useState<any>(null);
    const [subStatusDefault, setSubStatusDefault] = React.useState<any>(null);
    const [loader, setLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [trainer, setTrainer] = useState<any>({});

    const [payloadData, setPayloadData] = useState<any>({
        trainingLocationId: 0,
        dueDate: MomentDate(1),
        trainerId: row?.trainer?.id,
        sub_status_id: null,
        champ_requirement_id: 0
    });



    useEffect(() => {
        const subStatusDefault = subReasons?.find((ele: any) => ele?.id == row?.champSubStatus?.id)
        subStatusDefault && setSubStatusDefault({ label: subStatusDefault?.name, value: subStatusDefault?.id })
        subStatusDefault && setPayloadData({ ...payloadData, ["sub_status_id"]: subStatusDefault?.id })

    }, [subReasons, row?.subStatusDefault?.id])

    useEffect(() => {
        FilteredData?.show == false ? setDefaultValue(null) : setDefaultValue({
            label: "No Trainer",
            value: "No Trainer",
        })
    }, [row?.trainer?.first_name, FilteredData?.show]);


    const handleChange = (event: any, name: any) => {

        if (event === null) {
            if (name === "trainingLocationId") {
                setPayloadData((prev: any) => ({ ...prev, trainingLocationId: 0, champ_requirement_id: 0 }));
                setTrainer({});
            } else if (name === "trainerId") {
                setPayloadData((prev: any) => ({ ...prev, trainerId: 0 }));
            } else if (name === "sub_status_id") {
                setPayloadData((prev: any) => ({ ...prev, sub_status_id: 0 }));
            } else {
                setStatusUpdatedReason(null);
            }
        }

        else if (name === "trainingLocationId") {
            setPayloadData((prev: any) => ({ ...prev, ["champ_requirement_id"]: event.value, ["trainingLocationId"]: event?.item?.training_location_id }));
        }
        else if (name === "name") {
            setStatusUpdatedReason(event.value)
        }
        else {
            setPayloadData((prev: any) => ({ ...prev, [name]: event.value }));
        }
    }

    const handleVisitorStatus = async () => {

        setLoader(true)
        if (StatusUpdatedReason != null) {
            const payload = {
                champId: row?.id,
                statusId: StatusUpdatedReason,
                dueDate: payloadData.dueDate,
                trainingLocationId: payloadData.trainingLocationId ? payloadData.trainingLocationId : row?.cleanerTrainingLocation?.id,
                trainerId: payloadData.trainerId ? payloadData.trainerId : row?.trainer?.id,
                sub_status_id: payloadData.sub_status_id,
                champ_requirement_id: payloadData.champ_requirement_id,
            }

            const response: any = await postRequest(URL_EndPoints(null)?.updateChampVisitorStatus, payload, setLoader)
            adminToastRunner(response)
            if (response?.data?.status === 200) {
                const trainer: any = activeChamps?.find((champ: any) => champ?.id === payloadData?.trainerId);
                row['status_id'] = +StatusUpdatedReason
                row['last_resolution_date'] = payloadData.dueDate
                row['trainer'] = trainer
                setStoreRow && setStoreRow(row)
                FilteredData?.handleCloseForm()
            }
            setDisable(false);
            setLoader(false);

        } else {
            toast.error("Status id missing")
            setLoader(false);
            FilteredData?.handleCloseForm()
        }
    }

    useEffect(() => {
        FilteredData?.selectedId?.status_id && setStatusUpdatedReason(FilteredData?.selectedId?.status_id)
    }, [StatusUpdateDataList, FilteredData?.selectedId?.status_id])

    useEffect(() => {

        const fetchTrainer = async () => {
            setIsLoading(true)
            setDefaultValue(null);
            const result = await postRequest(URL_EndPoints(null)?.getTrainerByTrainingLocation, { trainingLocationId: payloadData?.trainingLocationId }, setIsLoading);
            result?.data?.data && setTrainer(result?.data?.data);
            result?.data?.data && setDefaultValue({
                label: result?.data?.data?.first_name + " " + result?.data?.data?.last_name,
                value: result?.data?.data?.id,
            });
            result?.data?.data && setPayloadData((prev: any) => ({ ...prev, ["trainerId"]: result?.data?.data?.id }));
            setIsLoading(false)
        }

        payloadData?.trainingLocationId && fetchTrainer();
    }, [payloadData?.trainingLocationId]);

    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleCloseForm} dialogClassName="modal"
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Champ Lead Status</Modal.Title>

                </Modal.Header>
                <Modal.Body >
                    <div className={`col-12  mb-3`} key={5}>
                        <label htmlFor="">Training</label>
                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChange}
                            HeaderTitle={"Select Status"}
                            SelectData={StatusUpdateDataList}
                            DynamicKey={"name"}
                            id={"id"}
                            name={"name"}
                            defaultData={prevInitialData}
                        />
                    </div>
                    {trainingWithFormData.includes(StatusUpdatedReason) && (
                        <>
                            <div className="col-12 mb-3" key={1}>
                                <span className='ms-2 muted'>ID 👉 Type 👉 Area Name 👉 Location Name 👉 Priority 👉 Min Payout 👉 referral  </span>
                                <span className='ms-2'> Amount 👉 champ Bonus Amount 👉 Champ in training </span>
                                <SingleRequirementTypeSearch
                                    handleChangeInputData={handleChange}
                                    HeaderTitle={"Training Location "}
                                    SelectData={trainingLocations.map((ele: any) => {
                                        let ConcatedLocationName = { ...ele }
                                        ConcatedLocationName.showName = (ele?.id ?? "NA")
                                            + " | " + (ele?.requirementType?.name ?? "NA")
                                            + " | " + (ele?.champ_requirement_area?.areaname ?? "NA")
                                            + " | " + (ele?.trainingLocation?.locationname ?? "NA")
                                            + " | " + (ele?.priority_level?.priority ?? "NA")
                                            + " | " + (ele?.min_payout ?? "NA")
                                            + " | " + (ele?.referral_bonus_amount ?? "NA")
                                            + " | " + (ele?.champ_bonus_amount ?? "NA")
                                            + " | " + (ele?.totalChampCount ?? "NA")

                                        return ConcatedLocationName
                                    })}
                                    DynamicKey={"showName"}
                                    id={"id"}
                                    name={"trainingLocationId"}
                                    defaultData={{ label: "No Location", value: "No Location" }}
                                />
                            </div>
                            <div className="col-12 mb-3" key={111}  >
                                {defaultValue && <label >Trainer</label>}

                                {defaultValue ? <SingleSelectSearchCategoryDefault
                                    handleChangeInputData={handleChange}
                                    HeaderTitle={"Select Trainer"}
                                    SelectData={trainer?.first_name ? [...activeChamps, trainer] : activeChamps}
                                    DynamicKey={"first_name"}
                                    DynamicKey2={"last_name"}
                                    DynamicKey3={"phone"}
                                    id={"id"}
                                    name={"trainerId"}
                                    defaultData={defaultValue ? defaultValue : {}}
                                />

                                    : <div className='d-flex justify-content-center text-center w-100 border text-success'>

                                        <span className="spinner-border spinner-border-sm mt-2 me-3 fw-bold fs-2" role="status" aria-hidden="true"></span>
                                        <span className='mt-1 fw-bold'>Loading...</span>
                                    </div>

                                }
                            </div>

                            <div className="col-12 mb-3" style={{ position: "relative", right: "10px" }} key={3}>
                                <label htmlFor="" className='ml-2'>Training Date</label>
                                <input
                                    type='date'
                                    style={{ height: '38px' }}
                                    value={payloadData.dueDate}
                                    name='dueDate'
                                    onChange={(event: any) =>
                                        handleChange(event.target, 'dueDate')
                                    }
                                    className='w-100  height-100 form-control me-2 px-2 ms-3 align-start'
                                />
                            </div>
                        </>
                    )}
                    {shouldRenderSubreason(StatusUpdateDataList, StatusUpdatedReason) &&
                        <div className="col-12 mb-3" key={4} >
                            <label htmlFor="">Sub Status</label>
                            <SingleSelectSearchCategoryDefault
                                handleChangeInputData={handleChange}
                                HeaderTitle={"Select Sub Status"}
                                SelectData={subReasons}
                                DynamicKey={"name"}
                                id={"id"}
                                name={"sub_status_id"}
                                defaultData={subStatusDefault ? subStatusDefault : null}
                            />
                        </div>
                    }
                    <div className='d-flex  align-items-center justify-content-end '>
                        <button className="btn btn-sm px-3 btn-success mt-4 d-flex" disabled={disable || loader ? true : false} onClick={() => handleVisitorStatus()}>
                            {
                                loader ?
                                    <>
                                        <div className='spinner-border mr-15' role='status'></div>
                                        <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>updating.....</h4>
                                    </>
                                    : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Save</h4>
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal> : ''}
        </>

    )
}



export default AdminChampLeadStatusActionForm
