
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LocalBaseURL } from '../../../../../BaseURLmanagement'
import { getRequest, postRequest, postRequestWithFile } from '../../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../../consts/Server/URL_EndPoints';
import AdminMultiSelectFilter from '../../../admins/adminForms/adminFormComponents/AdminMultiSelectFilter';


const AddNewFormData = (props: any) => {
    LocalBaseURL()
    const [isLoading, setLoading] = useState<boolean>(false)
    const addAdminUserModalOpen = props?.addAdminUserModalOpen
    const handleClose = props?.handleClose
    const tableRefresh = props?.tableRefresh


    const [payload, setPayload] = useState<any>({
        email: null,
        password: null,
        fname: null,
        lname: null,
        phone: null,
        call_active_status: null,
        customer_cap: null,
        champ_cap: null,
    })

    // to close pop up form
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const response = await postRequestWithFile(URL_EndPoints()?.register, payload, null)
        if (response.status == 200) {
            toast("Admin Details created successfully.")
            tableRefresh();
            handleClose()
        }
    }

    const handleChangeInputData = (e: any, name = null): any => {
        if (name == null) {
            if (e?.target?.name == "status" || e?.target?.name == "offer_duartion") {
                setPayload({
                    ...payload,
                    [e?.target?.name]: e?.target?.checked == true ? 1 : 0

                })
            }
            else {
                setPayload({
                    ...payload,
                    [e?.target?.name]: e?.target?.value,

                })
            }

        } else {
            if (name == "customerids") {
                setPayload({
                    ...payload,
                    [name]: JSON.stringify(e),
                })
            } else {
                setPayload({
                    ...payload,
                    [name]: e?.value,
                })
            }

        }
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload({ ...payload, ["role_id"]: event ? event?.value : event })
    }



    return (
        <Modal size="lg" show={addAdminUserModalOpen} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Add Admin User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading ?
                    <>
                        <Form autoComplete={'off'} onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>First Name </Form.Label>
                                <Form.Control type="text" placeholder="Enter First Name" name="fname" value={payload?.fname || payload?.first_name} onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Last Name </Form.Label>
                                <Form.Control type="text" placeholder="Enter Last Name" name="lname" value={payload?.lname || payload?.last_name} onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label> EMail </Form.Label>
                                <Form.Control type="email" placeholder="Enter EMail" name="email" value={payload?.email} onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password </Form.Label>
                                <Form.Control type="password" placeholder="Enter Password" name="password" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.password} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Phone </Form.Label>
                                <Form.Control type="number" placeholder="Enter Phone Number" name="phone" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.phone} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Customer Cap </Form.Label>
                                <Form.Control type="number" placeholder="Enter Customer Cap Count" name="customer_cap" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.customer_cap} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Champ Cap </Form.Label>
                                <Form.Control type="number" placeholder="Enter Champ Cap Count" name="champ_cap" onChange={(event) => setPayload({ ...payload, [event?.target.name]: event?.target?.value })} value={payload?.champ_cap} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="role_id">
                            <Form.Label>Role </Form.Label>
                                <AdminMultiSelectFilter
                                    onChangeHandler={onChangeHandler}
                                    HeaderTitle="Select Role"
                                    SelectData={props?.rolesData || []}
                                    displayFields={["Role_name"]}
                                    id="id"
                                    name="role_id"
                                    defaultData={null}
                                    searchable={false}
                                    setSeacrch={false}
                                    isLoading={false}
                                    filters={null}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Register
                            </Button>
                        </Form>
                    </> : <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
            </Modal.Body>
        </Modal>

    )
}

export default AddNewFormData


