import React, { useEffect, useState } from "react"
import { Button, ToastContainer } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import SingleSelectSearchCategory from "../../../consts/Select/SingleSelectSearchCategory";
import { URL_EndPoints } from "../../../consts/Server/URL_EndPoints";
import { getRequest, postRequest } from "../../../consts/Server/Requests";
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import moment from "moment";
import SingleSelectSearchCategoryDefault from "../../../consts/Select/SingleSelectSearchCategoryDefault";

const JobActions = ({ props, row }: any) => {
    const [cleaner_id, setCleaner_id] = useState(null)
    const [clAttendanceId, setClAttendanceId] = useState("")
    const [attendance_date, setattendance_date] = useState("")
    const [LeavesReasonsData, setLeavesReasonsData] = React.useState<any>([])
    const [message, setMessage] = React.useState<any>('')
    const [status, setStatus] = React.useState<any>(null)
    const [deductionReasons, setDeductionReasons] = useState<any>([])
    const dispatch: any = useDispatch();
    const [payload, setPayload] = useState<any>({
        deduction_reason_id: 0,
        cleanerid: props.cleaner_id,
        type: "",
        amount: 0,
        cl_attendance_id: props?.cl_attendance_id,
        fine_date: moment().format('YYYY-MM-DD'),
        date: attendance_date,
        is_approved: 1,
        reason_id: 0
    })

    const onClicks = () => {
        setCleaner_id(props?.cleaner_id)
        setattendance_date(props?.attendance_date)
        setClAttendanceId(props?.cl_attendance_id)
    }

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const [absentLeaveModal, setAbsentLeaveModal] = useState(false)

    const handleAbsentLeaveModal = async (status: any) => {
        setStatus(status)
        const getleavereasonData = await getRequest(URL_EndPoints(null)?.getleavereason, null)
        setLeavesReasonsData(getleavereasonData?.data?.result.filter((item: any) => +item.status == +status))
        setAbsentLeaveModal(true)
    }

    const handleAbsentLeaveSubmit = async () => {
        let fPayload: any = {
            status: status,
            reason_id: payload?.reason_id,
            cleaner_id: cleaner_id,
            attendance_date: attendance_date,
            userid: JSON.parse(localStorage.getItem("user") || "0"),
            comment: message,
            deduction_reason_id: payload?.deduction_reason_id,
            type: payload?.type,
            amount: payload?.amount,
            cl_attendance_id: clAttendanceId,
            fine_date: moment().format('YYYY-MM-DD'),
            is_approved: payload?.is_approved
        }

        if (payload?.reason_id > 0 && payload?.is_approved !== undefined && payload?.is_approved !== null) {
            const respond: any = await postRequest(URL_EndPoints()?.markCleanerLeaveAbsent, fPayload, null)

            if (respond?.status == 200) {
                toast.success((status == 0 ? 'Leave' : 'Absent') + ' marked successfully.')
                dispatch({ type: "REFRESH_TABLE", payload: Math.random() })
            }

            setAbsentLeaveModal(false)
        } else {
            toast.error('Please select a reason and approve status!!')
        }
    }

    async function getDeduction() {
        const response = await getRequest(URL_EndPoints()?.getDeductionWrongMarkingReasons + '?cleanerid=' + props?.cleaner_id + '&jobId=' + props?.id, null)
        setDeductionReasons(response?.data?.data)
    }

    useEffect(() => {
        absentLeaveModal && getDeduction()
    }, [absentLeaveModal])

    const handleChangeInput = (event: any, name: any) => {

        if (name == null) {
            setPayload({
                ...payload,
                [event?.target?.name]: event?.target?.value,
            })
        }
        else {
            setPayload({
                ...payload,
                [name]: event?.value,
            })
        }
    }


    const openJobAssign = (row: any) => {
        dispatch({
            type: 'ASSIGN_ABSENT_JOB', payload: {
                assignAbsentJobId: row?.id,
                assignAbsentJobRender: Math.random(),
                show: true,
                row: row,
                from: "",
                date: attendance_date
            }
        })
    }

    const openJobAssignAuto = async (row: any) => {
        try {
            const toastId = toast.loading("Please wait while we are assigning the jobs...");
            const payload = {
              date: attendance_date,
              champId: row?.id,
            };
            const res: any = await postRequest(URL_EndPoints(null).autoReAssignJob, payload, null);
            toast.update(toastId, {
              render: res?.data?.message ?? "Something went wrong",
              type: res?.data?.status === 200 ? 'success' : 'info',
              isLoading: false,
              autoClose: 3000
            });
            dispatch({
                type: 'ASSIGN_ABSENT_JOB', payload: {
                    assignAbsentJobId: row?.id,
                    assignAbsentJobRender: Math.random(),
                    show: true,
                    row: row,
                    from: "",
                    date: attendance_date
                }
            })            
        }catch(error) {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className='primary'>
            <>
                <button
                    className='btn btn-sm btn-light btn-active-primary  fs-8 '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    onClick={() => onClicks()}
                >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>

                <div
                    className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                    style={{
                        width: "500px",
                        zIndex: '105',
                        position: 'fixed',
                        inset: '0px 0px auto auto',
                        margin: '0px',
                        transform: 'translate(-59px, 440px)',
                    }}
                >
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => handleAbsentLeaveModal(0)}
                        >
                            Mark Leave
                        </a>
                    </div>
                    <div className='menu-item px-3'>
                        <a
                            className='menu-link  px-3'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => handleAbsentLeaveModal(1)}
                        >
                            Mark Absent
                        </a>
                    </div>
                    <div className='menu-item px-3'>
                        <a
                            href={`/daily/job/assign/oldjob/${props.cleaner_id}?date=${props.otherData?.date}`}
                            className='menu-link  px-3'
                        >
                            Re-Assign / Temporary
                        </a>
                    </div>
                    <div className='menu-item px-3'>
                        <span
                            // href={`#`}
                            className='menu-link  px-3'
                            onClick={() => openJobAssign(row)}
                            id='kt_drawer_chat_absent_assign_job_toggle3'
                        >
                            Re-Assign / Temporary (New)
                        </span>
                    </div>
                    <div className='menu-item px-3'>
                        <span
                            // href={`#`}
                            className='menu-link  px-3'
                            onClick={() => openJobAssignAuto(row)}
                            id='kt_drawer_chat_absent_assign_job_toggle3'
                        >
                            Re-Assign Temporary (Automatically)
                        </span>
                    </div>
                </div>
                {
                    absentLeaveModal ? <Modal size="lg" show={absentLeaveModal} onHide={() => setAbsentLeaveModal(false)} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Mark Leave/Absent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SingleSelectSearchCategory
                                handleChangeInputData={handleChangeInput}
                                HeaderTitle="Select Reason"
                                SelectData={LeavesReasonsData}
                                DynamicKey={"name"}
                                id={"id"}
                                name="reason_id"
                            ></SingleSelectSearchCategory>
                            <textarea
                                className='form-control form-control-flush mb-3 mt-2'
                                rows={1}
                                data-kt-element='input'
                                placeholder='Type a message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="is_approved" id="inlineCheckbox1" value={1} onChange={(e) => handleChangeInput(e, null)} checked={payload?.is_approved == 1 ? true : false} />
                                <label className="form-check-label" >Approve</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="is_approved" id="inlineCheckbox2" value={0} onChange={(e) => handleChangeInput(e, null)} checked={payload?.is_approved == 0 ? true : false} />
                                <label className="form-check-label" >Deny</label>
                            </div>
                            {
                                payload?.is_approved == 0 ?
                                    <>
                                        <hr />
                                        <h3 style={{ marginBottom: "20px" }}>Fine For Wrong Marking</h3>
                                        <div className="form-group mt-3">
                                            <label htmlFor="id">Select Reason</label>
                                            <SingleSelectSearchCategoryDefault
                                                handleChangeInputData={handleChangeInput}
                                                HeaderTitle="Select Reason"
                                                DynamicKey={"name"}
                                                id={"id"}
                                                name="deduction_reason_id"
                                                SelectData={deductionReasons}
                                                defaultData={null}
                                            ></SingleSelectSearchCategoryDefault>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="type">Method</label>
                                            <select name="type" id="method" className="form-select" onChange={(e: any) => handleChangeInput(e, null)}>
                                                <option value="">Select Method</option>
                                                <option value="point">Point</option>
                                                <option value="cash">Cash</option>
                                            </select>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label htmlFor="amount">Amount</label>
                                            <input type="text" className="form-control" name="amount" value={payload?.amount} onChange={(e: any) => handleChangeInput(e, null)} />
                                        </div>
                                    </>
                                    : ""
                            }
                            <div className="form-group mt-3">
                                <Button variant="primary" onClick={handleAbsentLeaveSubmit}>
                                    Save
                                </Button>
                            </div>
                        </Modal.Body>

                    </Modal> : ''
                }

            </>
        </div>
    )
}
export default JobActions