import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { LocalBaseURL } from '../../../../BaseURLmanagement';
import { URL_EndPoints } from '../../Server/URL_EndPoints';
import MultiSelectSearchDetails from '../../Select/MultiSelectSearchDetails';
import SingleSelectSearchCategory from '../../Select/SingleSelectSearchCategory';
import { getRequest, postRequest } from '../../Server/Requests';
import { ContainerCss } from '../../reuseModules/ReactTableComponent/FormStyleCssSheet';

const CreateNewTickets = ({ handleCloseForm, champId }: any) => {
    LocalBaseURL()
    const userid = JSON.parse(localStorage.getItem("user") || "0")

    const [TicketCategoryData, setTicketCategoryData] = React.useState<any>([])
    const [AdminListData, setAdminListData] = React.useState<any>([])
    const [SupervisorsListData, setSupervisorsListData] = React.useState<any>([])
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)

    useEffect(() => {

        async function invokedFilterAPI() {
            const getChampHelpTicketCategories = await getRequest(URL_EndPoints(null)?.getChampHelpTicketCategories, null)
            setTicketCategoryData(getChampHelpTicketCategories?.data?.data)

            const getSupervisorListData = await getRequest(URL_EndPoints(null)?.getSupervisorList, null)
            setSupervisorsListData(getSupervisorListData?.data?.data)

            const getAdminListData = await getRequest(URL_EndPoints(null)?.getAdminList, null)
            setAdminListData(getAdminListData?.data?.data)

        }
        invokedFilterAPI()

    }, [])


    const [TicketPayloads, setTicketPayloads] = useState({
        title: "",
        champ_id: champId,
        categoryid: 0,
        admins: [],
        answer: "",
        cleaners: [],
        userid: userid
    })


    const handleChangeInputData = (event: any, name: string) => {
        if (name === "admins") {
            setTicketPayloads({ ...TicketPayloads, [name]: event })
        }
        else if (name === "cleaners") {
            setTicketPayloads({ ...TicketPayloads, [name]: event })
        }
        else {
            if (event == null) {
                setTicketPayloads({ ...TicketPayloads, [name]: name === "answer" ? "" : name === "title" ? "" : name === "duedate" ? "" : 0 })
            }
            else {
                const { value } = event
                setTicketPayloads({ ...TicketPayloads, [name]: name === "answer" ? value : name === "title" ? value : name === "duedate" ? value : +value })
            }
        }
    }


    const handleCreateTicketes = async () => {
        setLoader(true)

        const result = await postRequest(URL_EndPoints()?.createChampHelpTicket, TicketPayloads, setLoader)

        if (result?.data?.status == 200) {
            toast.success("Your Ticket Created", result?.data?.data)
            handleCloseForm()
            setDisable(200)

        } else {
            toast.error(result.data?.msg)
            setDisable(200)

        }
    }

    
    return (
        <div className="p-4" style={ContainerCss}>

            <div style={{}} className="d-flex justify-content-between  pt-2">
                <h5 className="ms-4">Create New Ticket </h5>
                <i className="bi bi-x fs-1 text-dark cursor-pointer" onClick={handleCloseForm}></i>
            </div>


            <div className="p-5 mr-auto">
                <div className='modal-content ' >
                    <div className="row mb-2">
                        {/* <hr /> */}
                        <div className="col-12  mb-3">
                            <span className='ms-1 fw-bold'>Title </span>
                            <input
                                placeholder='Title  Here...'
                                type='text'
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(event) => handleChangeInputData(event.target, "title")}
                            />
                        </div>
                        <div className="col-12 mb-2">
                            <span className='ms-1 fw-bold'> Description</span>
                            <div className="form-outline">
                                <textarea className="form-control" name='description' placeholder='Message' id="textAreaExample1" rows={4}
                                    onChange={(event) => handleChangeInputData(event.target, "answer")}
                                ></textarea>
                            </div>
                        </div>
                        {/* <hr /> */}
                    </div>

                    <div className="row mb-2">
                        <div className="col-12  mb- ">
                            <span className='ms-3 fw-bold'>Select Category</span>
                            <SingleSelectSearchCategory
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Category"
                                SelectData={TicketCategoryData}
                                DynamicKey={"category_name"}
                                id={"id"}
                                name="categoryid"
                            ></SingleSelectSearchCategory>
                        </div>
                        <span className='ms-3 fw-bold'> Assign To</span>
                    </div>
                    <div className="row mb-5">
                        <div className="col-6  mb-3">
                            <span className='ms-3 fw-bold'>Select Admin Users</span>
                            <MultiSelectSearchDetails
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select admins"
                                SelectData={AdminListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="admins"
                            ></MultiSelectSearchDetails>
                        </div>
                        <div className="col-6  mb-3">
                            <span className='ms-3 fw-bold'>Select Supervisors</span>
                            <MultiSelectSearchDetails
                                handleChangeInputData={handleChangeInputData}
                                HeaderTitle="Select Supervisor"
                                SelectData={SupervisorsListData}
                                DynamicKey={"first_name"}
                                DynamicKey2={"last_name"}
                                DynamicKey3={"phone"}
                                id={"id"}
                                name="cleaners"
                            ></MultiSelectSearchDetails>
                        </div>
                        {/* <hr /> */}
                    </div>
                </div>

                <div className='modal-footer'>
                    <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={handleCreateTicketes}>
                        {
                            loader ?
                                <>
                                    <div className='spinner-border mr-15' role='status'></div>
                                    <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Creating.....</h4>
                                </>
                                : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Create Ticket</h4>
                        }
                    </button>
                </div>

            </div>
        </div>
    )
}
export default CreateNewTickets
