import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderSpinner from "../admins/LoaderSpinner";
import ImageRender from "./ImageRender";
import { faCheck, faInfo, faInfoCircle, faSign } from "@fortawesome/free-solid-svg-icons";
const Message = ({ message, message_type, sender_user_id, senderUser, userId, showSeenMessage, createdAt, messageStatus, chatFiles, sent_at, formatted_sent_at, loader, you }: any) => {
    const messageType: any = [2, 3, 4, 5]
    return (
        <>
            {
                messageType?.includes(message_type) ? message_type == 5 ? <div className={`d-flex mb-4 justify-content-start align-items-center w-100`}>
                    <div className="d-flex p-2 rounded  gap-3 align-items-center justify-content-center" style={{ backgroundColor: "#fafafa", margin: "auto" }}>
                        <div className="small text-danger ms-2 d-flex justify-content-between align-items-center" style={{ maxWidth: "90%", }}>
                            <span>{formatted_sent_at} - {you ? "You" : sender_user_id == userId ? "You" : senderUser?.name} </span>
                        </div>
                        <div className={`text-danger text-center`}
                        >
                            {message ? message : 'No message'} {loader && <><LoaderSpinner /> </>}

                        </div>
                    </div>
                </div> : <>
                    <div className={`d-flex mb-4 justify-content-start align-items-center w-100`}>
                        <div className="d-flex p-2 rounded  gap-3 align-items-center justify-content-center" style={{ backgroundColor: "#fafafa", margin: "auto" }}>
                            <div className="small text-muted ms-2 d-flex justify-content-between align-items-center" style={{ maxWidth: "90%", }}>
                                <span>{formatted_sent_at} - {you ? "You" : sender_user_id == userId ? "You" : senderUser?.name} </span>
                            </div>
                            <div className={`text-muted text-center`}
                            >
                                {message ? message : 'No message'} {loader && <><LoaderSpinner /> </>}
                            </div>
                        </div>
                    </div>
                </> : <>
                    {
                        chatFiles?.length > 0 ? (
                            <>
                                <div className={`d-flex mb-4 ${sender_user_id == userId ? "justify-content-end" : "justify-content-start"}`}>
                                    <div className="d-flex flex-column w-75 w-md-50">
                                        <div className="small text-muted ms-2 d-flex justify-content-between align-items-center" style={{ maxWidth: "90%", }}>
                                            <span>{formatted_sent_at} - {you ? "You" : sender_user_id == userId ? "You" : senderUser?.name} </span>
                                            {
                                                sender_user_id == userId && <FontAwesomeIcon icon={faInfoCircle} onClick={() => showSeenMessage(messageStatus)} className="text-muted me-2 fs-4 mb-1 cursor-pointer" />
                                            }
                                        </div>
                                        <div className={`p-3 rounded ${sender_user_id == userId ? "bg-light-primary text-primary" : "bg-secondary text-dark"}`} style={{ maxWidth: "90%", }}>
                                            <ImageRender images={chatFiles} userId={userId} sender_user_id={sender_user_id} loader={loader} />
                                            {message && (
                                                <div className="mt-2">
                                                    {message} {loader && <><LoaderSpinner /></>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className={`d-flex mb-4  ${sender_user_id == userId ? "justify-content-end " : "justify-content-start"}`}>
                                <div className="d-flex flex-column w-75 w-md-50 ">
                                    <div className="small text-muted ms-2 d-flex justify-content-between align-items-center" style={{ maxWidth: "90%", }}>
                                        <span>{formatted_sent_at} - {you ? "You" : sender_user_id == userId ? "You" : senderUser?.name} </span>
                                        {
                                            sender_user_id == userId && <FontAwesomeIcon icon={faInfoCircle} onClick={() => showSeenMessage(messageStatus)} className="text-muted me-2 fs-4 mb-1 cursor-pointer" />
                                        }
                                    </div>
                                    <div className={`p-3 rounded ${sender_user_id == userId ? "bg-primary text-white" : "bg-secondary text-dark"}`} style={{ maxWidth: "90%", }}
                                    >
                                        {message ? message : 'No Caption'} {loader && <><LoaderSpinner /></>}
                                    </div>
                                    <span style={{ position: 'relative', display: 'inline-block' }}>
                                        {
                                            loader ? sender_user_id == userId && <FontAwesomeIcon icon={faCheck} className="text-white" style={{ position: 'absolute', right: 40, top: -15 }} /> : sender_user_id == userId && <>
                                                <FontAwesomeIcon icon={faCheck} className="text-white" style={{ position: 'absolute', right: 40, top: -15 }} />
                                                <FontAwesomeIcon icon={faCheck} className="text-white" style={{ position: 'absolute', right: 40, top: -15 }} />
                                            </>
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </>
            }


        </>
    );
};

export default Message;