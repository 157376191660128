import {
  faCancel,
  faClose,
  faFilePdf,
  faImage,
  faPlusCircle,
  faSmile,
  faUpload,
  faVideo,
  faMicrophone,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useRef, useState} from 'react'
import {postRequest} from '../../consts/Server/Requests'
import {URL_EndPoints} from '../../consts/Server/URL_EndPoints'
import {toast} from 'react-toastify'
import { adminMenuActionPermissionKeys } from '../admins/adminConstant'
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider'

const ChatInput = ({setSelectedChatMap, selectedChat}: any) => {
  const [showUpload, setShowUpload] = useState(false)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [previewUrl, setPreviewUrl] = useState<any>(null)
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null)
  const inputRef = useRef<any>(null)
  const [message, setMessage] = useState('')
  const [uploading, setUploading] = useState<any>(false)
  const closePrevIamge = () => {
    setPreviewUrl(null)
    setSelectedImage(null)
  }
  const handleImageClick = () => {
    inputRef.current?.click()
  }

  const handleImageChange = (e: any) => {
    const file: any = e.target.files[0]
    if (file) {
      setSelectedImage([file])
      setPreviewUrl(URL.createObjectURL(file))
    }
  }

  const handleImageUpload = async () => {
    // if (!message) {
    //   toast.error('Caption message is required', {position: 'top-center'})
    //   return
    // }
    if (!selectedImage) {
      alert('Please select an image')
    }
    setUploading(true)
    const formData = new FormData()
    formData.append('file', selectedImage[0])

    try {
      const response = await fetch('https://api.carselonadaily.com/api/admin/uploadFile', {
        method: 'POST',
        headers: {
          Authorization: `abcdefghijklmnopqrstuvwxyz`,
        },
        body: formData,
      })

      const data = await response.json()

      if (response.ok && data?.data?.image) {
        setSelectedChatMap((prevMessage: any) => {
          const existingMessages = prevMessage[selectedChat?.id] || []
          return {
            ...prevMessage,
            [selectedChat?.id]: [
              ...existingMessages,
              {
                id: 0,
                sender_user_id: Number(localStorage.getItem('user')),
                sender_user_type: 5,
                message: message,
                sent_at: new Date(),
                formatted_sent_at: 'Just now',
                group_id: selectedChat?.id,
                message_type: 1,
                createdAt: new Date(),
                updatedAt: new Date(),
                messageStatus: [],
                loader: true,
                you: true,
                chatFiles: [
                  {
                    file_path: 'Carselona loader',
                  },
                ],
              },
            ],
          }
        })
        const result = await postRequest(
          URL_EndPoints()?.sendMessageToGroup,
          {files: [{filePath: data?.data?.image}], message: message, groupId: selectedChat?.id},
          null
        )

        if (result?.data?.status == 200) {
          setSelectedChatMap((prevMessage: any) => {
            let existingMessages = prevMessage[selectedChat?.id] || []
            let newMessage = existingMessages[existingMessages.length - 1]
            newMessage.loader = false
            newMessage.chatFiles = [
              {
                file_path: data?.data?.image,
                file_type: result?.data?.data?.chatFiles?.[0]?.file_type,
              },
            ]
            existingMessages[existingMessages.length - 1] = newMessage
            return {
              ...prevMessage,
              [selectedChat?.id]: existingMessages,
            }
          })
          setPreviewUrl(false)
          setShowUpload(false)
          setMessage('')
          setUploadedImageUrl(null)
          setSelectedImage(null)
        }
      }
      setUploading(false)
    } catch (error: any) {
      console.log('error', error?.message)
      setUploading(false)
      setPreviewUrl(false)
      setUploadedImageUrl(null)
      setSelectedImage(null)
    }
  }

  const handleSend = async () => {
    setSelectedChatMap((prevMessage: any) => {
      const existingMessages = prevMessage[selectedChat?.id] || []
      return {
        ...prevMessage,
        [selectedChat?.id]: [
          ...existingMessages,
          {
            id: 0,
            sender_user_id: Number(localStorage.getItem('user')),
            sender_user_type: 5,
            message: message,
            formatted_sent_at: 'Just now',
            group_id: selectedChat?.id,
            message_type: 1,
            createdAt: new Date(),
            updatedAt: new Date(),
            messageStatus: [],
            loader: true,
            you: true,
          },
        ],
      }
    })
    const result = await postRequest(
      URL_EndPoints()?.sendMessageToGroup,
      {files: [], message: message, groupId: selectedChat?.id},
      null
    )
    if (result?.data?.status == 200) {
      setSelectedChatMap((prevMessage: any) => {
        let existingMessages = prevMessage[selectedChat?.id] || []
        let newMessage = existingMessages[existingMessages.length - 1]
        newMessage.loader = false
        existingMessages[existingMessages.length - 1] = newMessage
        return {
          ...prevMessage,
          [selectedChat?.id]: existingMessages,
        }
      })
      setMessage('')
      return
    }
    setPreviewUrl(false)
    setMessage('')
  }

  // Add these new states inside the ChatInput component
  const [isRecording, setIsRecording] = useState(false)
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [audioChunks, setAudioChunks] = useState<Blob[]>([])
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [sendingVoiceMessage, setSendingVoiceMessage] = useState(false);

  // Add these new functions inside the ChatInput component
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);

      const recorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm'
      });
      setMediaRecorder(recorder);

      const chunks: Blob[] = [];
      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
        setAudioChunks(prevChunks => [...prevChunks, e.data]);
      };

      recorder.start(1000);
      setIsRecording(true);
      setAudioChunks([]);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      toast.error('Could not access microphone');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && audioStream) {
      mediaRecorder.stop();
      audioStream.getTracks().forEach((track) => track.stop());
      
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        setIsRecording(false);
        setMediaRecorder(null);
        setAudioStream(null);
      };
    }
  };

  // Add a function to cancel recording preview
  const cancelAudioPreview = () => {
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl); // Clean up the URL
    }
    setAudioUrl(null);
    setAudioChunks([]);
  };

  const handleSendVoiceMessage = async () => {
    setSendingVoiceMessage(true);
    const audioBlob = new Blob(audioChunks, {type: 'audio/webm'})
    const formData = new FormData()
    formData.append('file', audioBlob, 'audio.webm')

    try {
      const response = await fetch('https://api.carselonadaily.com/api/admin/uploadFile', {
        method: 'POST',
        headers: {
          Authorization: `abcdefghijklmnopqrstuvwxyz`,
        },
        body: formData,
      })

      const data = await response.json()
      if (response.ok && data?.data?.image) {
        setSendingVoiceMessage(false);
        setSelectedChatMap((prevMessage: any) => {
          const existingMessages = prevMessage[selectedChat?.id] || []
          return {
            ...prevMessage,
            [selectedChat?.id]: [
              ...existingMessages,
              {
                id: 0,
                sender_user_id: Number(localStorage.getItem('user')),
                sender_user_type: 5,
                message: '',
                sent_at: new Date(),
                formatted_sent_at: 'Just now',
                group_id: selectedChat?.id,
                message_type: 1, // Audio type
                createdAt: new Date(),
                updatedAt: new Date(),
                messageStatus: [],
                loader: true,
                you: true,
                chatFiles: [
                  {
                    file_path: 'Carselona loader',
                  },
                ],
              },
            ],
          }
        })

        const result = await postRequest(
          URL_EndPoints()?.sendMessageToGroup,
          {
            files: [{filePath: data?.data?.image}],
            message: '',
            groupId: selectedChat?.id,
            message_type: 2,
          },
          null
        )

        if (result?.data?.status == 200) {
          setSelectedChatMap((prevMessage: any) => {
            let existingMessages = prevMessage[selectedChat?.id] || []
            let newMessage = existingMessages[existingMessages.length - 1]
            newMessage.loader = false
            newMessage.chatFiles = [
              {
                file_path: result?.data?.data?.chatFiles?.[0]?.file_path,
                file_type: result?.data?.data?.chatFiles?.[0]?.file_type,
              },
            ]
            existingMessages[existingMessages.length - 1] = newMessage
            return {
              ...prevMessage,
              [selectedChat?.id]: existingMessages,
            }
          })

          // Clean up after successful send
          if (audioUrl) {
            URL.revokeObjectURL(audioUrl)
          }
          setAudioUrl(null)
          setAudioChunks([])
        }
      }
    } catch (error) {
      console.error('Error uploading audio:', error)
      toast.error('Failed to send voice message')
    }
  }

  // {
  //     "message": "Message from Moin's side",
  //     "groupId": 1
  // }
  return (
    <div
      className='p-3 d-flex align-items-center bg-white'
      style={{position: 'sticky', bottom: 0, width: '100%'}}
    >
      {showUpload && (
        <div
          className='position-absolute d-flex justify-content-center align-items-center shadow'
          style={{
            bottom: '60px',
            left: '40%',
            right: '50%',
            borderRadius: '10px',
            border: 'none',
            height: '100%',
            backgroundColor: 'white',
            zIndex: 20,
            width: '200px',
          }}
        >
          <div
            style={{width: '50px', height: '50px'}}
            className='d-flex justify-content-center align-items-center me-3 rounded-pill bg-secondary'
            onClick={handleImageClick}
          >
            <FontAwesomeIcon
              icon={faImage}
              className='text-primary'
              style={{fontSize: '30px', cursor: 'pointer'}}
            />
            <input
              type='file'
              accept='image/*'
              ref={inputRef}
              onChange={handleImageChange}
              style={{display: 'none'}}
            />
          </div>

          <div
            style={{width: '50px', height: '50px'}}
            className='d-flex justify-content-center align-items-center me-3 rounded-pill bg-secondary'
          >
            <FontAwesomeIcon
              icon={faVideo}
              className='text-success'
              style={{fontSize: '30px', cursor: 'pointer'}}
            />
          </div>

          <div
            style={{width: '50px', height: '50px'}}
            className='d-flex justify-content-center align-items-center me-3 rounded-pill bg-secondary'
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              className='text-danger'
              style={{fontSize: '30px', cursor: 'pointer'}}
            />
          </div>
        </div>
      )}

      {previewUrl && (
        <div
          className='position-absolute bg-white shadow p-2 rounded'
          style={{
            bottom: '55px',
            left: '40%',
            transform: 'translateX(-50%)',
            zIndex: 30,
            textAlign: 'center',
            width: '300px',
          }}
        >
          <div className='d-flex justify-content-end align-items-center'>
            <FontAwesomeIcon
              icon={faClose}
              className='text-primary me-2'
              style={{fontSize: '30px', cursor: 'pointer'}}
              onClick={closePrevIamge}
            />
          </div>
          <img
            src={previewUrl}
            alt='Preview'
            className='img-fluid rounded mb-2'
            style={{maxHeight: '300px', objectFit: 'cover'}}
          />
          <input
            type='text'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className='form-control mt-3 mb-3'
            placeholder='Type a message...'
            style={{paddingRight: '60px'}}
          />
          <button
            className='btn btn-sm btn-primary w-100'
            onClick={handleImageUpload}
            disabled={uploading}
          >
            {uploading ? 'Sending...' : 'Send'}
          </button>
        </div>
      )}

      {audioUrl && (
        <div
          className="position-absolute bg-white shadow p-2 rounded"
          style={{
            bottom: '55px',
            left: '40%',
            transform: 'translateX(-50%)',
            zIndex: 30,
            textAlign: 'center',
            width: '300px',
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            <FontAwesomeIcon
              icon={faClose}
              className="text-primary me-2"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => {
                if (audioUrl) {
                  URL.revokeObjectURL(audioUrl);
                }
                setAudioUrl(null);
                setAudioChunks([]);
              }}
            />
          </div>
          <audio 
            src={audioUrl} 
            controls 
            className="w-100 mb-2"
            preload="metadata"
          />
          <button
            className="btn btn-sm btn-primary w-100"
            onClick={handleSendVoiceMessage}
            disabled={sendingVoiceMessage}
          >
            {sendingVoiceMessage ? 'Sending...' : 'Send Voice Message'}
          </button>
        </div>
      )}

    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["chat-send-message"]}>
        <FontAwesomeIcon
            icon={faPlusCircle}
            className='me-2'
            style={{color: '#98a4a5', fontSize: '30px', cursor: 'pointer'}}
            onClick={() => setShowUpload(!showUpload)}
        />
        <FontAwesomeIcon
            icon={faSmile}
            className='me-2'
            style={{color: '#98a4a5', fontSize: '30px', cursor: 'pointer'}}
        />

      <div className='input-group position-relative' style={{width: '97%'}}>
        <input
          type='text'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className='form-control rounded-pill'
          placeholder='Type a message...'
          style={{paddingRight: '60px'}}
        />
        <button
          className="btn position-absolute end-0 me-2"
          style={{
            borderTopLeftRadius: "50px",
            borderBottomLeftRadius: "50px",
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
            zIndex: 99
          }}
          onClick={message.trim() ? handleSend : isRecording ? stopRecording : startRecording}
        >
          {message.trim() ? (
            "Send"
          ) : isRecording ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="text-danger"
              spin
              style={{ fontSize: "20px" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faMicrophone}
              style={{ fontSize: "20px" }}
            />
          )}
        </button>
      </div>

    </PermissionContextProvider>
    </div>
  )
}

export default ChatInput
