import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../reuseModules/ReactTableComponent/FormStyleCssSheet'
const SingleSelectSearchCategory = ({ handleChangeInputData, HeaderTitle, SelectData, DynamicKey, id, name, disableValue, isLoading }: any) => {

  const [showData, setShowData] = React.useState<any>("")
  React.useEffect(() => {
    const updatedData = SelectData?.map((ele: any, i: number) => {
      if (ele[DynamicKey] != null || ele[DynamicKey]) {
        let newValue = ele["contact_count"] ? ` - ( ${ele["contact_count"]} )` : ""
        const newData = {
          label: ele[DynamicKey] ? ele[DynamicKey] + newValue : "NA",
          value: ele[id],
        }
        return newData
      }
    })
    setShowData(updatedData)
  }, [SelectData])



  return (
    <div className='mb-2'>
      <Select
        placeholder={HeaderTitle}
        isClearable
        isSearchable
        options={showData}
        onChange={(event) => handleChangeInputData(event, name)}
        defaultValue={showData}
        styles={colourStyles2}
        isDisabled={disableValue && disableValue}
        isLoading={isLoading}
      />
    </div>
  )
}
export default SingleSelectSearchCategory
