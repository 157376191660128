import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faCalendarCheck,
    faMapMarkerAlt,
    faHome,
    faExchangeAlt,
    faShieldAlt,
    faSignInAlt,
    faInfoCircle,
    faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";
import { postRequest } from "../Server/Requests";
import { URL_EndPoints } from "../Server/URL_EndPoints";
import moment from "moment";
import IsLoading from "../IsLoading/IsLoading";
const groupEventsByTime = (data: any[], timeDifference: any) => {
    data.sort((a, b) => a?.event_time - b?.event_time);
    let groupedEvents: any = {};
    let eventCount = 1;
    let currentGroup: any[] = [];
    let startTime = new Date(data[0].event_time);

    data.forEach((event) => {
        let eventTime = new Date(event.event_time);
        if (eventTime <= new Date(startTime.getTime() + timeDifference)) {
            currentGroup.push(event);
        } else {
            groupedEvents[`event${eventCount}`] = [...currentGroup];
            eventCount++;
            currentGroup = [event];
            startTime = eventTime;
        }
    });
    if (currentGroup.length) {
        groupedEvents[`event${eventCount}`] = [...currentGroup];
    }
    return groupedEvents;
};

const ChampActivityBoard = ({ cleanerId }: any) => {

    let iconsName: any = {
        JOURNEY_COMPLETED: faCheckCircle,
        SELECTED_TRAINING_DATE: faCalendarCheck,
        SELECTED_TRAINING_LOCATION_VACANCY: faMapMarkerAlt,
        GO_TO_EXISTING_ADDRESS: faHome,
        CHANGE_TRAINING_SHIFT: faExchangeAlt,
        CLICKED_OTP_VERIFY: faShieldAlt,
        CLICKED_LOGIN: faSignInAlt,
    }
    const [groupedData, setGroupedData] = useState<any>({});
    const [eventData, setEventData] = useState<any>([])
    const [lastStatus, setLastStatus] = useState<any>(null)
    const [journeyMaxTime, setJourneyMaxTime] = useState<any>(0)
    const [loader, setLoader] = useState<any>(false)

    useEffect(() => {

        const getChamp = async () => {
            setLoader(true)
            const response = await postRequest(URL_EndPoints()?.getChampLinkEventDetail, { champId: cleanerId }, null)
            setJourneyMaxTime(response?.data?.data?.journey_max_time_ms)
            setEventData(response?.data?.data?.eventDetail)
            setLastStatus(response?.data?.data?.latestEvent)
            setLoader(false)
        }

        getChamp()

    }, [])


    useEffect(() => {
        if (eventData?.length > 0) {
            const grouped = groupEventsByTime(eventData, journeyMaxTime);
            setGroupedData(grouped);
        }

    }, [eventData, journeyMaxTime]);

    function formatUnderscoreString(str: any) {
        return str
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/^\w/, (c: any) => c.toUpperCase());
    }
    const keysValue: any[] = Object.keys(groupedData || {});
    return (
        <>

            {
                lastStatus && <div className="ms-4" style={{ maxWidth: "350px" }}>
                    <div className="d-flex align-items-start mb-8 mt-4 shadow ms-6 bg-primary" style={{ maxWidth: "300px" }}>
                        <div className="me-3 fs-1 d-flex flex-column align-items-center" style={{ zIndex: 1 }}>
                            <div className="bg-transparent"> <FontAwesomeIcon icon={faInfoCircle} className=" fs-1" style={{ color: "white" }} /></div>
                        </div>
                        <div>
                            <small className="text-white mb-2">{moment(lastStatus?.event_time).format("DD-MM-YYYY hh:mm:ss")}</small>
                            <h6 className="mb-1 mt-2">{formatUnderscoreString(lastStatus?.event?.name)}</h6>
                        </div>
                    </div>
                </div>
            }

            <div className="container mt-4"
                style={{
                    maxWidth: "900px",
                    overflowX: "auto",
                    whiteSpace: "nowrap"
                }}>
                {
                    loader ? <IsLoading /> :
                        <div className="position-relative" style={{ minWidth: "max-content" }}>
                            {keysValue?.map((event: any, index: number) => {
                                const firstEvent = groupedData[event][0];
                                return (
                                    <div key={index} className="d-flex align-items-start mb-4 position-relative" style={{ flexShrink: 0 }}>
                                        {index !== keysValue?.length - 1 && (
                                            <div className="position-absolute"
                                                style={{ left: "20px", top: "20px", width: "2px", height: "calc(100% + 10px)", backgroundColor: "#ddd" }}>
                                            </div>
                                        )}
                                        <div className="me-3 fs-1 position-relative d-flex flex-column align-items-center" style={{ zIndex: 1 }}>
                                            <div className="bg-white">
                                                <FontAwesomeIcon
                                                    icon={iconsName[firstEvent?.event?.name] ? iconsName[firstEvent?.event?.name] : faInfoCircle}
                                                    className=" fs-1" style={{ color: "#5e627c" }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-muted">{moment(firstEvent?.event_time).format("DD-MM-YYYY hh:mm:ss")}</small>
                                            <h6 className="mb-1">{formatUnderscoreString(firstEvent?.event?.name)}</h6>
                                        </div>
                                        {groupedData[event]?.length > 1 &&
                                            groupedData[event]?.map((ele: any, index2: number) => {
                                                return index2 !== 0 && (
                                                    <div key={index2} className="d-flex justify-content-start align-items-center ms-4" style={{ flexShrink: 0 }}>
                                                        <FontAwesomeIcon icon={faLongArrowAltRight} className="me-2 mt-5" />
                                                        <div>
                                                            <small className="text-muted">{moment(ele?.event_time).format("DD-MM-YYYY hh:mm:ss")}</small>
                                                            <h6 className="mb-1">{formatUnderscoreString(ele?.event?.name)}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                );
                            })}
                            
                        </div>
                }
            </div>



        </>
    );
};

export default ChampActivityBoard;



// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faCheckCircle,    // Journey Completed
//     faCalendarCheck,  // Selected Training Date
//     faMapMarkerAlt,   // Selected Training Location Vacancy
//     faHome,           // Go to Existing Address
//     faExchangeAlt,    // Change Training Shift
//     faShieldAlt,      // Clicked OTP Verify
//     faSignInAlt,       // Clicked Login
//     faInfoCircle
// } from "@fortawesome/free-solid-svg-icons";
// import { postRequest } from "../Server/Requests";
// import { URL_EndPoints } from "../Server/URL_EndPoints";
// import moment from "moment";
// import IsLoading from "../IsLoading/IsLoading";

// const ChampActivityBoard = ({ cleanerId }: any) => {

//     let iconsName: any = {
//         JOURNEY_COMPLETED: faCheckCircle,
//         SELECTED_TRAINING_DATE: faCalendarCheck,
//         SELECTED_TRAINING_LOCATION_VACANCY: faMapMarkerAlt,
//         GO_TO_EXISTING_ADDRESS: faHome,
//         CHANGE_TRAINING_SHIFT: faExchangeAlt,
//         CLICKED_OTP_VERIFY: faShieldAlt,
//         CLICKED_LOGIN: faSignInAlt,
//     }
//     const [champData, setChampData] = useState<any>([])
//     const [lastStatus, setLastStatus] = useState<any>(null)
//     const [loader, setLoader] = useState<any>(false)

//     useEffect(() => {

//         const getChamp = async () => {
//             setLoader(true)
//             const response = await postRequest(URL_EndPoints()?.getChampLinkEventDetail, { champId: cleanerId }, null)
//             setChampData(response?.data?.data?.eventDetail)
//             setLastStatus(response?.data?.data?.latestEvent)
//             setLoader(false)
//         }

//         getChamp()

//     }, [])
//     function formatUnderscoreString(str: any) {
//         return str
//             .toLowerCase()
//             .replace(/_/g, ' ')
//             .replace(/^\w/, (c: any) => c.toUpperCase());
//     }

//     return (
//         <>
//             {
//                 lastStatus && <div className="ms-4" style={{ maxWidth: "350px" }}>
//                     <div className="d-flex align-items-start mb-8 mt-4 shadow ms-6 bg-primary" style={{ maxWidth: "300px" }}>
//                         <div className="me-3 fs-1 d-flex flex-column align-items-center" style={{ zIndex: 1 }}>
//                             <div className="bg-transparent"> <FontAwesomeIcon icon={faInfoCircle} className=" fs-1" style={{ color: "white" }} /></div>
//                         </div>
//                         <div>
//                             <small className="text-white mb-2">{moment(lastStatus?.event_time).format("DD-MM-YYYY hh:ss")}</small>
//                             <h6 className="mb-1 mt-2">{formatUnderscoreString(lastStatus?.event?.name)}</h6>
//                         </div>
//                     </div>
//                 </div>
//             }

//             <div className="container mt-4">
//                 {
//                     loader ? <IsLoading /> : <div className="position-relative">
//                         {champData?.map((event: any, index: number) => (
//                             <div key={index} className="d-flex align-items-start mb-4 position-relative">
//                                 {index !== champData?.length - 1 && (
//                                     <div className="position-absolute" style={{ left: "20px", top: "20px", width: "2px", height: "calc(100% + 10px)", backgroundColor: "#ddd" }}></div>
//                                 )}
//                                 <div className="me-3 fs-1 position-relative d-flex flex-column align-items-center" style={{ zIndex: 1 }}>
//                                     <div className="bg-white"> <FontAwesomeIcon icon={iconsName[event?.event?.name] ? iconsName[event?.event?.name] : faInfoCircle} className=" fs-1" style={{ color: "#5e627c" }} /></div>
//                                 </div>
//                                 <div>
//                                     <small className="text-muted">{moment(event?.event_time).format("DD-MM-YYYY hh:ss")}</small>
//                                     <h6 className="mb-1">{formatUnderscoreString(event?.event?.name)}</h6>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 }


//             </div>


//         </>
//     );
// };

// export default ChampActivityBoard;