const initialState = {
  userAllowedPermissionList: [],
  noticationCredential: 'prospect',
  liveChatMessage: {
    message: '',
    render: null,
  },
}

export const adminReducer = (state = initialState, {type, payload}: any) => {
  switch (type) {
    case 'STORE_USER_ALLOWED_PERMISSION_LIST':
      return {
        ...state,
        userAllowedPermissionList: payload,
      }
    case 'LIVE_CHAT_MESSAGE':
      return {
        ...state,
        liveChatMessage: payload,
      }
    default:
      return state
  }
}

// const userAllowedPermissionList = useSelector((store:any)=>store.adminReducer.userAllowedPermissionList)
// const noticationCredential = useSelector((store:any)=>store.adminReducer.noticationCredential)
