import React from 'react'
import Select from 'react-select'
import { colourStyles2 } from '../../../../consts/reuseModules/ReactTableComponent/FormStyleCssSheet'
const AdminSelectCustom = (props: any) => {
  const {
    HeaderTitle,
    onChangeHandler,
    selectData,
    displayFields,
    id,
    name,
    defaultData,
    searchable,
    setSeacrch,
    isLoading = false,
    filters,
    isMulti,
    isDisabled = false
  } = props

  const [showData, setShowData] = React.useState<any>([])
  React.useEffect(() => {
    const updatedData = selectData?.map((ele: any, i: number) => {
      const newData = {
        label: displayFields?.map((key: any) => !ele[key] ? "NA" : ele[key]).join(" - ") ,
        value: ele[id],
        item: ele
      }
      return newData
    })
    setShowData(updatedData)
  }, [selectData])

  const debouncing = (event: any) => {
    setSeacrch(event, filters)
  }

  return (
    <div className='mb-2'>
      <Select
        placeholder={HeaderTitle}
        isMulti={isMulti}
        isClearable
        isSearchable
        onInputChange={setSeacrch && searchable ? (e: any) => debouncing(e) : undefined}
        options={showData}
        onChange={(event) => onChangeHandler && onChangeHandler({ item: event, value: isMulti == true ? event?.map((ele: any) => ele?.value) : event?.value }, name, false)}
        defaultValue={defaultData && defaultData?.item}
        styles={colourStyles2}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </div>
  )
}


export default AdminSelectCustom


//  ✅ copy and paste this logic and dont make any change but you just need to make change how many filter you like champ, custoemr, area manager in 👉 storeFilterListItem 

// const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
// const [storeSearchKeywords, setStoreSearchKeywords] = useState<any>("")
// const [dynFilter, setDynFilter] = useState<any>("")
// const [storeFilterListItem, setStoreFilterListItem] = useState<any>({
//   customerListData: []
// })

// const setSearchFilter = (event: any, filters: any) => {
//   setDynFilter(filters)
//   setStoreSearchKeywords(event)
// }

// useEffect(() => {
//   let x = setTimeout(async () => {
//     setIsLoadingFilter(true)
//     await getFilterDataBySearchClientSide(dynFilter, setStoreFilterListItem, storeFilterListItem, setIsLoadingFilter, { keyword: storeSearchKeywords })
//     setIsLoadingFilter(false)
//   }, 2000)

//   return () => {
//     setIsLoadingFilter(false)
//     clearTimeout(x)
//   }
// }, [storeSearchKeywords, dynFilter])



//  ✅ to pass props 

// onChangeHandler: handleChangeInputData,
// setSearchFilter: setSearchFilter,
// isLoadingFilter: isLoadingFilter,
// storeFilterListItem: storeFilterListItem,


// ✅ use where need dropdown and also below code you make more dyanmice as per your requiement 

{/* <AdminSelectSearchable
HeaderTitle='Select Customer'
onChangeHandler={onChangeHandler}
selectData={storeFilterListItem["customerListData"]}
displayFields={["first_name", "last_name", "phone"]}
id="id"
name="customerid"
defaultData={null}
searchable={true}
setSeacrch={setSearchFilter}
isLoading={isLoadingFilter}
filters={{
  reqApi: "getCustomersList",
  storeKeyName: "customerListData",
}}
isMulti={false}
/> */}


// ✅✅✅✅✅✅✅✅✅ use these two api for this form

// 👉  getChampsList
// 👉  getCustomersList

