import React from 'react'
import { champColorStatus } from '../adminConstant'

const AdminActiveOrInactive = ({ title, isActive }: any) => {
    return (
        <span className={`badge badge-${champColorStatus[isActive] ? champColorStatus[isActive] : champColorStatus["normal"]} me-2`}>{title}</span>
    )
}

export default AdminActiveOrInactive
