import axios from 'axios'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { KTCardBody } from '../../../_metronic/helpers'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import VisitorListChampMainV2 from './VisitorListChampMainV2'
import { setStoreLastStatusListData } from '../../../Redux/actions/cxAction'
import { catchCurrentOpenedUrl } from '../../consts/components/Utility'
import SkeletonLoader from '../../consts/IsLoading/SkeletonLoader'
const VisitorListChampV2 = ({ filterDataOutsource, setFilteredIdForChat = null, apiPending = false }: any) => {
    const dispatch = useDispatch()
    const getData = async () => {
        const champVisitorCategoryId = 7;

        const queryParam = {
            To: URL_EndPoints(null)?.getMasterReasonForVisitors,
            What: "?categoryId=" + champVisitorCategoryId
        };

        const response = getRequest(URL_EndPoints(queryParam)?.getMasterReasonForVisitors, null)
        const ticketStatuses = getRequest(URL_EndPoints(null)?.getTicketStatuses, null)
        const ticketSources = getRequest(URL_EndPoints(null)?.getTicketSources, null)
        const adminList = getRequest(URL_EndPoints(null)?.getAdminList + "?all=1", null)
        const supervisorList = getRequest(URL_EndPoints(null)?.getSupervisorList, null)
        const activeChamps = getRequest(URL_EndPoints(null)?.getActiveChampList, null)
        const trainingLocations = getRequest(URL_EndPoints(null)?.getChampRequirementWithChampCount, null)
        const subReasons = getRequest(URL_EndPoints({
            To: URL_EndPoints(null)?.getMasterSubReason,
            What: "?categoryId=1"
        })?.getMasterSubReason, null)

        const result: any[] = await Promise.allSettled([
            response,
            ticketStatuses,
            ticketSources,
            adminList,
            supervisorList,
            activeChamps,
            trainingLocations,
            subReasons
        ]);
        dispatch(setStoreLastStatusListData({
            keyName: catchCurrentOpenedUrl(),
            data: result[0]?.value?.data?.data
        }) as any)

        dispatch({ type: "ADD_STATUSES", payload: result[0]?.value?.data?.data })
        dispatch({ type: "ADD_TICKET_STATUSES", payload: result[1]?.value?.data?.data })
        dispatch({ type: "ADD_TICKET_SOURCES", payload: result[2]?.value?.data?.data })
        dispatch({ type: "ADD_ADMIN_LIST", payload: result[3]?.value?.data?.data })
        dispatch({ type: "ADD_SUPERVISOR_LIST", payload: result[4]?.value?.data?.data })
        dispatch({ type: "ADD_ACTIVE_CHAMP_LIST", payload: result[5]?.value?.data?.data })
        dispatch({ type: "ADD_TRAINING_LOCATIONS", payload: result[6]?.value?.data?.data })
        dispatch({ type: "ADD_SUB_REASONS", payload: result[7]?.value?.data?.data })

    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <KTCardBody className='card py-4'>
            {
                // apiPending ? <SkeletonLoader rowCount={10} title="Champ Leads" /> :
                    <VisitorListChampMainV2 filterDataOutsource={filterDataOutsource} setFilteredIdForChat={setFilteredIdForChat} ></VisitorListChampMainV2>
            }
        </KTCardBody>
    )
}
export default VisitorListChampV2
