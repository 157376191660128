import React, { useState } from "react";
import ChatListItem from "./ChatListItem";
import SkeletonLoader from "../../consts/IsLoading/SkeletonLoader";
import AddNewGroup from "./AddNewGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { adminMenuActionPermissionKeys } from "../admins/adminConstant";
import PermissionContextProvider from "../admins/adminComponents/PermissionContextProvider";
import { getName } from "../admins/adminUtils";

const Sidebar = ({ onSelectChat, selectedChat, pending, chats, handleScrollling, search, setSearch, getChats, otherMembers, refss, page, recordsTotal, paginationLoader, areaManagerList }: any) => {
    
    const [selectedFilter, setSelectedFilter] = useState("All")
    const [selectedAreaManager, setSelectedAreaManager] = useState<any>(0)
    const [filterLoading, setFilterLoading] = useState(false)
    const [filterData, setFilterData] = useState<any>({})

    const [show, setShow] = useState(false);
    const loadMoreHandler = () => {
        const nextPage = page + 1;
        getChats(nextPage, "", "", true, filterData)
    }

    const handleFilter = async (filter: string, value?: string) => {
        setFilterLoading(true)
        let filterData = {}
        if (filter === "All") {
            filterData = {}
        }else if(filter === "Groups"){
            filterData = {
                is_group: {value: true, operator: "=="}
            }
        }else if(filter === "Direct Messages"){
            filterData = {
                is_group: {value: false, operator: "=="}
            }
        }else if(filter === "Champs"){
            filterData = {
                is_champs: {value: true, operator: "=="}
            }
        }else if(filter === "Broadcast Channels"){
            filterData = {
                group_type: {value: 3, operator: "=="}
            }
        }
        
        if(filter === "Area Managers"){
            filterData = {
                ...filterData,
                area_manager_id: {value: value, operator: "=="}
            }
            setSelectedAreaManager(value)
        }else {
            setSelectedFilter(filter)
            if(selectedAreaManager > 0){
                filterData = {
                    ...filterData,
                    area_manager_id: {value: selectedAreaManager, operator: "=="}
                }
            }            
        }
        setFilterData(filterData)
        await getChats(1, "", true, false, filterData)
        setFilterLoading(false)
    }

    return (
        <div
            className="sidebar border-end p-3 bg-white"
        >
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Chats</h5>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["chat-create-group"]}>
                    <div className="d-flex align-items-center" onClick={() => setShow(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} style={{ color: "#98a4a5", fontSize: "30px", cursor: "pointer" }} />
                    </div>
                </PermissionContextProvider>
            </div>
            <input type="text" id="search" className="form-control rounded-pill my-2" value={search} placeholder="Search" onChange={(event: any) => setSearch(event?.target?.value)} />
            <div className="d-flex flex-column flex-wrap gap-2 mb-3">
                <div>
                    <div className={`chip btn btn-sm btn-light-primary ${selectedFilter === "All" ? "active" : ""} ${filterLoading ? "disabled" : ""}`} onClick={() => handleFilter("All")}>
                        {selectedFilter === "All" && filterLoading ? "All..." : "All"}
                    </div>
                    <div className={`chip btn btn-sm btn-light-primary ${selectedFilter === "Groups" ? "active" : ""} ${filterLoading ? "disabled" : ""}`} onClick={() => handleFilter("Groups")}>
                        {selectedFilter === "Groups" && filterLoading ? "Groups..." : "Groups"}
                    </div>
                    <div className={`chip btn btn-sm btn-light-primary ${selectedFilter === "Direct Messages" ? "active" : ""} ${filterLoading ? "disabled" : ""}`} onClick={() => handleFilter("Direct Messages")}>
                        {selectedFilter === "Direct Messages" && filterLoading ? "Direct Messages..." : "Direct Messages"}
                    </div>
                    <div className={`chip btn btn-sm btn-light-primary ${selectedFilter === "Champs" ? "active" : ""} ${filterLoading ? "disabled" : ""}`} onClick={() => handleFilter("Champs")}>
                        {selectedFilter === "Champs" && filterLoading ? "Champs..." : "Champs"}
                    </div>
                    <div className={`chip btn btn-sm btn-light-primary ${selectedFilter === "Broadcast Channels" ? "active" : ""} ${filterLoading ? "disabled" : ""}`} onClick={() => handleFilter("Broadcast Channels")}>
                        {selectedFilter === "Broadcast Channels" && filterLoading ? "Broadcast Channels..." : "Broadcast Channels"}
                    </div>
                </div>
                <div>
                    <label htmlFor="area-manager-filter" className="form-label">Area Managers</label>
                    <select 
                        className={`form-select btn-sm btn-light-primary ${selectedFilter.includes("Area Managers") ? "active" : ""}`}
                        onChange={(e) => handleFilter("Area Managers", e.target.value)}
                        value={selectedAreaManager}
                        id="area-manager-filter"
                    >
                        {areaManagerList.map((a: any) => <option value={a?.id}>{getName(a)}</option>)}
                    </select>                    
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    height: "80vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#ccc transparent",
                    marginBottom: "100px"
                }}
                onScroll={handleScrollling}
                ref={refss}
            >
                {
                    pending ? <SkeletonLoader /> : <>
                        {
                            chats.map((chat: any) => (
                                <ChatListItem key={chat?.id} {...chat} onClick={() => onSelectChat(chat)} selectedChat={selectedChat} />
                            ))
                        }
                        {
                            chats?.length < recordsTotal && <div className={`d-flex align-items-center justify-content-center p-2`} style={{ cursor: 'pointer' }}>
                                <button className="btn btn-sm btn-light px-4 p-1 fs-5 fw-semibold" disabled={paginationLoader} onClick={() => loadMoreHandler()}>{paginationLoader ? "Loading..." : "Load more"}</button>
                            </div>
                        }
                        {otherMembers.map((chat: any) => (
                            <div key={chat.title}>
                                <h4 className="mt-3 mb-3 text-muted">{chat?.title}</h4>
                                <div style={{height: '400px', overflow: 'auto'}}>
                                    {chat?.members?.length > 0 ? (
                                        <div className="virtual-table">
                                            {chat.members.map((newMember: any, index: number) => (
                                                <div 
                                                    key={newMember.id}
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <ChatListItem 
                                                        {...newMember} 
                                                        onClick={() => onSelectChat(newMember)} 
                                                        selectedChat={selectedChat}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center py-4">No Data Found</div>
                                    )}
                                </div>
                            </div>
                        ))}

                    </>
                }


            </div>
            {show && <AddNewGroup show={show} setShow={setShow} getChats={getChats} />}
        </div>
    );
};

export default Sidebar;
