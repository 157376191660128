import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch'
import { getRequest, postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { columns } from './Columns'
import { ActiveCleanersFilteration } from './ActiveCleanersFilteration'
import './styles.css'
import './ColumnsCss.css'
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu'
import SendNotificationForm from './SendNotificationForm'
import { Button } from 'react-bootstrap'
import ChampBulkWhatsappNotificationSender from '../../consts/components/ChampBulkWhatsappNotificationSender'
import { paginationRowsPerPageOptions, paginationRowsPerPageOptionsAll, reorderColumns, storeReorderIndexes } from '../../consts/components/Utility'
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper'
import LeftBox from '../../consts/mediaQueryLayout/LeftBox'
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal'
import RightBox from '../../consts/mediaQueryLayout/RightBox'
import Refresh from '../../consts/Refresh'
import { BulkUtilButton, BulkUtilButtonWrapper } from '../../consts/reuseModules/BulkUtilButton'
import { ReactTableComponentStylesSheet } from '../../consts/reuseModules/ReactTableComponent/ReactTableComponentStylesSheet'
import AdminBulkAddContactsToCampaignList from '../admins/adminForms/AdminBulkAddContactsToCampaignList'
import { QueryParams } from '../../consts/Server/QueryParams'
import ExcelExportButton from '../../consts/ExcelExportButton'
import { FEATURES, isFeatureAllowed } from '../../consts/Permissions'
import IsLoading from '../../consts/IsLoading/IsLoading'
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../admins/adminConstant'
import { useSelector } from 'react-redux'
import { useAdminManagementPermission } from '../admins/adminUtils'
const ReactDataTable = ({ setFilteredIdForChat }: any) => {
  const { permissionWrapperHideColsUtil } = useAdminManagementPermission()

  const [AllActiveCleaner, setAllActiveCleaner] = useState<any>([])
  const [AllSupervisroList, SetAllSupervisroList] = useState<any>([])
  const [areaListData, setAreaListData] = useState<any>([])
  const [subaAreaListData, setSubaAreaListData] = useState<any>([])
  const [whatsappGroupListData, setWhatsappGroupListData] = useState<any>([])
  const [sortedColumns, setSortedColumns] = useState<any>(permissionWrapperHideColsUtil(columns()));
  const [Search, setSearch] = useState<any>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [filterData, setfilterData] = useState<any>([])
  const [sendNotificationPopUp, setSendNotificationPopUp] = useState(false)
  const [templateData, setTemplateData] = useState<any>([])
  const [refresh, setRefresh] = useState<boolean>(false)
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
  const [showNotifyPopUp, setShowNotifyPopUp] = useState(false);
  const [operationStatuses, setOperationStatuses] = useState([]);
  const [perPageSize, setPerPageSize] = useState(10);

  useEffect(() => {
    if (filterData) {
      const newColumns = permissionWrapperHideColsUtil(columns(filterData?.map((ele: any) => ele?.id)));
      setSortedColumns(newColumns);
    }
  }, [filterData]);


  const [payloads, setPayloads] = useState<any>({
    supervisorId: 0,
    tags: "",
    isSociety: '',
    type: '',
    payout_type: '',
    user_type: '',
    cleanertype: "",
    ratings: "",
    payoutscheme: "",
    cleanerStatus: "",
    start_date: "",
    description: "",
    areaId: null,
    wa_group_id: null,
    sub_area_id: null,
    rating_sval: null,
    rating_eval: null,
    activation_sdate: null,
    activation_edate: null,
    reactivation_sdate: null,
    reactivation_edate: null,
    status_id: 0,

  })

  const handleSelectedRowsChange = (selectedRows: any) => {
    setFilteredIdForChat && setFilteredIdForChat(selectedRows?.selectedRows, "active_champs")
    setFilteredSelectedRows(selectedRows?.selectedRows)
  };

  const handleNotifyPopUpClose = () => {
    setShowNotifyPopUp(false)
  }

  React.useEffect(() => {
    let updatedData = AllFielSearch(AllActiveCleaner, Search)
    setfilterData(updatedData)
  }, [Search])

  React.useEffect(() => {
    getTemplates()
  }, [refresh])

  const getTemplates = () => {

    async function InvokedRendered() {
      const { data } = await getRequest(URL_EndPoints(null)?.getMasterNotificationTemplates, null)
      setTemplateData(data.data)
      setRefresh(false)

      let newQeueryParams = { ...QueryParams("getMasterReasonForVisitors", "?") }
      newQeueryParams.What += `categoryId=7`
      const res = await getRequest(URL_EndPoints(newQeueryParams)?.getMasterReasonForVisitors, null)
      setOperationStatuses(res?.data?.data)
    }
    InvokedRendered()
  }

  useEffect(() => {

    async function InvokedRendered() {
      setPending(true)

      const { data } = await getRequest(URL_EndPoints()?.cleanerlist, setPending)
      setAllActiveCleaner(data.data)
      setfilterData(data.data)

      const supervisor = await getRequest(URL_EndPoints()?.getSupervisorList, null)
      SetAllSupervisroList(supervisor?.data?.data)

      const getarea = await getRequest(URL_EndPoints()?.getarea, null)
      setAreaListData(getarea?.data?.data)

      const whatsappGroupListData = await getRequest(URL_EndPoints()?.getWhatsAppGroupsForFilter, null)
      setWhatsappGroupListData(whatsappGroupListData?.data?.data)

      const getMapBlocksSubAreasList = await postRequest(URL_EndPoints()?.getMapBlocksSubAreasList, {
        "start": 1,
        "length": -1,
        "search": "",
        "orders": "desc",
        "columns": "id",
        "sortby": "",
        "direction": "desc",
        "searchType": "any",
        "filterData": {},
        "supervisorid": 489
      }, null)
      setSubaAreaListData(getMapBlocksSubAreasList?.data?.data)
      filterData && setLoading(false)
    }
    InvokedRendered()
  }, [])


  const handleSearch = async () => {
    setPending(true)
    let QeueryParams = {
      To: "cleanerlist",
      What: `?supervisorid=${payloads.supervisorId}&type=${payloads.cleanertype}&areaId=${payloads.areaId}&sub_area_id=${payloads.sub_area_id}&rating_sval=${payloads.rating_sval}&rating_eval=${payloads.rating_eval}&wa_group_id=${payloads.wa_group_id}&activation_sdate=${payloads.activation_sdate}&activation_edate=${payloads.activation_edate}&reactivation_sdate=${payloads.reactivation_sdate}&reactivation_edate=${payloads.reactivation_edate}&payout_type=${payloads.payout_type}&user_type=${payloads.user_type}&status_id=${payloads.status_id}`
    }
    const { data } = await getRequest(URL_EndPoints(QeueryParams)?.cleanerlist, setPending)
    setAllActiveCleaner(data.data)
    setfilterData(data.data)
  }



  const handleChangeInputData = (event: any, name: any = null) => {
    if (name != null) {
      setPayloads({
        ...payloads, [name]: event?.value
      })
    } else {
      setPayloads({ ...payloads, [event?.target?.name]: event?.target?.value })
    }
  }

  const handlePerRowsChange = async (newPerPageSize: any, page: any) => {
    setPerPageSize(newPerPageSize)

  };

  const [showCampaignContact, setShowCampaignContact] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  return (
    <div className='active-cleaners'>
      {
        <DataTable
          title='Active Cleaner'
          selectableRows
          progressPending={pending}
          progressComponent={<IsLoading />}
          customStyles={ReactTableComponentStylesSheet}
          columns={reorderColumns(sortedColumns, "activeCleanerReorderedItem")}
          data={filterData}
          onColumnOrderChange={cols => storeReorderIndexes(cols, "activeCleanerReorderedItem")}
          fixedHeader
          fixedHeaderScrollHeight='auto'
          selectableRowsHighlight
          highlightOnHover
          subHeader
          onSelectedRowsChange={handleSelectedRowsChange}
          paginationComponentOptions={paginationRowsPerPageOptionsAll}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationPerPage={perPageSize}
          pagination
          // striped= {true}
          onChangeRowsPerPage={handlePerRowsChange}
          clearSelectedRows={toggledClearRows}
          subHeaderComponent={
            <>
              <MainContainerWrapper>
                <LeftBox>
                  <SearchBoxModal search={Search} setSearch={setSearch} />
                </LeftBox>
                <RightBox>
                  <Refresh refreshNow={handleSearch} />
                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-export-list"]}>
                    {
                      isFeatureAllowed(FEATURES.activeCleanerExport) && <ExcelExportButton tableName=".active-cleaners" />
                    }
                  </PermissionContextProvider>
                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-send-notification"]}>
                    <Button variant='primary btn btn-sm  btn-flex  bg-body btn-color-gray-700 btn-active-color-primary  btn-active-secondary p-3 px-3   filterBtn' onClick={() => setSendNotificationPopUp(true)}>Send Notification</Button>
                  </PermissionContextProvider>
                  <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-filter"]}>
                    <DropDownFilterMenu
                      ParentData={{
                        handleChangeInputData: handleChangeInputData,
                        handleActions: handleSearch,
                        payloads: payloads,
                        AllSupervisroList: AllSupervisroList,
                        areaListData: areaListData,
                        subaAreaListData: subaAreaListData,
                        whatsappGroupListData: whatsappGroupListData,
                        operationStatuses: operationStatuses,
                      }}
                      Component={ActiveCleanersFilteration}
                    ></DropDownFilterMenu>
                  </PermissionContextProvider>




                </RightBox>
              </MainContainerWrapper>
              {
                filteredSelectedRows.length > 0 ? <BulkUtilButtonWrapper>
                  <BulkUtilButton title="Bulk WhatsApp Notify Champs" filteredSelectedRows={filteredSelectedRows} setShow={setShowNotifyPopUp} />
                  <BulkUtilButton title="Bulk Add Contacts to Campaign list" filteredSelectedRows={filteredSelectedRows} setShow={setShowCampaignContact} />
                </BulkUtilButtonWrapper> : <></>
              }

            </>
          }
        />
      }

      {
        sendNotificationPopUp ? <SendNotificationForm data={templateData} sendNotificationPopUp={sendNotificationPopUp} setSendNotificationPopUp={setSendNotificationPopUp} mainPayloads={payloads} /> : <></>
      }


      {
        showCampaignContact ? <AdminBulkAddContactsToCampaignList
          show={showCampaignContact}
          setCloseModal={setShowCampaignContact}
          filteredSelectedRowsIds={filteredSelectedRows?.map((row: any) => row && row?.id)?.filter((id: number) => (id !== undefined) && (id !== 0))}
          setFilteredSelectedRows={setFilteredSelectedRows}
          setToggleClearRows={setToggleClearRows}
          toggledClearRows={toggledClearRows}
          handleRefresh={null}
          userType={"cleaner"}
          set
        /> : <></>
      }


      {
        showNotifyPopUp ? <ChampBulkWhatsappNotificationSender
          showNotifyPopUp={showNotifyPopUp}
          handleNotifyPopUpClose={handleNotifyPopUpClose}
          filteredSelectedRows={filteredSelectedRows}
          templateType={2}
          key="champIds"
          setFilteredSelectedRows={setFilteredSelectedRows}
        />
          : <></>
      }
    </div>
  )
}
export default ReactDataTable