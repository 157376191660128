import React from "react";
import ChatApp from "./ChatApp";

const LiveChat = () => {
  return (
    <div className="container-fluid p-0 h-100">
      <ChatApp />
    </div>
  );
};

export default LiveChat;





