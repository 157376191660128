import {toast} from 'react-toastify'
import {QueryParams} from './../Server/QueryParams'
import {getRequest, postRequest} from '../Server/Requests'
import {URL_EndPoints} from '../Server/URL_EndPoints'
import {useLocation} from 'react-router'

export const toastNotifier = (response: any = '', message: any = 'msg', closePopUp: any) => {
  if (response?.status == 200) {
    toast.success(response[message], {position: 'top-center'})
    closePopUp(false)
  } else {
    toast.error(response[message], {position: 'top-center'})
    closePopUp(false)
  }
}

export const downloadSampleFileKey = '67bcc4a8-0e27-4b01-b1ae-437b210f5836.xlsx'
export const image_Cloudfront = 'https://dic554fk1zcxm.cloudfront.net/'
export const image_Amaxonaws = 'https://carselona-img.s3.ap-south-1.amazonaws.com'
export const isUserid = localStorage.getItem('user') || -1
// export const isAllowed: any = '7, 25'
export const isAllowed: any = '7, 25, 70, 72, 107, 87, 53, 115, 127'
export const isAllowedUser: any = '7, 25, 70, 110, 87, 112, 115, 40'
export const isAllowedForShubhamMoinRoshan: any = '110, 70, 115'
export const isAllowedUserAction: any = '7, 25, 70, 115'

export const isAllowedChampNumber = (champ_number: any, champ_id: any) => {
  if (isAllowed.includes(isUserid) || localStorage.getItem('role') == 'Admin') return champ_number
  return champ_id
}

export const isAllowedCopyNumber = () => {
  if (isAllowed.includes(isUserid) || localStorage.getItem('role') == 'Admin') return true
  return false
}

export const customSortingCoumn = (rowA: any, rowB: any, keyName: any, isStr: boolean) => {
  const a = isStr ? rowA[keyName]?.toLowerCase() : rowA[keyName]
  const b = isStr ? rowB[keyName]?.toLowerCase() : rowB[keyName]
  if (a > b) {
    return 1
  }

  if (b > a) {
    return -1
  }
  return 0
}

export const setQueryParameter = (table_Submen_Id: any) => {
  let url = new URL(window.location.href)
  url.searchParams.set('table', table_Submen_Id)
  window.history.replaceState({}, document.title, url.href)
}
// setQueryParameter(2);
export const isConvertedArrayOfActionToObj = (parmas: any, key: any) => {
  let arr = parmas[key] || []
  let isAction = arr.reduce((result: any, item: any) => {
    result[item.action] = item.isAllowed
    return result
  }, {})
  return isAction
}

export const changedIsActivesToIsActiveKeyName = (data: any, from: any) => {
  let keyMapped: any = {}

  let newData = data?.data?.map((menuItem: any) => {
    let menu = JSON.parse(JSON.stringify(menuItem))
    let isActive = Object.keys(menu).includes('isActive') ? 'isActive' : 'isActives'
    let isAction = Object.keys(menu).includes('isActive') ? 'isAction' : 'isActions'

    if (menu?.isParent != 0) {
      if ((menu && menu[isActive]?.isAllowed == 1) || (menu && menu[isActive]?.isAllowed == 0)) {
        menu?.subMenu?.map((subMenu: any) => {
          if (
            (subMenu && subMenu[isActive]?.isAllowed == 1) ||
            (subMenu && subMenu[isActive]?.isAllowed == 0)
          ) {
            let active = subMenu[isActive]
            keyMapped[subMenu?.url] = active[isAction] ?? []
          }
        })
      }
    } else if (
      (menu && menu[isActive]?.isAllowed == 1) ||
      (menu && menu[isActive]?.isAllowed == 0)
    ) {
      let active = menu[isActive]
      keyMapped[menu?.url] = active[isAction] ?? []
    }
  })
  return from ? newData : keyMapped
}

export const reorderColumns = (data: any = [], localStorageKey: string) => {
  const indexValue: any = JSON.parse(localStorage.getItem(localStorageKey) || '[]') || []

  const idToIndexMap: any = {}
  indexValue.forEach((id: any, index: any) => {
    idToIndexMap[id] = index
  })

  data?.sort((a: any, b: any) => {
    const indexA = idToIndexMap[a.id]
    const indexB = idToIndexMap[b.id]
    return indexA - indexB
  })
  return data
}

export const storeReorderIndexes = (cols: any = [], localStorageKey: any) => {
  localStorage.setItem(localStorageKey, JSON.stringify(cols?.map((column: any) => column?.id)))
}

export const paginationRowsPerPageOptionsAll = {
  selectAllRowsItem: true,
  selectAllRowsItemText: 'ALL',
  // rowsPerPageText: 'Rows per page:',
  // rangeSeparatorText: 'of',
  // noRowsPerPage: false,
}

export const paginationRowsPerPageOptions = [10, 15, 20, 25, 30, 50, 100, 150, 300, 500, 1000]


// paginationComponentOptions={paginationRowsPerPageOptionsAll}
// paginationRowsPerPageOptions={paginationRowsPerPageOptions}

// onColumnOrderChange={cols => storeReorderIndexes(cols, "workingOn")}
// reorderColumns(sortedColumns, "workingOn")

// reorder:true,

// sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA?.customer, rowB?.customer, "first_name", true),

// cell: (row: any) => <DateFormateMoment date={row?.createdAt} from="TICKET_CREATED_AT" />,

{
  /* <ToggleColumns columns={[{ name: "CREATED AT", id: "CREATED AT" }, { name: "UPDATED AT", id: "UPDATED AT" }]} from="TICKET" /> */
}

// customer search filter

export const getSearchDataBykeyword = async (
  storeSeachKeywords: any,
  setsearchDataCleaner: any,
  setIsLoading: any
) => {
  setIsLoading(true)

  let newQueryParams = {...QueryParams('getAllCustomerListBySearch', '?')}
  newQueryParams.What += `start=${0}`
  newQueryParams.What += `&length=${10}`
  newQueryParams.What += `&search=${storeSeachKeywords}`
  const response: any = await getRequest(
    URL_EndPoints(newQueryParams)?.getAllCustomerListBySearch,
    setIsLoading
  )

  let newData = response?.data?.data?.map((ele: any) => {
    ele.first_name = ele?.first_name ?? 'NA'
    ele.last_name = ele?.last_name ?? 'NA'
    ele.phone = ele?.phone ?? 'NA'
    return ele
  })

  response?.data?.data && setsearchDataCleaner(newData)
  setIsLoading(false)
}

export const handleSelectChange = (event: any, name: any, setPayload: any, payload: any) => {
  setPayload({...payload, [name]: event ? event?.value : null})
}

export const filterDataManuplation = (filterData: any = [], dynamicKeys: any = []) => {
  return filterData?.map((ele: any) => ({
    label: dynamicKeys?.map((key: any) => (!ele[key] ? 'NA' : ele[key])).join(' '),
    value: ele?.id,
    item: ele,
  }))
}

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const catchCurrentOpenedUrl = () => {
  return window.location.pathname
}

export const fetchCallHistoryUtil = async (
  _ids: any = [],
  setCallHistory: any = false,
  setPending: any = false,
  isFor: any = ''
) => {
  setPending && setPending(true)
  let payload: {customerIds: number[]; userType?: string} = {customerIds: _ids}
  if (isFor) {
    payload['userType'] = isFor
  }
  const response = await postRequest(
    URL_EndPoints()?.getCallHistoryByCustomerIds,
    payload,
    setPending
  )
  response?.data?.data?.[0]?.length > 0 && setCallHistory(response?.data?.data?.[0])
}
