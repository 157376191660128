// import { LocalBaseURL } from "../../../../../BaseURLmanagement"
import { DropdownAttendanceStatus } from "./DropdownAttendanceStatus"
import React, { useState } from "react"
import { LocalBaseURL } from "../../../../BaseURLmanagement"
const HandleDropDown = (props: any) => {
  LocalBaseURL()

  return (
    <div className='primary'>
      <DropdownAttendanceStatus referece="AssignedCleaner" filteredData={props.props} cleaneridSingle={props.props.id}></DropdownAttendanceStatus>

    </div>
  )
}
export default HandleDropDown