
const HiringGenerateFilter = ({ ParentData }: any) => {
  const getNearestMonday = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const difference = currentDay === 0 ? 6 : currentDay - 1;
    const nearestMonday = new Date(currentDate);
    nearestMonday.setDate(currentDate.getDate() - difference);
    return nearestMonday.toISOString().split('T')[0];
  };

  const nearestMonday = getNearestMonday();
  return (
    <>
      <div className='p-6 w-325px w-md-375px'>
        <h3 className='fw-bold mb-7 text-primary'>Generate Hiring Points Payout</h3>

        <div className='row mb-4'>
          <span className="mb-2 ">Start Date : Please Only Select <span className="text-danger fw-bold">Date</span> of <span className="text-danger fw-bold">Monday</span></span>
          <div className='col-12'>
            <input
              type='date'
              style={{ height: '38px' }}
              value={ParentData?.payloads?.startDate}
              name='startDate'
              max={nearestMonday}
              onChange={(event: any) =>
                ParentData.handleChangeInput(event.target.value, 'startDate')
              }
              className='w-100  height-100 form-control me-2 align-start'
            />
          </div>
        </div>

        <div className='row mb-4'>
          <span className="mb-2 ">End Date : Please Only Select  <span className="text-danger fw-bold">Date</span> of <span className="text-danger fw-bold">Sunday</span></span>
          <div className='col-12'>
            <input
              type='date'
              style={{ height: '38px' }}
              value={ParentData?.payloads?.endDate}
              name='endDate'
              max={nearestMonday}
              onChange={(event: any) =>
                ParentData.handleChangeInput(event.target.value, 'endDate')
              }
              className='w-100  height-100 form-control me-2 align-start'
            />
          </div>
        </div>

      </div>
    </>
  )
}
export { HiringGenerateFilter }



// const HiringGenerateFilter = ({ ParentData }: any) => {
//   // Generate an array of Mondays for the next year
//   const getMondays = () => {
//     const dates = [];
//     let currentDate = new Date();
//     currentDate.setDate(currentDate.getDate() + ((1 - currentDate.getDay() + 7) % 7)); // Next Monday

//     for (let i = 0; i < 52; i++) {
//       dates.push(currentDate.toISOString().split("T")[0]);
//       currentDate.setDate(currentDate.getDate() + 7); // Next Monday
//     }

//     return dates;
//   };

//   // Generate an array of Sundays for the next year
//   const getSundays = () => {
//     const dates = [];
//     let currentDate = new Date();
//     currentDate.setDate(currentDate.getDate() + ((7 - currentDate.getDay()) % 7)); // Next Sunday

//     for (let i = 0; i < 52; i++) {
//       dates.push(currentDate.toISOString().split("T")[0]);
//       currentDate.setDate(currentDate.getDate() + 7); // Next Sunday
//     }

//     return dates;
//   };

//   const validMondays = getMondays();
//   const validSundays = getSundays();

//   const handleStartDateChange = (date: string) => {
//     if (validMondays.includes(date)) {
//       ParentData.handleChangeInput(date, "startDate");

//       // Automatically set the nearest Sunday after the selected Monday
//       const selectedMonday = new Date(date);
//       const nextSunday = new Date(selectedMonday);
//       nextSunday.setDate(selectedMonday.getDate() + 6);
//       const formattedSunday = nextSunday.toISOString().split("T")[0];

//       ParentData.handleChangeInput(formattedSunday, "endDate");
//     } else {
//       alert("Please select a valid Monday!");
//     }
//   };

//   const handleEndDateChange = (date: string) => {
//     if (validSundays.includes(date)) {
//       ParentData.handleChangeInput(date, "endDate");
//     } else {
//       alert("Please select a valid Sunday!");
//     }
//   };

//   return (
//     <div className="p-6 w-325px w-md-375px">
//       <h3 className="fw-bold mb-7 text-primary">Generate Hiring Points Payout</h3>

//       {/* Start Date */}
//       <div className="row mb-4">
//         <span className="mb-2">
//           Start Date: Please Only Select <span className="text-danger fw-bold">Monday</span>
//         </span>
//         <div className="col-12">
//           <input
//             type="date"
//             style={{ height: "38px" }}
//             value={ParentData?.payloads?.startDate}
//             name="startDate"
//             onChange={(event: any) => handleStartDateChange(event.target.value)}
//             className="w-100 form-control me-2 align-start"
//             list="monday-dates"
//           />
//           <datalist id="monday-dates">
//             {validMondays.map((date) => (
//               <option key={date} value={date} />
//             ))}
//           </datalist>
//         </div>
//       </div>

//       {/* End Date */}
//       <div className="row mb-4">
//         <span className="mb-2">
//           End Date: Please Only Select <span className="text-danger fw-bold">Sunday</span>
//         </span>
//         <div className="col-12">
//           <input
//             type="date"
//             style={{ height: "38px" }}
//             value={ParentData?.payloads?.endDate}
//             name="endDate"
//             min={ParentData?.payloads?.startDate} // Ensure endDate is after startDate
//             onChange={(event: any) => handleEndDateChange(event.target.value)}
//             className="w-100 form-control me-2 align-start"
//             list="sunday-dates"
//           />
//           <datalist id="sunday-dates">
//             {validSundays.map((date) => (
//               <option key={date} value={date} />
//             ))}
//           </datalist>
//         </div>
//       </div>
//     </div>
//   );
// };

// export { HiringGenerateFilter };
