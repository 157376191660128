import { TableColumn } from 'react-data-table-component'

export const AllowanceColumn = (setPayload: any, payload: any, setEditLoader: any, mainRow:any) => [
  {
    name: 'EDIT',
    selector: (row: any) => <i onClick={() => EditHandler(row, setPayload, payload, setEditLoader,mainRow)} className="bi bi-pencil-square fs-2 text-danger cursor-pointer"></i>,
    sortable: true,
    id: 'ID',
  },

  {
    name: 'ID',
    selector: (row: any) => row?.id,
    sortable: true,
    id: 'ID',
  },

  {
    name: 'ALLOWANCE NAME',
    selector: (row: any) => row?.master_allowance?.name,
    sortable: true,
    id: 'ID',
  },

  {
    name: 'CASH/POINTS',
    selector: (row: any) => row?.type,
    sortable: true,

  },
  
  {
    name: 'AMOUNT',
    selector: (row: any) => row?.amount,
    sortable: true,

  },
  {
    name: 'STATUS',
    selector: (row: any) => row?.status == 1 ? "Active" : "Inctive",
    sortable: true,

  },

];

const EditHandler = (row: any, setPayload: any, payload: any, setEditLoader: any,mainRow:any) => {
  setEditLoader(true)

  payload.cl_allowance_id = row?.id
  payload.type = row?.type
  payload.allowance_id = row?.allowance_id
  payload.cleanerid = mainRow?.id
  payload.amount = row?.amount
  payload.status = row?.status


  setPayload(payload)
  setTimeout(() => {
    setEditLoader(false)
  }, 1000)

}