import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import HandleDropDown from './DropBox/HandleDropDown'
import { Root } from './interfaces'
import { TagsUpdate } from './TagsUpdateForm/TagsUpdate'
import OpenChat from '../../consts/champChatDashboard/OpenChat'
import { customSortingCoumn, isAllowedChampNumber } from '../../consts/components/Utility'
import { postRequest } from '../../consts/Server/Requests'
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints'
import { toast } from 'react-toastify'
import IsLoading from '../../consts/IsLoading/IsLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import AddRemoveWhatsappGroup from './Forms/AddRemoveWhatsappGroup'
import { sortDateWithNull } from '../admins/adminUtils'
import { AdminToolTip } from '../admins/adminComponents/AdminToolTipModal'
import AdminActiveOrInactive from '../admins/adminComponents/AdminActiveOrInactive'
import AdminHeaderColumnExpand from './../admins/adminComponents/AdminHeaderColumnExpand';
import ListRowToolTip from './../admins/adminComponents/ListRowToolTip';
import moment from 'moment'
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../admins/adminConstant'
import MaskedPhoneNumber from '../../consts/MaskedPhoneNumber'

let arr = [{ name: "weekly", id: 1 }, { name: "monthly", id: 2 }]
let payoutType: any = {
  1: "weekly",
  2: "monthly",
}

export const columns = (data?: any) => [
  {
    id: 1,
    reorder: true,
    name: 'ACTION',
    cell: (row: any) => (
      <HandleDropDown props={row}></HandleDropDown>
    ),
    minWidth: "70px",
  },
  {
    id: 16,
    reorder: true,
    name: <ListRowToolTip anchorId={16} title="Whatsapp Group" subTitle="W.G" />,
    cell: (row: any) => <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-whatsapp-group"]}>
      <WhatsappGroups row={row} />
    </PermissionContextProvider>,
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.champ_whatsapp_group?.group_name
      let b = rowB?.champ_whatsapp_group?.group_name
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },
    sortable: true,
    // width:""
    minWidth: "150px",
    hideCols: "active-whatsapp-group"

  },
  {
    id: 2,
    reorder: true,
    name: 'ID',
    selector: (row: { id: any }) => row.id,
    sortable: true,
    minWidth: "80px"
  },
  {
    id: 3,
    reorder: true,
    name: 'NAME',
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA, rowB, "first_name", true),
    cell: (row: any) => {
      return (
        <div className='text-left'>

          <VisitChat row={row} trackData={data} />

          <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap'
            id="kt_drawer_chat2_toggle2"
          >
            <TagsUpdate row={row} tag={"private"} />
          </div>
        </div>
      )
    },

    sortable: true,
    // id: 'css',
    minWidth: "150px",
    grow: 4
  },
  {
    id: 4,
    reorder: true,
    name: 'PHONE',
    selector: (row: any) => isAllowedChampNumber(<MaskedPhoneNumber phoneNumber={row.phone}/>, row?.id),
    sortable: true,
  },
  {
    id: 23,
    reorder: true,
    name: <ListRowToolTip anchorId={22} title="MIN PAYOUT" subTitle="MIN PAYOUT" />,
    cell: (row: any) => <ShowMinPayout row={row} />,
    sortable: true,
    grow: 4,
    minWidth: "100px"
  },
  {
    id: 22,
    reorder: true,
    name: <ListRowToolTip anchorId={22} title="NOTICE PERIOD DATE" subTitle="N.P.D" />,
    selector: (row: any) => row?.notice_period_date ? row?.notice_period_date : "Not Set",
    sortable: true,
    grow: 4,
    minWidth: "100px"
  },
  {
    id: 5,
    reorder: true,
    name: <ListRowToolTip anchorId={5} title="WORK LOCATION" subTitle="W.L" />,
    cell: (row: any) => {
      if (row.worklocation.length > 0) {
        return <span className='badge badge-success fs-8 fw-bold'>Yes</span>
      } else {
        return <span className='badge badge-danger fs-8 fw-bold'>No</span>
      }
    },
    minWidth: "80px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.worklocation?.length
      let b = rowB?.worklocation?.length
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },
  },
  {
    id: 30,
    reorder: true,
    name: <ListRowToolTip anchorId={30} title="This Week Max Earnable Points" subTitle="T.W.M.E.P" />,
    cell: (row: any) => row?.thisWeekMaxEarnablePoints,
    grow: 4,
    minWidth: "60px",
    sortable: true,
  },
  {
    id: 31,
    reorder: true,
    name: <ListRowToolTip anchorId={31} title="Projected Points" subTitle="P.T" />,
    cell: (row: any) => row?.thisWeekProjectedPoints,
    grow: 4,
    minWidth: "80px",
    sortable: true,
  },
  {
    id: 6,
    reorder: true,
    name: 'SUPERVISORS',
    cell: (row: any) => supervisorSpan(row),
    sortable: true,
    grow: 4,
    minWidth: "200px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.cleanerSupervisor?.supervisorcleaner?.first_name
      let b = rowB?.cleanerSupervisor?.supervisorcleaner?.first_name
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },
  },
  {
    id: 7,
    reorder: true,
    name: 'Area Name',
    cell: (row: any) => row?.cleanerArea?.areaDetail?.areaname,
    sortable: true,
    grow: 4,
    minWidth: "200px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.cleanerArea?.areaDetail?.areaname
      let b = rowB?.cleanerArea?.areaDetail?.areaname
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },

  },
  {
    id: 17,
    reorder: true,
    name: 'Activation Date',
    cell: (row: any) => row?.champHistory?.date,
    grow: 4,
    minWidth: "150px",
    sortFunction: (rowA: any, rowB: any) => sortDateWithNull(rowA?.champHistory?.date?.toLowerCase(), rowB?.champHistory?.date?.toLowerCase()),

  },
  {
    id: 18,
    reorder: true,
    name: 'Re-Activation Date',
    cell: (row: any) => row?.champReactivatedHistory?.date,
    sortable: true,
    grow: 4,
    minWidth: "150px",
    sortFunction: (rowA: any, rowB: any) => sortDateWithNull(rowA?.champReactivatedHistory?.date?.toLowerCase(), rowB?.champReactivatedHistory?.date?.toLowerCase()),
  },
  {
    id: 14,
    reorder: true,
    name: 'Sub Area Name',
    cell: (row: any) => row?.cleaner_sub_area_details?.name,
    sortable: true,
    grow: 4,
    minWidth: "150px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.cleaner_sub_area_details?.name
      let b = rowB?.cleaner_sub_area_details?.name
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },
  },
  {
    id: 21,
    reorder: true,
    name: 'Champ Status',
    cell: (row: any) => <AdminActiveOrInactive title={row?.cleanerMasterOperationReasons?.name ? row?.cleanerMasterOperationReasons?.name : "NA"} isActive={row?.cleanerMasterOperationReasons?.id} />,
    sortable: true,
    grow: 4,
    minWidth: "150px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.cleanerMasterOperationReasons?.name
      let b = rowB?.cleanerMasterOperationReasons?.name
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },
  },

  {
    id: 8,
    reorder: true,
    name: <ListRowToolTip anchorId={8} title="DEPOSITED" subTitle="D" />,
    selector: (row: any) => row.security_deposited_amount + "/" + row.security_deposit_amount,
    sortable: true,
    grow: 4,
    minWidth: "100px"
  },
  {
    id: 19,
    reorder: true,
    name: <ListRowToolTip anchorId={19} title='HOME TO WORK DISTANCE' subTitle="H.T.W.D" />,
    selector: (row: any) => row?.homeToWorkDistance,
    sortable: true,
    grow: 4,
    minWidth: "80px"
  },
  {
    id: 20,
    reorder: true,
    name: <ListRowToolTip anchorId={20} title='MAX DISTANCE TRAVEL' subTitle="M.D.T" />,
    selector: (row: any) => row?.maxDistanceTravel,
    sortable: true,
    grow: 4,
    minWidth: "80px"
  },
  {
    id: 9,
    reorder: true,
    name: 'PINCODE',
    selector: (row: { pincode: any }) => row.pincode,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA, rowB, "pincode", false),
    sortable: true,
    minWidth: "80px"
  },


  {
    id: 10,
    reorder: true,
    name: <ListRowToolTip anchorId={10} title='PROJECT POINTS' subTitle="P.P" />,
    cell: (row: any) => row?.clAttendance?.total_points_earned,
    sortable: true,
    grow: 4,
    minWidth: "70px",
    sortFunction: (rowA: any, rowB: any) => {
      let a = rowA?.clAttendance?.total_points_earned
      let b = rowB?.clAttendance?.total_points_earned
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    },

  },
  {
    id: 11,
    reorder: true,
    name: 'EMAIL',
    selector: (row: { email: any }) => row.email,
    sortable: true,
    grow: 4,
    minWidth: "180px"
  },


  {
    id: 12,
    reorder: true,
    name: 'RATING',
    cell: (row: any) => {
      return <span className='badge badge-success fs-8 fw-bold'>{row?.rating}</span>
    },
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA, rowB, "rating", false),

    sortable: true,
    minWidth: "80px"
  },
  {
    id: 13,
    reorder: true,
    name: 'Payout Type',
    cell: (row: any) => <PayoutType row={row} />,
    sortFunction: (rowA: any, rowB: any) => customSortingCoumn(rowA, rowB, "payout_type", false),
    sortable: true,
    minWidth: "150px",

  },

]

const PayoutType = ({ row }: any) => {
  // const [storeRow, setStoreRow] = useState<any>(row)
  // useEffect(() => {
  //   setStoreRow(row)
  // }, [row])

  // const [pending, setPending] = useState<any>(false)
  // const hnadleUpdate = async (value: any) => {
  //   setPending(true)
  //   const response: any = await postRequest(URL_EndPoints(null)?.updateChampColumns, {
  //     "cleaner_id": row?.id,
  //     "payout_type": +value
  //   }, setPending)

  //   if (response?.data?.status === 200) {
  //     toast.success(response?.data?.msg, { position: "top-center" })
  //     row.payout_type = +value
  //     setStoreRow(row)
  //   }
  //   else {
  //     toast.error(response?.data?.msg, { position: "top-center" })
  //   }
  // }

  return (
    <>
      {/* {
        pending ? <IsLoading /> : <select
          name='date_typer'
          data-control='select2'
          data-placeholder='Period'
          className='form-select'
          defaultValue={storeRow?.payout_type}
          style={{ height: "30px", padding: "5px" }}
          onChange={(event: any) => hnadleUpdate(event.target.value)}
        >
          {
            arr?.map((ele: any) => {
              return (
                <>
                  < option value={ele?.id} >{ele?.name}</option>
                </>
              )
            })
          }
        </select>
      } */}

      {
        payoutType[row?.payout_type] ? <span id={row?.id}>
          {payoutType[row?.payout_type]}
          {row?.payout_type ? <AdminToolTip anchorId={row?.id} title="Please change the scheme record and the payout type will be changed automatically." place={""} /> : <></>}
        </span> : <>NA</>
      }

    </>
  )
}

const ShowMinPayout = ({ row }: any) => {
  let filteredData = row?.schemeRecords?.filter((filter: any) => filter.status == 1)
  return (
    filteredData?.[0]?.min_earning_week
  )
}

const WhatsappGroups = ({ row }: any) => {
  const [storeRow, setStoreRow] = useState<any>(row)
  const [pending, setPending] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  useEffect(() => {
    setStoreRow(row)
  }, [row])
  return (
    <>
      <FontAwesomeIcon icon={faEdit} className='fs-4 me-2 cursor-pointer' onClick={() => setShowModal(true)} />  {storeRow?.champ_whatsapp_group?.group_name || "Null"}
      {
        showModal ? <AddRemoveWhatsappGroup showModal={showModal} setShowModal={setShowModal} setPending={setPending} row={row} setStoreRow={setStoreRow} /> : <></>
      }
    </>
  )
}

const VisitChat = ({ row, trackData }: any) => {
  return (
    <>
      <OpenChat title={row?.first_name + " " + row?.last_name} champId={row?.id} trackData={trackData} />
    </>
  )
}

const supervisorSpan = (row: any) => {
  if (row.cleanerSupervisor.length > 0) {
    var output = row.cleanerSupervisor.map((supervisorname: any) => {
      return (
        <span className='badge badge-success fs-8 fw-bold'>
          {supervisorname?.supervisorcleaner?.first_name +
            ' ' +
            supervisorname?.supervisorcleaner?.last_name}
        </span>
      )
    })
    return (
      <>
        <p className=''>{output}</p>
      </>
    )
  } else {
    return (
      <>
        <span className='badge badge-success fs-8 fw-bold'>Not Assigned</span>
      </>
    )
  }
}



