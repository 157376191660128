import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { toast } from 'react-toastify';
import { adminQueryGenerator, adminToastRunner } from '../../admins/adminUtils';
import IsLoading from '../../../consts/IsLoading/IsLoading';

const DeactivateChamps = ({ deactivateModalOpen, handleCloseModel, row }: any) => {
    let noteCategoryRowData = row?.champHistoryV2?.champInactiveReason?.notesCategory
    let noteSubCategoryRowData = row?.champHistoryV2?.champInactiveReason
    let accountDeleted = row?.accountDeleted == 1 ? true : false

    const [loader, setLoader] = useState(false)
    // const [masterReasonChamp, setMasterReasonChamp] = useState<any>([])
    const [categoryNotes, setCategoryNotes] = useState<any>([])
    const [masterNotes, setMasterNotes] = useState<any>([])
    const [noteApiLoader, setNoteApiLoader] = useState<any>(false)
    const [defaultLoader, setDefaultLoader] = useState(false)


    const [payload, setPayload] = useState<any>({
        cleanerid: row?.id,
        reasonId: 0,
        noteReason: "",
        categoryId: null,
        isBlock: false,
    })



    useEffect(() => {
        let newPayload: any = {
            reasonId: 0,
            noteReason: "",
            categoryId: null,
            isBlock: accountDeleted,
        }
        if (noteCategoryRowData?.id) {
            newPayload.categoryId = noteCategoryRowData?.id
        }

        if (noteSubCategoryRowData?.note) {
            newPayload.noteReason = noteSubCategoryRowData?.note
            newPayload.reasonId = noteSubCategoryRowData?.id
        }
        newPayload.isBlock = accountDeleted
        setPayload({ ...payload, ...newPayload })

    }, [noteCategoryRowData?.name, noteSubCategoryRowData?.note, accountDeleted])



    async function getInitialList4Actions() {
        // const getMasterReasonChampActiveDeactive = await postRequest(URL_EndPoints().getMasterReasonChampActiveDeactive, {}, null)
        // setMasterReasonChamp(getMasterReasonChampActiveDeactive?.data?.data)
        const getNotesCategories = await getRequest(URL_EndPoints().getNotesCategories, null)
        setCategoryNotes(getNotesCategories?.data?.data)
    }

    useEffect(() => {
        getInitialList4Actions()
    }, [])




    async function getNotesByCategory(category: any) {
        setNoteApiLoader(true)
        const getNotesByCategory = await getRequest(URL_EndPoints(adminQueryGenerator("getNotesByCategory", category)).getNotesByCategory, setNoteApiLoader)
        setMasterNotes(getNotesByCategory?.data?.data)
    }



    useEffect(() => {
        payload?.categoryId && getNotesByCategory({ categoryId: payload?.categoryId })
    }, [payload?.categoryId])


    const deactivateCleanerHandler = async () => {
        let { categoryId, ...newPayload } = payload
        setLoader(true)
        const deactivate: any = await postRequest(URL_EndPoints().makeInactiveCleaner, newPayload, setLoader)
        adminToastRunner(deactivate, "msg")
        if (deactivate?.data?.status == 200) {
            handleCloseModel()
        }
    }

    return (
        <Modal show={deactivateModalOpen} onHide={handleCloseModel} dialogClassName="modal-small"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Deactivate Champ </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {/* <Form.Group className="mb-3" >
                    <Form.Label>Select Reason</Form.Label>
                    <Form.Select aria-label="reasonId" name='reasonId' onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}>
                        <option value={0}>Select Reason</option>
                        {
                            masterReasonChamp?.map((ele: any) => <option value={ele?.id}>{ele?.name}</option>)

                        }
                    </Form.Select>
                </Form.Group> */}

                {
                    defaultLoader ? <IsLoading /> :
                        <Form.Group className="mb-3" key={"cateegoryId2"} >
                            <Form.Label>Select Note Category</Form.Label>
                            <Form.Select
                                aria-label="categoryId"
                                name='categoryId'
                                value={+payload.categoryId || ""}
                                defaultValue={+payload.categoryId || ""}
                                onChange={(event) => setPayload({ ...payload, [event.target.name]: event.target.value })}
                            >
                                <option value={""}>Select Note Category</option>
                                {
                                    categoryNotes?.map((ele: any) => <option value={ele?.id}>{ele?.name}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                }

                {
                    noteApiLoader ? <IsLoading /> : payload?.categoryId && <Form.Group className="mb-3" key={"subNotcateegoryId2"} >
                        <Form.Label>Select Sub Note Category</Form.Label>
                        <Form.Select
                            aria-label="noteReason"
                            name='noteReason'
                            value={`${payload.noteReason || ""}_${payload.reasonId || ""}`}
                            defaultValue={`${payload.noteReason || ""}_${payload.reasonId || ""}`}
                            onChange={(event) => setPayload({ ...payload, ["noteReason"]: event.target.value == "0" ? Number(event.target.value) : event.target.value?.split("_")[0], ["reasonId"]: Number(event.target.value?.split("_")[1]) })}
                        >
                            <option value={""}>Select Sub Note Category</option>
                            {
                                masterNotes?.map((ele: any) => <option value={ele?.note + "_" + ele?.id}>{ele?.note}</option>)
                            }
                        </Form.Select>
                    </Form.Group>
                }

                <Form.Group className="mb-3 d-flex" >
                    <Form.Label className='me-3'> Block </Form.Label>
                    <Form.Check
                        type="checkbox"
                        name='isBlock'
                        value={payload?.isBlock}
                        checked={payload?.isBlock}
                        onChange={(event) => setPayload({ ...payload, [event.target.name]: !payload?.isBlock })}
                    />
                </Form.Group>


                <div className='d-flex  align-items-center justify-content-between '>
                    <button dir="rtl" className="btn btn-sm  btn-success mt-4 text-end " onClick={deactivateCleanerHandler} disabled={loader ? true : false}>
                        {loader ? "Updating..." : 'Update'}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeactivateChamps
