/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../_metronic/helpers'
import TagsInputs from '../Forms/TagsInputs'
import DeactivateChamps from '../Forms/DeactivateChamps'
import SecurityDeposit from '../Forms/SecurityDeposit'
import AssignRemoveSupervisor from '../Forms/AssignRemoveSupervisor'
import { AssignChangeSlab } from '../components/AssignChangeSlab/AssignChangeSlab'
import GiveRewardPoints from '../components/GiveRewardPoints'
import { AssignAllowance } from '../components/AssignAllowance/AssignAllowance'
import { isAllowedUser } from '../../../consts/components/Utility'
import { WrongReportingModal } from '../components/WrongReportingModal'
import { postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import { toast } from 'react-toastify'
import NewChampAssignmentsModel from '../NewChampAssignmentsModel'
import { isAppRunningInTestOrProd } from '../../admins/adminUtils'
import PermissionContextProvider from '../../admins/adminComponents/PermissionContextProvider'
import { adminMenuActionPermissionKeys } from '../../admins/adminConstant'

type Props = {
  cleaneridSingle: ID
  filteredData: any
  referece: string
}
export const DropdownAttendanceStatus: FC<Props> = ({ referece, cleaneridSingle, filteredData }) => {
  const user = Number(localStorage.getItem("user"))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [tagsData, setTagsData] = useState<any>({})
  const [isOpenedModel, setisOpenedModel] = useState<boolean>(false)
  const [isOpenedModelsupervisor, setisOpenedModelsupervisor] = useState<boolean>(false)
  const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false)
  const [depositModal, setDepositModal] = useState<boolean>(false)
  const [assignModal, setAssignModal] = useState<boolean>(false)
  const [assignAllowanceModal, setAssignAllowanceModal] = useState<boolean>(false)
  const [rewardModal, setRewardModal] = useState<boolean>(false)
  const [wronReportFine, setwronReportFine] = useState<boolean>(false)
  const [showNewChampAssignemnt, setShowNewChampAssignemnt] = useState<boolean>(false)
  const [refs, setrefs] = useState<any>("")
  const [revertJobLeftLoader, setRevertJobLeftLoader] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const handleReAssign = (cleaneridSingle: any) => {
    navigate(`/champ-permanent-replacement/active_cleaner_champ/${cleaneridSingle}`)
    const state = {
      filteredData,
      cleaneridSingle,
      referece: "cleaner"
    }
  }
  const handleReAssignTemporay = (cleaneridSingle: any) => {
    // navigate('/champ-permanent-replacement', {
    //   state: {
    //     filteredData
    //   }
    // })
    navigate(`/daily/job/assign/oldjob/${cleaneridSingle}`)
  }
  const handlNewAddTag = (singleData: any) => {
    setTagsData(singleData)
    setisOpenedModel(!isOpenedModel)
    setrefs("ActiveCleanerDropDownAddNewTag")
  }
  const handlAssignRemoveSupervisor = (singleData: any) => {
    setTagsData(singleData)
    setisOpenedModelsupervisor(!isOpenedModel)
    setrefs("Assig-remove-supervisor")
  }
  const handlAddBankAccount = (singleData: any) => {
    dispatch({ type: "LISTDRAWER", payload: "AddBankAccount" })
    dispatch({ type: "ADD-BANK", payload: singleData })
  }
  const handleCloseModel = () => {
    setisOpenedModel(false)
    setisOpenedModelsupervisor(false)
    setDeactivateModalOpen(false)
    setDepositModal(false)

  }

  const updatedDataPrivate = filteredData?.private_tag?.split(",")?.map((ele: any, i: number) => {
    if (ele) {
      const newData = {
        label: ele,
        value: i,
      }
      return newData
    }
  })

  const updatedDataPublic = filteredData?.public_tag?.split(",")?.map((ele: any, i: number) => {
    if (ele) {
      const newData = {
        label: ele,
        value: i,
      }
      return newData
    }
  })
  const ViewProfileCleaner = (cleaneridSingle: any) => {
  }

  const Deactivate = (cleaneridSingle: any) => {
    setDeactivateModalOpen(true)
  }

  const handleRevertJobLeft = async () => {
    if (window.confirm('Are you sure you want to revert Job left?')) {
      setRevertJobLeftLoader(true)

      try {
        const { data } = await postRequest(URL_EndPoints()?.revertCleanerJobLeft, { cleanerid: filteredData?.id }, setRevertJobLeftLoader)
        toast?.[data?.status == 200 ? 'success' : 'error'](data?.status == 200 ? data?.message : 'Something went wrong')
      } catch (error) {
        toast.error("Something went wrong")
      } finally {
        setRevertJobLeftLoader(false)
      }
    }
  }

  const createAMChampGroup = async () => {
    if (window.confirm('Are you sure you want to create group?')) {
      setRevertJobLeftLoader(true)
      try {
        toast.info("Creating AM group...", {
          isLoading: true,
          toastId: "createAMGroup"
        });
        const { data } = await postRequest(URL_EndPoints()?.createAMGroup, { areaManagerId: filteredData?.id }, null)
        toast.dismiss("createAMGroup");
        toast?.[data?.status == 200 ? 'success' : 'error'](data?.status == 200 ? data?.message : 'Something went wrong')
      } catch (error) {
        toast.error("Something went wrong")
      } finally {
        toast.dismiss("createAMGroup");
      }
    }
  }

  const handleUpdateStatus = (cleaneridSingle: any) => {
  }

  // const AsignChangeSlab = (cleaneridSingle: any, drawerRefs: any) => {
  //   dispatch({ type: "LISTDRAWER", payload: drawerRefs })
  //   dispatch({ type: "LISTDRAWERIDS", payload: cleaneridSingle })
  // }

  const AssignUpdateAlowance = (cleaneridSingle: any, drawerRefs: any) => {
    dispatch({ type: "LISTDRAWER", payload: drawerRefs })
    dispatch({ type: "LISTDRAWERIDS", payload: cleaneridSingle })
  }
  // const GiveRewards = (cleaneridSingle: any, drawerRefs: any) => {
  //   dispatch({ type: "LISTDRAWER", payload: drawerRefs })
  //   dispatch({ type: "LISTDRAWERIDS", payload: cleaneridSingle })
  // }
  const ViewLoans = (cleaneridSingle: any, drawerRefs: any) => {
    dispatch({ type: "LISTDRAWER", payload: drawerRefs })
    dispatch({ type: "LISTDRAWERIDS", payload: cleaneridSingle })
  }
  const AssignSuperVisor = (cleaneridSingle: any, drawerRefs: any) => {
    dispatch({ type: "LISTDRAWER", payload: drawerRefs })
    dispatch({ type: "LISTDRAWERIDS", payload: cleaneridSingle })
  }
  const securityDepositHandler = () => {
    setDepositModal(true)
  }

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm '
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>

      </button>
      <div
        className='menu DropdownContainerwidth menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
        style={{
          minWidth: "200px",
          zIndex: '105',
          position: 'fixed',
          inset: '0px 0px auto auto',
          margin: '0px',
          transform: 'translate(-59px, 440px)',
          overflowY: "scroll",
          maxHeight: "calc(100vh - 20px)",
        }}
      // ref={menuRef}
      >

        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-re-assign-permanent"]}>
          <div className='menu-item px-3'>
            <a
              href={`/champ-permanent-replacement/active_cleaner_champ/${cleaneridSingle}`}
              className='menu-link  px-3'
              data-kt-users-table-filter='delete_row'
              // target="_blank"
              onClick={() => handleReAssign(cleaneridSingle)}
            >
              Re-Assign/Permanent
            </a>
          </div>
        </PermissionContextProvider>

        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-re-assign-temporary"]}>
          <div className='menu-item px-3'>
            <a
              href={`/daily/job/assign/oldjob/${cleaneridSingle}`}
              className='menu-link  px-3'
              data-kt-users-table-filter='delete_row'
              // target="_blank"
              onClick={() => handleReAssignTemporay(cleaneridSingle)}
            >
              Re-Assign/Temporary
            </a>
          </div>
        </PermissionContextProvider>


        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-assign-remove-supervisor"]}>
     
        <div className='menu-item px-3'>
          <a
            // href={`/daily/job/assign/oldjob/${cleaneridSingle}`}
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            // target="_blank"
            onClick={() => handlAssignRemoveSupervisor(filteredData)}
          >
            Assign/Remove-Supervisor
          </a>
        </div>
        </PermissionContextProvider>

        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-add-update-tag"]}>
        <div className='menu-item px-3'>
          <a
            // href={`/daily/job/assign/oldjob/${cleaneridSingle}`}
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            // target="_blank"
            onClick={() => handlNewAddTag(filteredData)}
          >
            Add New/Update Tags
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-add-bank-detail"]}>
        <div className='menu-item px-3'>
          <a
            // href={`/daily/job/assign/oldjob/${cleaneridSingle}`}
            className='menu-link  px-3'
            id="kt_activities2_toggle2"
            data-kt-users-table-filter='delete_row'
            // target="_blank"
            onClick={() => handlAddBankAccount(filteredData)}
          >
            Add Bank Account
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-view-profile"]}>
        <div className='menu-item px-3' >
          <a
            // href='_blank'
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => ViewProfileCleaner(cleaneridSingle)}
          >
            View Profile
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-deactivate-champ"]}>
        <div className='menu-item px-3'>
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => Deactivate(cleaneridSingle)}
          >
            Deactivate
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-revert-job-left"]}>
        
        {filteredData?.userType == 2 &&  <div className='menu-item px-3'>
          <a
            className='menu-link  px-3'
            onClick={() => createAMChampGroup()}
          >
            Create AM Camp Group
          </a>
        </div>
        }

        <div className='menu-item px-3'>
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={handleRevertJobLeft}
          >
            Revert Job Left
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-make-full-timer"]}>
   
        <div className='menu-item px-3'>
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => handleUpdateStatus(cleaneridSingle)}
          >
            Make Full Timer
          </a>
        </div>
        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-availability"]}>
        <div className='menu-item px-3'>
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
          >
            Availibility
          </a>
        </div>

        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-assign-update-slab"]}>
        {
          isAllowedUser?.includes(user) && <>
            <div className='menu-item px-3' >
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => setAssignModal(true)}
              >
                Assign / Change Slab
              </a>
            </div>

            <div className='menu-item px-3'>
              <a
                className='menu-link  px-3'
                data-kt-users-table-filter='delete_row'
                onClick={() => setAssignAllowanceModal(true)}
              >
                Assign / Upddate Allowance
              </a>
            </div>

          </>
        }

        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-give-reward"]}>
        <div className='menu-item px-3' >
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => setRewardModal(true)}
          >
            Give Rewards
          </a>
        </div>

        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-fine-for-wrong-reporting"]}>
     
        <div className='menu-item px-3'>
          <a
            href="#"
            className='menu-link  px-3'
            onClick={() => setwronReportFine(true)}
          >
            Fine For Wrong Reporting
          </a>
        </div>

        </PermissionContextProvider>
        <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-view-loans"]}>
     
        <div className='menu-item px-3' id='kt_activities2_toggle2' >
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => ViewLoans(cleaneridSingle, "viewLoans")}
          >
            View Loans
          </a>
        </div>

        </PermissionContextProvider>
       <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-assign-supervisor"]}>
 
        <div className='menu-item px-3' id="kt_activities2_toggle2">
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => AssignSuperVisor(cleaneridSingle, "assignSuper")}
          >
            Assign SuperVisor
          </a>
        </div>

        </PermissionContextProvider> 
       <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-security-deposit"]}>
 
       <div className='menu-item px-3' >
          <a
            className='menu-link  px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => securityDepositHandler()}
          >
            Security Deposit
          </a>
        </div>

        </PermissionContextProvider> 
       <PermissionContextProvider keyName={adminMenuActionPermissionKeys["active-new-champ-assign"]}>
       {
          isAppRunningInTestOrProd[window.location.host] ? <div className='menu-item px-3' >
            <a
              className='menu-link  px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => setShowNewChampAssignemnt(true)}
            >
              New Champ Assign
            </a>
          </div> : <></>
        }

        </PermissionContextProvider> 

      </div>


      {
        isOpenedModel && <TagsInputs FilteredData={{
          handleClose: handleCloseModel,
          show: isOpenedModel,
        }} row={filteredData} ParentData={tagsData} updatedDataPublic={updatedDataPublic} updatedDataPrivate={updatedDataPrivate}></TagsInputs>
      }

      {
        isOpenedModelsupervisor ? <AssignRemoveSupervisor
          handleCloseForm={handleCloseModel}
          row={filteredData}
          openModal={isOpenedModelsupervisor}
        /> : <></>
      }

      {
        deactivateModalOpen ? <DeactivateChamps deactivateModalOpen={deactivateModalOpen} handleCloseModel={handleCloseModel} row={filteredData} /> : <></>
      }

      {
        depositModal && <SecurityDeposit depositModal={depositModal} handleCloseModel={handleCloseModel} row={filteredData} />
      }

      {
        assignModal && <AssignChangeSlab
          row={filteredData}
          show={assignModal}
          setShow={setAssignModal}
        />
      }
      {
        assignAllowanceModal && <AssignAllowance
          row={filteredData}
          show={assignAllowanceModal}
          setShow={setAssignAllowanceModal}
        />
      }


      {
        rewardModal && <GiveRewardPoints
          row={filteredData}
          show={rewardModal}
          setShow={setRewardModal}
        />
      }
      {
        wronReportFine ? <WrongReportingModal
          row={filteredData}
          show={wronReportFine}
          setShowWrongReportingForm={setwronReportFine}
        /> : <></>
      }


      {
        showNewChampAssignemnt ? <NewChampAssignmentsModel
          row={filteredData}
          show={showNewChampAssignemnt}
          setShow={setShowNewChampAssignemnt}
        /> : <></>
      }


    </>
  )
}
