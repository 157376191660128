import { useEffect, useState } from "react";
import { Badge, Button, Form, Modal, Spinner, Tab, Tabs } from "react-bootstrap";

import Select from 'react-select';
import React from "react";
import { toast } from "react-toastify";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";
import { postRequest } from "../../consts/Server/Requests";
import MultiSelectSearchDetails from "../../consts/Select/MultiSelectSearchDetails";


const SendNotificationForm = (props: any) => {
    const sendNotificationPopUp = props?.sendNotificationPopUp
    const setSendNotificationPopUp = props?.setSendNotificationPopUp
    const data = props?.data
    const mainPayloads = props?.mainPayloads
    const [payload, setPayload] = useState<any>({})
    const allCleanersData = props?.allCleanersData
    const [showData, setShowData] = React.useState<any>("")
    const [postLoading, setPostLoading] = React.useState<any>(false)
    const [key, setKey] = useState('notif');
    const [wpData, setWpData] = React.useState<any>([])

    React.useEffect(() => {
        const updatedData = data?.map((ele: any, i: number) => {

            const newData = {
                label: ele['id'] + ' ' + ele['title']['en'],
                value: ele['id'],
            }
            return newData

        })
        setShowData(updatedData)
    }, [data])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setPostLoading(true)
        const response = await postRequest(URL_EndPoints()?.sendTemplateNotification, { ...mainPayloads, ...payload }, null)
        if (response.status == 200) {
            toast("Notification sent successfully.")
        }
        setPostLoading(false)
        setSendNotificationPopUp(false)
    }

    const handleChnageInputData = (e: any, name: any = null): any => {
        if (name == null) {
            setPayload({
                ...payload,
                [e?.target?.name]: e?.target?.value,

            })

        } else {
            if (name == "cleanerid") {
                setPayload({
                    ...payload,
                    [name]: e,

                })
            } else {
                setPayload({
                    ...payload,
                    [name]: e.value,
                })
            }

        }
    }

    const hideModal = () => {
        setPayload({})
        setSendNotificationPopUp(false)
    }

    const getAppliedFilters = () => {
        const result = Object.keys(mainPayloads).filter((keyName, i) => mainPayloads[keyName] != "" && mainPayloads[keyName] != 0 && mainPayloads[keyName] != "0")
        return Object.keys(result).length
    }

    React.useEffect(() => {
        getWpTemplates()
    }, [])

    const getWpTemplates = () => {
        async function InvokedRendered() {
            const { data } = await postRequest(URL_EndPoints(null)?.getWhatsAppTemplate, {}, null)
            setWpData(data.data)
            //   setRefresh(false)
        }
        InvokedRendered()
    }



    return (
        <Modal size="lg" show={sendNotificationPopUp} onHide={hideModal} >
            {/* <Modal.Header closeButton></Modal.Header> */}
            <div className="container p-2">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k: any) => k == "closeTabButton" ? hideModal() : setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="notif" title="Template Notification">
                        <Form onSubmit={handleSubmit} >

                            <Form.Group className="mb-3" controlId="feed_category">
                                <Form.Label>**Send notifications to applied filters champs**</Form.Label>
                                <Button variant="primary" className="blackBadge">
                                    Applied Filters <Badge bg="secondary">{Object.keys(mainPayloads).filter((keyName, i) => mainPayloads[keyName] != "").length}</Badge>
                                    <span className="visually-hidden"></span>
                                </Button>


                            </Form.Group>


                            <Form.Group className="mb-3" controlId="isfeed">
                                <Form.Label>Select Template</Form.Label>
                                <Select
                                    placeholder={'Notification Templates'}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={showData}
                                    onChange={(event: any) => handleChnageInputData(event, 'templateid')}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="description">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="type.."
                                    style={{ height: '100px' }}
                                    name="description"
                                    onChange={handleChnageInputData}
                                />
                            </Form.Group>


                            <Button variant="primary" disabled={postLoading} type="submit">
                                {
                                    postLoading ? <Spinner animation="border" /> : 'Send Notification'
                                }

                            </Button>
                        </Form>
                    </Tab>
                    <Tab eventKey="wp_notif" title="WhatsApp Notification">
                        <Form onSubmit={handleSubmit} >

                            <Form.Group className="mb-3" controlId="feed_category">
                                <Form.Label>**Send notifications to applied filters champs**</Form.Label>
                                <Button variant="primary" className="blackBadge">
                                    Applied Filters <Badge bg="badge" >{Object.keys(mainPayloads).filter((keyName, i) => mainPayloads[keyName] != "").length}</Badge>
                                    <span className="visually-hidden"></span>
                                </Button>


                            </Form.Group>


                            <Form.Group className="mb-3" controlId="isfeed">
                                <Form.Label>Select WhatsApp Template</Form.Label>
                                <Select
                                    placeholder={'Notification Templates'}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={wpData}
                                    getOptionLabel={(option: any) => option.name}
                                    getOptionValue={(option: any) => option.id}
                                    onChange={(event: any) => handleChnageInputData(event, 'templateid')}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="description">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="type.."
                                    style={{ height: '100px' }}
                                    name="description"
                                    onChange={handleChnageInputData}
                                />
                            </Form.Group>


                            <Button variant="primary" disabled={postLoading} type="submit">
                                {
                                    postLoading ? <Spinner animation="border" /> : 'Send Notification'
                                }

                            </Button>
                        </Form>
                    </Tab>
                    <Tab eventKey="closeTabButton" title="close" >

                    </Tab>
                </Tabs>
            </div>



        </Modal>

    )
}
export default SendNotificationForm