import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { getRequest, postRequest } from '../../../consts/Server/Requests';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';
import SingleSelectSearchCategoryDefault from '../../../consts/Select/SingleSelectSearchCategoryDefault';

const AssignRemoveSupervisor = ({ handleCloseForm, row, openModal, }: any) => {

    const [loader, setloader] = useState<any>(false)
    const [SupervisorsListData, setSupervisorsListData] = useState<any>([])

    const [payload, setPayload] = useState<any>({
        supervisorId: null,
        champId: row?.id,
    })

    useEffect(() => {
        async function load() {
            const supervisor_data = await getRequest(URL_EndPoints()?.getSupervisorList, null)
            setSupervisorsListData(supervisor_data?.data?.data)
        }
        load()
    }, [])

    const handleChangeInputData = (event: any, name: string) => {
        if (event != null) {
            setPayload({ ...payload, ["supervisorId"]: event?.value })
        }
        else {
            setPayload({ ...payload, ["supervisorId"]: null })

        }
    }

    // by this function we are creating new tags
    const assignSupervisorHandler = async () => {
        setloader(true)

        if (payload?.supervisorId) {
            const result = await postRequest(URL_EndPoints()?.assignMultipleCleanersWithSupervisior, payload, null)
            if (result?.data?.status == 200) {
                toast.success(result?.data?.msg, { position: "top-center" })
                handleCloseForm()
            } else {
                toast.error(result?.data?.msg, { position: "top-center" })
                handleCloseForm()
            }
        }
        else {
            toast.error("Super visor id missing ")
        }

        setloader(false)
    }




    const updatedData = row?.cleanerSupervisor?.find((ele: any, i: number) => {
        let number = ele.supervisorcleaner.first_name ? '(' + ele.supervisorcleaner.phone + ')' : "(NA)"
        if (ele.supervisorcleaner.first_name) {
            const newData = {
                label: ele.supervisorcleaner.first_name + ' ' + ele.supervisorcleaner.last_name + number,
                value: ele.supervisor_id,
            }
            return newData
        }
    })



    return (
        <>

            {openModal ? <Modal show={openModal} onHide={handleCloseForm} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assgin / Remove supervisor </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row  ms-3 mb-3 mt-2 col-12'>

                        <SingleSelectSearchCategoryDefault
                            handleChangeInputData={handleChangeInputData}
                            HeaderTitle="Select Supervisor"
                            SelectData={SupervisorsListData}
                            DynamicKey={"first_name"}
                            DynamicKey2={"last_name"}
                            DynamicKey3={"phone"}
                            id={"id"}
                            name="supervisorId"
                            defaultData={row?.cleanerSupervisor?.length > 0 ? {
                                label: row?.cleanerSupervisor[0]?.supervisorcleaner?.first_name + " " + row?.cleanerSupervisor[0]?.supervisorcleaner?.last_name + " " + (row?.cleanerSupervisor[0]?.supervisorcleaner?.phone),
                                value: row?.cleanerSupervisor[0]?.supervisorcleaner?.id
                            } : null}
                        />

                    </div>

                    <div className='d-flex  align-items-center justify-content-end '>
                        <button dir="rtl" className="btn btn-success mt-4 text-end" onClick={assignSupervisorHandler} disabled={loader ? true : false}>
                            {loader ?
                                <Bars
                                    height="20"
                                    width="20"
                                    color="#00000"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                : 'Assign/Remove supervisor'}
                        </button>
                    </div>

                </Modal.Body>
            </Modal> : ''}


        </>
    )
}
export default AssignRemoveSupervisor
