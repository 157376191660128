import Calling from "../../../consts/Call/Calling";
import Layout from "../../../consts/Call/Layout";
import OpenChat from "../../../consts/champChatDashboard/OpenChat";
import React, { useState } from "react";
import { getName, imageBaseUrl } from "../../admins/adminUtils";


export const HiringListViewsColumn = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        cell: (row: any) => row?.id ?? "NA",
        sortField: 'id',
        sortable: true,
        width: "80px"
    },

    {
        id: 2,
        reorder: true,
        name: "NAME",
        cell: (row: any) => {
            return <div className="d-flex g-4 flex-wrap">
            <Calling title={row?.name ? row?.name : "NA"} row_id={row?.cl_cleaner_id} row={row} type="cleanerid" From={Layout} isOpen={true} source="hiring_points" trackData={champTrackData} totalRowDataForChampDashboard={champTrackData} />
        </div>
        },
        sortField: 'name',
        sortable: true,
        width: "200px"
    },
    {
        id: 2,
        reorder: true,
        name: "PHOTO",
        cell: (row: any) => <ShowImageUrl row={row} />,
        sortField: 'name',
        sortable: true,
        width: "200px"
    },
    {
        id: 7,
        reorder: true,
        name: "AREA MANAGER",
        cell: (row: any) => row?.areaManager ? getName(row?.areaManager) : "",
        sortField: 'areaManager.firstName',
        sortable: true,
        width: "200px"
    },

    {
        id: 3,
        reorder: true,
        name: "TOTAL LEADS",
        cell: (row: any) => row?.total_leads ,
        sortField: 'total_leads',
        sortable: true,
        width: "150px"
    },
    {
        id: 3,
        reorder: true,
        name: "CONVERTED LEADS",
        cell: (row: any) => row?.converted_leads ,
        sortField: 'converted_leads',
        sortable: true,
        width: "150px"
    },
    {
        id: 3,
        reorder: true,
        name: "ADDRESS",
        cell: (row: any) => row?.address ?? "NA",
        sortField: 'address',
        sortable: true,
        width: "250px"
    },

    {
        id: 8,
        reorder: true,
        name: "AREA",
        cell: (row: any) => row?.area?.areaname ?? "NA",
        sortField: 'area.areaname',
        sortable: true,
        width: "150px"
    },

    {
        id: 4,
        reorder: true,
        name: "MIN AMOUNT",
        cell: (row: any) => row?.min_amount,
        sortField: 'min_amount',
        sortable: true,
        width: "100px"
    },

    {
        id: 5,
        reorder: true,
        name: "QR CODE",
        cell: (row: any) => row?.qr_code ?? "NA",
        sortField: 'qr_code',
        sortable: true,
        width: "200px"
    },
    {
        id: 6,
        reorder: true,
        name: "PHONE",
        cell: (row: any) => row?.recruiter?.phone ?? "NA",
        sortField: 'recruiter.phone',
        sortable: true,
        width: "200px"
    },




]





const ShowImageUrl = ({ row }: any) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {/* Thumbnail Image */}
            <img
                src={`${imageBaseUrl}${row?.photo_url}`}
                className="img-fluid"
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                onClick={() => setShowModal(true)}
                alt="Thumbnail"
            />

            {/* Modal Popup */}
            {showModal && (
                <div style={styles.modalOverlay} onClick={() => setShowModal(false)}>
                    <div style={styles.modalContent}>
                        <img
                            src={`${imageBaseUrl}${row?.photo_url}`}
                            alt="Full Size"
                            style={styles.fullImage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

// Styles for Modal
const styles: any = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modalContent: {
        position: "relative",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "5px",
    },
    fullImage: {
        maxWidth: "90vw",
        maxHeight: "90vh",
    },
};


