import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getRequest, postRequest } from '../../../consts/Server/Requests'
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints'
import TagInputs from '../../../consts/TagInputs/TagInputs'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
const TagsInputs = ({ FilteredData, row, ParentData, updatedDataPublic, updatedDataPrivate }: any) => {
    // const updatedData = FilteredData?.states?.map((ele: any, i: number) => {
    //     if (ele) {
    //         const newData = {
    //             label: ele,
    //             value: i,
    //         }
    //         return newData
    //     }
    // })
    // const [suggestionTagData, setsuggestionTagData] = useState<any>([])
    // const getData = async () => {
    //     let QeueryParams = {
    //         To: "getMasterTags",
    //         What: `?user_type=3`
    //     }
    //     const result = await getRequest(URL_EndPoints(QeueryParams)?.getMasterTags, null)
    //     setsuggestionTagData(result?.data?.data)
    // }
    // useEffect(() => {
    //     getData()
    // }, [])















    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [isPrivate, setIsPrivate] = useState("")
    // const [previousTags, setpreviousTags] = useState<any>([])
    const [PayloadsTags, setPayloadsTags] = useState<any>([])
    const [suggestionTagData, setsuggestionTagData] = useState<any>([])





    const getData = async () => {
        let QeueryParams = {
            To: "getMasterTags",
            What: `?user_type=${row?.userType}`
        }
        const result = await getRequest(URL_EndPoints(QeueryParams)?.getMasterTags, null)
        setsuggestionTagData(result?.data?.data)
    }
    useEffect(() => {
        getData()
    }, [])
    // by this we are storign value from input to   payloads state
    const handleChangeInputData = (event: any) => {
        // setDisable(null)
        setPayloadsTags(event)
    }
    const Togglling = (event: any) => {
        setIsPrivate(event)
        if (event === "private") {
            const privateData = ParentData?.private_tag ? ParentData?.private_tag?.split(",") : []
            setPayloadsTags(privateData)
        }
        else {
            let publicData = ParentData?.public_tag ? ParentData?.public_tag?.split(",") : []
            setPayloadsTags(publicData)
        }
    }
    // by this function we are creating new tags
    const handleCreateTags = async (refs: string) => {
        let references = refs === "private" ? "private" : refs === "public" ? "public" : ""


        if (PayloadsTags?.length >= 0) {
            setLoader(true)
            let payloads: any = { tag: PayloadsTags.join(","), cleanerid: ParentData?.id, tagtype: references }
            const result = await postRequest(URL_EndPoints()?.addtagforcleaner, payloads, setLoader)
            if (result?.data?.status == 200) {
                toast.success(`${references} tags are Added Successfully`)
                row[references == "private" ? "private_tag" : "public_tag"] = PayloadsTags.join(",")
                setDisable(200)
                dispatch({
                    type: "UPDATE_ROW_DATE", payload: {
                        reference: references,
                        row: row
                    }
                })
                FilteredData?.handleClose()
            } else {
                setLoader(false)
                setDisable(null)
                toast.error(result?.data?.message)
            }
            handleChangeInputData([])
        } else {
            toast.error("Tag is missing ")
        }
        // window.location.reload()
    }











    return (
        <>
            {FilteredData?.show ? <Modal show={FilteredData?.show} onHide={FilteredData?.handleClose} dialogClassName="modal-small"
                // size="m"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tag Create/Update {FilteredData?.selectedTag}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12  mb-3">
                        {
                            !isPrivate && <h2 className='text-center'>Select Tag Types</h2>
                        }
                        <select
                            className='form-select form-select-solid me-2'
                            onChange={(e) => Togglling(e.target.value)}
                        >
                            <option>Select Tag Type</option>
                            <option value={"private"}>Private Tags</option>
                            <option value={"public"}>Public Tags</option>
                        </select>
                    </div>

                    {
                        isPrivate === "private" &&
                        <>
                            <div className="col-12  mb-3">
                                <h5>{isPrivate} </h5>
                                <TagInputs
                                    handleChangeInputData={handleChangeInputData}
                                    HeaderTitle="Add Tags"
                                    SelectData={suggestionTagData}
                                    DynamicKey={"name"}
                                    id={"id"}
                                    name="private_tags"
                                    defaultData={updatedDataPrivate}
                                />
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => handleCreateTags("private")}>
                                    {
                                        loader ?
                                            <>
                                                <div className='spinner-border mr-15' role='status'></div>
                                                <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Adding.....</h4>
                                            </>
                                            : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Add Private Tag </h4>
                                    }
                                </button>
                            </div>
                        </>
                    }


                    {
                        isPrivate === "public" && <>
                            <div className="col-12  mb-3">
                                <h5>{isPrivate} </h5>
                                <TagInputs
                                    handleChangeInputData={handleChangeInputData}
                                    HeaderTitle="Add Tags"
                                    SelectData={suggestionTagData}
                                    DynamicKey={"name"}
                                    id={"id"}
                                    name="public_tags"
                                    defaultData={updatedDataPublic}
                                />
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-sm p-2 btn-primary ms-2 d-flex cursor-pointer' disabled={disable || loader ? true : false} onClick={() => handleCreateTags("public")}>
                                    {
                                        loader ?
                                            <>
                                                <div className='spinner-border mr-15' role='status'></div>
                                                <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Adding.....</h4>
                                            </>
                                            : <h4 className='fw-bold text-white fs-6 ms-2 mt-2'>Add Public Tag </h4>
                                    }
                                </button>
                            </div>
                        </>
                    }



                </Modal.Body>
            </Modal> : ''}
        </>
    )
}
export default TagsInputs
