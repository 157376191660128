import React, { FC } from 'react'
import { KTCardBody } from '../../../_metronic/helpers'
import ActiveCleaner from './ActiveCleaners'
import SkeletonLoader from '../../consts/IsLoading/SkeletonLoader'
const ActiveCleanerRoute = ({ setFilteredIdForChat, apiPending }: any) => {



  return (
    <KTCardBody className='card py-4'>
      {
        // apiPending ? <SkeletonLoader rowCount={10} title="Active Cleaner" /> :

          <ActiveCleaner setFilteredIdForChat={setFilteredIdForChat}></ActiveCleaner>
      }
    </KTCardBody>
  )
}
export default ActiveCleanerRoute
