

import React, { useEffect, useState, useRef } from "react";
import Message from "./Message";
import ChatInput from "./ChatInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddMembers from './AddMembers';
import { postRequest } from "../../consts/Server/Requests";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";
import SkeletonLoader from "../../consts/IsLoading/SkeletonLoader";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import GroupInfo from "./GroupInfo";
import IsLoading from "../../consts/IsLoading/IsLoading";
import LoaderBars from "../../consts/IsLoading/LoaderBars";
import SeenMessage from "./SeenMessage";
import { adminMenuActionPermissionKeys } from "../admins/adminConstant";
import PermissionContextProvider from "../admins/adminComponents/PermissionContextProvider";

const ChatWindow = ({ selectedChat, onBack, setSelectedChatMap, selectedChatMap, getChats, setChats }: any) => {
    const [show, setShow] = useState(false);
    const [pendingMessage, setPendingMessage] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);


    const getChatMessages = async (pageNumber = 1, isNew?: any) => {
        setPendingMessage(true);
        const response = await postRequest(URL_EndPoints()?.getGroupMessages, {
            start: pageNumber,
            groupId: selectedChat?.id,
            length: 10,
            search: "",
            orders: "",
            columns: "id",
            sortby: "",
            direction: "",
            searchType: "any",
            filterData: {},
        }, setPendingMessage);

        let newMessages = response?.data?.data?.data?.sort((a: any, b: any) => a.sent_at - b.sent_at)

        setChats((chats: any) => {
            const newChats = chats.map((chat: any) => {
                if (chat.id === selectedChat?.id) {
                    return {
                        ...chat,
                        groupMember: {
                            unread_message_count: 0
                        }
                    }
                }
                return chat
            })
            return newChats
        })

        // If less than page size (10), then no more data
        if (newMessages.length < 10) {
            setHasMore(false);
        }

        setSelectedChatMap((prevMessage: any) => {
            const existingMessages = prevMessage[selectedChat?.id] || [];
            return {
                ...prevMessage,
                [selectedChat?.id]: isNew ? newMessages : [...newMessages, ...existingMessages,],
            };
        });

        setPage(pageNumber);
        setPendingMessage(false);
        if (response?.data?.status == 200) {
            await postRequest(URL_EndPoints()?.markGroupMessagesSeen, {
                "groupId": selectedChat?.id
            }, null)
        }
    };


    useEffect(() => {
        if (selectedChat?.id && selectedChatMap[selectedChat?.id] === undefined) {
            setPage(1);
            getChatMessages(1);
        } else {
            setPendingMessage(false);
        }
    }, [selectedChat?.id]);

    const createGroup = async () => {
        const response = await postRequest(URL_EndPoints()?.createGroup, {
            isGroup: false,
            members: [
                { "userId": selectedChat?.id, "userType": selectedChat?.userType },
            ]
        }, null)

        if (response?.data?.status == 200) {
            getChats(1, "", true)
        }
    }

    useEffect(() => {
        if (selectedChat?.id && selectedChatMap[selectedChat?.id] === undefined) {
            const isGroup = "is_group" in selectedChat;
            if (!isGroup) {
                createGroup()
            }
        }
    }, [selectedChat?.id]);

    const [showProfile, setShowProfile] = useState(false);

    const openListView = () => {
        setShow(true);
    };

    const openProfileInformation = () => {
        setShowProfile(true);
    };
    const userId = localStorage.getItem("user")

    // const handleScrollling = (scroll: any) => {
    //     // const bottom = scroll.target.scrollHeight - scroll.target.clientHeight - scroll.target.scrollTop;
    //     // if (bottom <= 1 && !pendingMessage) {
    //     if (scroll.target.scrollTop == 0 && !pendingMessage) {
    //         const nextPage = page + 1;
    //         getChatMessages(nextPage);
    //         setPage(nextPage);
    //     }
    // };

    const handleScrollling = (scroll: any) => {
        if (scroll.target.scrollTop === 0 && !pendingMessage && hasMore) {
            const prevScrollHeight = scroll.target.scrollHeight;
            const nextPage = page + 1;
            getChatMessages(nextPage).then(() => {
                setTimeout(() => {
                    const container = chatBodyRef.current;
                    if (container) {
                        const newScrollHeight = container.scrollHeight;
                        container.scrollTop = newScrollHeight - prevScrollHeight + 100;
                    }
                }, 100);
            });
            setPage(nextPage);
        }
    };

    const chatBodyRef = useRef<any>(null);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [selectedChatMap[selectedChat?.id]?.length])


    useEffect(() => {
        const tryAutoFetch = () => {
            const container = chatBodyRef.current;
            if (
                container &&
                hasMore &&
                container.scrollHeight <= container.clientHeight &&
                !pendingMessage
            ) {
                const nextPage = page + 1;
                getChatMessages(nextPage);
            }
        };

        tryAutoFetch();
    }, [selectedChatMap[selectedChat?.id]?.length]);

    const [showSeen, setShowSeen] = useState(false)
    const [showSeenDat, setShowSeenDat] = useState([])
    const showSeenMessage = (value: any) => {
        setShowSeenDat(value)
        setShowSeen(true)
    }

    if (!selectedChat) {
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center h-50 text-muted"
                style={{ textAlign: "center", fontSize: "1.5rem" }}
            >
                <p>Select a chat to start messaging</p>
            </div>
        );
    }
    return (
        <div className="d-flex flex-column flex-grow-1 h-100 " style={{ width: "100%" }}>
            <div className="chat-header border-bottom d-flex align-items-center">
                <button className=" bg-white border border-white d-md-none me-2" onClick={onBack}>
                    <FontAwesomeIcon icon={faArrowLeftLong} className="fs-3" />
                </button>

                <div
                    className={`d-flex align-items-center justify-content-between p-3 w-100`}
                    style={{ cursor: 'pointer' }}
                >
                    <div className="d-flex align-items-center" onClick={() => selectedChat?.is_group ? openProfileInformation() : {}}>
                        <div className="avatar rounded-circle bg-secondary" style={{ width: "40px", height: "40px" }}>
                            <img src={selectedChat?.group_image ? selectedChat?.group_image : toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }} />
                        </div>
                        <div className="ms-2 flex-grow-1">
                            <strong>{selectedChat.name}</strong>
                            {/* <div className="text-success font-bold">Online</div> */}
                        </div>
                    </div>
                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["chat-add-group-member"]}>
                    {
                        selectedChat?.is_group && <div className="d-flex align-items-center" onClick={() => openListView()}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ color: "#98a4a5", fontSize: "30px", cursor: "pointer" }} />
                        </div>
                    }
                </PermissionContextProvider>
                </div>
            </div>
            {
                pendingMessage && page > 1 && (
                    <div className="mt-4 mb-4 d-flex justify-content-center align-items-center">
                        <LoaderBars />
                    </div>
                )
            }
            <div
                className="chat-body flex-grow-1 p-3 overflow-auto "
                style={{ maxHeight: "calc(100vh - 120px)", overflowY: 'auto', width: "100%" }}
                onScroll={handleScrollling}
                ref={chatBodyRef}
            >
                {pendingMessage && page === 1 ? (
                    <SkeletonLoader />
                ) : (
                    <>
                        {selectedChatMap[selectedChat?.id]?.length > 0 ? (
                            selectedChatMap[selectedChat?.id]?.map((msg: any, index: number) => (
                                <Message key={index} showSeenMessage={showSeenMessage} {...msg} userId={Number(userId)} />
                            ))
                        ) : (
                            "No Chat is found"
                        )}
                    </>
                )}
            </div>

            <ChatInput setSelectedChatMap={setSelectedChatMap} selectedChatMap={selectedChatMap} selectedChat={selectedChat} />

            {show && <AddMembers show={show} setShow={setShow} groupId={selectedChat?.id} getChatMessages={getChatMessages} />}
            {showProfile && <GroupInfo show={showProfile} setShow={setShowProfile} groupId={selectedChat?.id} getChatMessages={getChatMessages}/>}

            {
                showSeen && <SeenMessage show={showSeen} setShow={setShowSeen} groupsData={showSeenDat} />
            }
        </div>
    );
};

export default ChatWindow;
