import { TableColumn } from 'react-data-table-component'

export const columns : TableColumn<any>[] =  [

    {
        name: 'SCHEMA NAME',
        selector: (row) => row?.cleanerPayoutScheme?.scheme_name,
        sortable: true,
        id: 'ID',
      },
      {
        name: 'MIN EARNIING WEEK',
        selector: (row) => row?.min_earning_week,
        sortable: true,
    
      },
      {
        name: 'ASSIGNED DATE',
        selector: (row : {scheme_assigned_date :any}) => row?.scheme_assigned_date,
        sortable: true,
    
      },
      {
        name: 'PAUSED DATE',
        selector: (row : {scheme_paused_date :any}) => row?.scheme_paused_date,
        sortable: true,
    
      },
      {
        name: 'STATUS',
        selector: (row ) => row?.status,
        sortable: true,
    
      },

];

