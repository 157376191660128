import React from 'react';
import { image_Cloudfront } from '../../consts/components/Utility';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const ImageRender = ({ images, loader }: any) => {
    return (
        <>
        <div className="d-flex " style={{ position: 'relative', overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: "100%" }}>
            {loader ? <div className="avatar rounded-circle bg-secondary" style={{ width: "40px", height: "40px" }}>
                <img src={toAbsoluteUrl('/media/logos/images.ico')} alt='metronic' style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
            </div> : images.map((image: any, index: any) => (
                <div key={index} className="me-2">
                    {image?.file_type === "audio" ? (
                        <div style={{ minWidth: '250px', margin: '10px 0' }}>
                            <audio
                                controls 
                                className="w-100" 
                                style={{ display: 'block' }}
                                preload="metadata"
                            >
                                <source 
                                    src={`${image_Cloudfront}${image?.file_path}`} 
                                    type="audio/mpeg"
                                />
                                <p>Your browser does not support the audio element. <a href={`${image_Cloudfront}${image?.file_path}`}>Download audio</a></p>
                            </audio>
                        </div>
                    ) : image?.file_type === "image" ? (
                        <img
                            src={`${image_Cloudfront}${image?.file_path}`}
                            alt={`Image ${index + 1}`}
                            className=""
                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                        />
                    ) : (
                        <div className="text-muted">Media file not supported</div>
                    )}
                </div>
            ))}
        </div>
        </>
    );
};

export default ImageRender;
